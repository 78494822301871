import { Component, OnInit } from '@angular/core';
import { PostInfo } from 'src/app/models/post.model';
import { EpochtaApiService } from 'src/app/services/epochta-api.service';
import {Router} from '@angular/router';

@Component({
    selector: 'app-archive',
    templateUrl: './archive.component.html',
    styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
    isShowSidebar: boolean = false;

    constructor(private apiService: EpochtaApiService, private router: Router) { }

    posts: PostInfo[];

    async ngOnInit(): Promise<void> {
        this.posts = await this.apiService.posts.getUserArchivedPosts();
    }

    goDetails(post: PostInfo): void {
        if (post.status === 'new') {
            this.router.navigateByUrl(`create-departure/${post.id}`);
        }
        this.router.navigateByUrl(`departure-details/${post.id}`);
    }

    sidebarOpen(open: boolean): void {
        this.isShowSidebar = open;
    }
}
