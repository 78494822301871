import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
    isBurger: boolean = true;

    @Input() title: string;
    @Output() toggleSidebar = new EventEmitter<boolean>();

    isShowSidebar: boolean = false;

    constructor(private router: Router) { }

    ngOnInit(): void {
        this.checkUrl();
    }

    showSidebar() {
        this.isShowSidebar = !this.isShowSidebar;
        this.toggleSidebar.emit(this.isShowSidebar);
    }

    checkUrl() {
        switch (this.router.url) {
            case '/registration':
                this.isBurger = false;
                break;
            case  '/password-recovery':
                this.isBurger = false;
                break;
            default:
                this.isBurger = true;
        }
        // if(this.router.url === '/registration') this.isBurger = false;
    }
}
