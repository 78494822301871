import {ToastrService} from 'ngx-toastr';
import {AfterViewInit, Component, ElementRef, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EpochtaApiService} from '../../services/epochta-api.service';
import {TypeRegistration} from '../../models/customer.model';

@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.scss']
})


export class RegistrationComponent implements OnInit, AfterViewInit {
    phone: string = null;
    code: string = null;
    oldCode: string = null;
    sms: boolean = false;
    isCode: boolean = false;
    token: string = null;
    fullName: string = null;
    email: string = null;
    password: string = null;
    typeRegistration: TypeRegistration = TypeRegistration.Phone;
    trackNumber;

    constructor(
        private router: Router,
        private epochtaApi: EpochtaApiService,
        private toastr: ToastrService,
        private elementRef: ElementRef
    ) { }

    ngOnInit(): void {
        this.checkAuth();
    }

    ngAfterViewInit(): void {
        addEventListener('popstate', () => {
            if (window.location.pathname === '/registration') {
                if (window.location.hash === '') {
                    this.sms = false;
                    this.isCode = false;
                    this.email = '';
                    this.phone = '';
                }
                if (window.location.hash === '#step-2') {
                    this.sms = true;
                    this.isCode = false;
                    this.code = '';
                }
            }
        });
    }

    async sendSmsOrEmail($event): Promise<void> {
        if ((this.phone || this.phone.length === 16) || this.changeNumberEmail(this.email)) {
            if (this.typeRegistration === TypeRegistration.Phone) {
                const numberPhone = this.phone.replace(/\D/g, '');
                if (numberPhone) {
                    const data = await this.epochtaApi.startCustomerLogin(numberPhone);
                    this.token = data;
                    localStorage.setItem('auth', this.token);
                    this.sms = true;
                    setTimeout(() => document.getElementById('code').focus());
                    window.location.hash = 'step-2';
                }
            } else if (this.typeRegistration === TypeRegistration.Email) {
                if (this.email) {
                    const data = await this.epochtaApi.startCustomerLoginEmail(this.email);
                    this.token = data;
                    localStorage.setItem('auth', this.token);
                    this.sms = true;
                    setTimeout(() => document.getElementById('code').focus());
                    window.location.hash = 'step-2';
                }
            } else {
                this.toastr.error('Error');
            }
        } else {
            this.toastr.error('Error');
        }
    }

    changeNumberPhone(value: string) {
        const x = value.replace("+7", "").replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.phone = "+7" + (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));

        (document.getElementById("phone") as HTMLInputElement).value = this.phone;

        if (this.phone && this.phone.length == 16) {
            (<HTMLInputElement>document.getElementById('send-sms-button')).disabled = false;
        } else {
            (<HTMLInputElement>document.getElementById('send-sms-button')).disabled = true;
        }
    }

    changeNumberEmail(value: string): boolean {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (value && value.match(re)) {
            this.email = value;
            (<HTMLInputElement>document.getElementById('send-sms-button')).disabled = false;
            return true;
        } else {
            this.email = null;
            (<HTMLInputElement> document.getElementById('send-sms-button')).disabled = true;
            return false;
        }
    }

    _typeRegistration(): void {
        if (this.typeRegistration === TypeRegistration.Phone) {
            this.email = '';
        } else if (this.typeRegistration === TypeRegistration.Email) {
            this.phone = '';
        }
        (<HTMLInputElement>document.getElementById('send-sms-button')).disabled = true;
    }

    async sendCode($event) {
        if (this.token == null) return;

        if (this.code) {
            const data = await this.epochtaApi.continueCustomerLogin(this.code);
            if (data && data.length > 0) {

                if (this.typeRegistration === TypeRegistration.Email) {
                    await this.epochtaApi.customers.getCustomerByEmail(this.email).then(e => {
                        if (e) {
                            this.phone = e.phone;
                            this.fullName = e.friendlyName;
                        }

                        this.token = data;
                        localStorage.setItem('auth', data);
                        this.isCode = true;
                        window.location.hash = 'step-3';
                        setTimeout(() => document.getElementById('full-name').focus());
                    });
                } else if (this.typeRegistration === TypeRegistration.Phone) {
                    await this.epochtaApi.customers.getCustomerByPhone(this.phone).then(e => {
                        if (e) {
                            this.email = e.email;
                            this.phone = e.phone;
                            this.fullName = e.friendlyName;
                        }

                        this.token = data;
                        localStorage.setItem('auth', data);
                        this.isCode = true;
                        window.location.hash = 'step-3';
                        setTimeout(() => document.getElementById('full-name').focus());
                    });
                }
            }
        }

        (<HTMLInputElement>document.getElementById('send-code-button')).disabled = true;
        this.oldCode = this.code;
    }

    changeCode() {
        if (this.code != this.oldCode) {
            (<HTMLInputElement>document.getElementById('send-code-button')).disabled = false;
        }
    }

    async addUserData($event) {
        if (this.token == null) return;

        if (!this.fullName || this.fullName.length < 5) {
            this.toastr.error("Укажите Ф.И.О.");
            return;
        }
        if (!this.email || ! (/^(.+@.+)$/).test(this.email)) {
            this.toastr.error("Введите Email в правильном формате ...@...");
            return;
        }
        if (!this.password || this.password.length < 6) {
            this.toastr.error("Введите пароль (не менее 6-ти символов)");
            return;
        }

        const data = await this.epochtaApi.customers.saveCustomer({ id: this.token, friendlyName: this.fullName, phone: this.phone, email: this.email, passwordHash: this.password });
        this.token = data.id;
        localStorage.setItem('auth', this.token);
        this.router.navigateByUrl('/history');
    }

    async checkAuth() {
        const userInfo = await this.epochtaApi.customers.getCurrentCustomerProfile();

        if (!userInfo) return;

        this.router.navigateByUrl("/history");
    }
}
