import { Attachment, AttachmentReq } from './attachment.model';
import { CustomerLocation } from './customer-location.model';
import {PostalServiceAlias} from './postal-service.model';

export class Post {
    id: string;
    title: string;
    postalCode: string;
    postalCodeIndex: string;
    senderName: string;
    senderPhone: string;
    senderLocation: string;
    senderLocationCity: string;
    senderLocationStreet: string;
    senderLocationHouse: string;
    senderCompany: string;
    receiverName: string;
    receiverPhone: string;
    receiverLocation: string;
    receiverLocationCity: string;
    receiverLocationStreet: string;
    receiverLocationHouse: string;
    cost: number = 0;
    attachments: AttachmentReq[];
    cdekNumber: string;
    status: string;
    ParentId: string;
    postalServiceAlias: PostalServiceAlias;
    city: string;
    street: string;
    house: string;
    spi?: string;
}

export class PostInfo {
    id: string;
    title: string;
    sender: Sender;
    location: CustomerLocation;
    receiver: Receiver;
    createdAt: Date;
    status: string;
    destination: Destination;
    ownerId: string;
    officeId: string;
    attachments: Attachment[];
    changeStatus: ChangeStatus[];
    cost?: number;
    cdekNumber: string;
    postalServiceAlias: PostalServiceAlias;
    spi?: string;
}

export class Sender {
    name: string;
    phone: string;
    company: string;
    address: string;
    postalCodeIndex: string;
    city: string;
    street: string;
    house: string;
}

export class Receiver {
    name: string;
    phone: string;
    postalCodeIndex: string;
    city: string;
    street: string;
    house: string;
}

export class ChildReceiver {
    name: string;
    phone: string;
    adsress: string;
    postalCode: string;
}

export class Destination {
    address: string;
    postalCode: string;
}

export class ChangeStatus {
    status: string;
    date: Date;
}
