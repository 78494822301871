import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-popular-questions',
  templateUrl: './popular-questions.component.html',
  styleUrls: ['./popular-questions.component.scss']
})
export class PopularQuestionsComponent implements OnInit {
    isShowSidebar: boolean = false;

    constructor(private route: ActivatedRoute) { }

    activeQuestion: number;

    ngOnInit(): void {
        this.activeQuestion = +this.route.snapshot.queryParamMap.get("h");
    }

    sidebarOpen(open: boolean): void {
        this.isShowSidebar = open;
    }
}
