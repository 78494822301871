<app-header [title]="'Популярные вопросы'" (toggleSidebar)="sidebarOpen($event)"></app-header>

<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>

    <main class="content">
        <div class="container">
            <div class="accordion" id="accordionExample">
                <div class="card">
                    <div class="card-header" id="heading-1">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-1" aria-expanded="true" aria-controls="collapse-1">
                                Как работает доставка электронных писем?
                            </button>
                        </h2>
                    </div>

                    <div id="collapse-1" [class.show]="activeQuestion == 1" class="collapse" aria-labelledby="heading-1"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Вы отправляете электронное письмо, ваш Адресат получает бумажную копию.</p>
                            <p>В нашем приложении можно отправить письмо организации (юридическому лицу). Для отправки требуется
                                зарегистрироваться в приложении по номеру телефона. В предложенной форме ввести данные
                                получателя и
                                отправителя письма. После чего загрузить в электронном виде документы , которые вы хотите
                                отправить (В
                                качестве вложения допустимы в формате PDF. После оплаты письма ему будет присвоен код
                                отслеживания
                                (трек-номер). По этому коду отслеживания можно письмо будет узнать текущий статус доставки
                                письма. Письмо
                                будет автоматически распечатано курьерской службой, вложено в конверт и доставлено получателю,
                                после чего в
                                личном кабинете появится соответствующий статус о вручении письма. В случает отказа от получения
                                /
                                отсутствия
                                получателя по указанному адресу, отправлению будет присвоен статус «неудачная попытка вручения».
                                Для
                                подтверждения факта отправки письма отправитель может распечатать "Отчет о доставке", «опись
                                вложения»,
                                «квитанция об оплате отправления».</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                Какова юридическая сила доставки писем?
                            </button>
                        </h2>
                    </div>
                    <div id="collapseTwo" [class.show]="activeQuestion == 2" class="collapse" aria-labelledby="headingTwo"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Согласно ст. 165.1 ГК РФ Заявления, уведомления, извещения, требования или иные юридически
                                значимые
                                сообщения, с которыми закон или сделка связывает гражданско-правовые последствия для другого
                                лица, влекут
                                для этого лица такие последствия с момента доставки соответствующего сообщения ему или его
                                представителю.
                            </p>
                            <p>Сообщение считается доставленным и в тех случаях, если оно поступило лицу, которому оно
                                направлено
                                (адресату), но по обстоятельствам, не зависящим от него, не было ему вручено или адресат не
                                ознакомился с
                                ним.</p>
                            <p>Согласно п. 65 Постановление Пленума Верховного Суда Российской Федерации от 23 июня 2015 г. N 25
                                г. Москва
                                "О применении судами некоторых положений раздела I части первой Гражданского кодекса Российской
                                Федерации",
                                Если иное не установлено законом или договором и не следует из обычая или практики,
                                установившейся во
                                взаимоотношениях сторон, юридически значимое сообщение может быть направлено, в том числе
                                посредством
                                электронной почты, факсимильной и другой связи, осуществляться в иной форме, соответствующей
                                характеру
                                сообщения и отношений, информация о которых содержится в таком сообщении.</p>
                            <p>Таким образом, письмо, отправленное через почтовую службу в электронном виде, имеет силу, равную
                                силе
                                письма, отправленного на бумажном носителе.</p>
                            <p>При пересылке электронных писем соблюдается тайна переписки. Информация передается по каналам,
                                защищенным
                                специальными сертифицированными средствами.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="headingThree">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left collapsed" type="button" data-toggle="collapse"
                                data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                Когда письмо считается врученным?
                            </button>
                        </h2>
                    </div>
                    <div id="collapseThree" [class.show]="activeQuestion == 3" class="collapse" aria-labelledby="headingThree"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Письмо считается врученным в следующих случаях:</p>
                            <ol>
                                <li>Получение письма от курьера,</li>
                                <li>Согласно ст. 165.1 ГК РФ, Сообщение считается доставленным и в тех случаях, если оно
                                    поступило лицу,
                                    которому оно направлено (адресату), но по обстоятельствам, не зависящим от него, не было ему
                                    вручено или
                                    адресат не ознакомился с ним.</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading-2">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-2" aria-expanded="true" aria-controls="collapse-2">
                                Можно ли направить электронное письмо в государственное учреждение?
                            </button>
                        </h2>
                    </div>
                    <div id="collapse-2" [class.show]="activeQuestion == 4" class="collapse" aria-labelledby="heading-2"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>В случае, если содержимое письма допустимо предоставить в виде распечатанной незаверенной копии,
                                указанное
                                письмо может быть отправлено в государственное учреждение.</p>
                            <p>В случае, если требуется направить оригинал документа, данное письмо не может быть отправлено в
                                электронном
                                виде.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading-3">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-3" aria-expanded="true" aria-controls="collapse-3">
                                Можно ли направлять электронные письма сторонам судебного дела?
                            </button>
                        </h2>
                    </div>

                    <div id="collapse-3" [class.show]="activeQuestion == 5" class="collapse" aria-labelledby="heading-3"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Да. Данное письмо, направленное через лицензированную почтовую службу, является надлежащим
                                способом
                                направления второй стороне документа(ов).</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading-4">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-4" aria-expanded="true" aria-controls="collapse-4">
                                С какого момента письмо считается отправленным?
                            </button>
                        </h2>
                    </div>
                    <div id="collapse-4" [class.show]="activeQuestion == 6" class="collapse" aria-labelledby="heading-3"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>С момента регистрации и оплаты отправления письма.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading-5">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-5" aria-expanded="true" aria-controls="collapse-5">
                                Может ли отправитель направить пустые ПДФ-файлы и сослаться на надлежащее направление документов
                                по описи?
                            </button>
                        </h2>
                    </div>
                    <div id="collapse-5" [class.show]="activeQuestion == 7" class="collapse" aria-labelledby="heading-3"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Нет. Отправленные через наш сервис письма хранятся в электронном виде. По запросу отправителя,
                                получателя,
                                гос. органов на основании полномочий, предоставленных законом, наш сервис предоставит сведения о
                                содержании
                                документов, отправленных второй стороне.</p>
                            <p>Данный сервис позволит пресечь недобросовестное поведение отправителя.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading-6">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-6" aria-expanded="true" aria-controls="collapse-6">
                                Как долго будет доставляться письмо?
                            </button>
                        </h2>
                    </div>
                    <div id="collapse-6" [class.show]="activeQuestion == 8" class="collapse" aria-labelledby="heading-3"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Благодаря нашему сервису, письмо будет вручено/курьер придет по указанному адресу в день отправки
                                или на
                                следующий день.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading-7">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-7" aria-expanded="true" aria-controls="collapse-7">
                                Как происходит идентификация отправителя/получателя?
                            </button>
                        </h2>
                    </div>
                    <div id="collapse-7" [class.show]="activeQuestion == 9" class="collapse" aria-labelledby="heading-3"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Идентификация происходит в момент регистрации по телефону. И по сервису проверки почтового адреса
                                и
                                наименования Юридического лица.</p>
                        </div>
                    </div>
                </div>

                <div class="card">
                    <div class="card-header" id="heading-8">
                        <h2 class="mb-0">
                            <button class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                data-target="#collapse-8" aria-expanded="true" aria-controls="collapse-8">
                                Может ли кто-либо кроме меня просмотреть мои письма?
                            </button>
                        </h2>
                    </div>
                    <div id="collapse-8" [class.show]="activeQuestion == 10" class="collapse" aria-labelledby="heading-3"
                        data-parent="#accordionExample">
                        <div class="card-body">
                            <p>Наш сервис защищается паролем, требует смс-подтверждения по номеру телефона, указанному
                                владельцем личного
                                кабинета.</p>
                            <p>При такой степени защиты, доступ в личный кабинет имеет только владелец и лицо, которое владелец
                                сам
                                допустил к личному кабинету по собственному желанию.</p>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </main>
</div>


