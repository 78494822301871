import { PrivacyPolicyComponent } from './pages/policy/privacy-policy/privacy-policy.component';
import { PersonalDataPolicyComponent } from './pages/policy/personal-data-policy/personal-data-policy.component';
import { ErrorPaymentComponent } from './pages/result-pages/error-payment/error-payment.component';
import { SuccessfulPaymentComponent } from './pages/result-pages/successful-payment/successful-payment.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { OutgoingShipmentHistoryComponent } from './pages/outgoing-shipment-history/outgoing-shipment-history.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PopularQuestionsComponent } from './pages/popular-questions/popular-questions.component';
import { DepartureDetailsComponent } from './pages/departure-details/departure-details.component';
import { CreateDepartureComponent } from './pages/create-departure/create-departure.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { ShipmentArchiveComponent } from './pages/shipment-archive/shipment-archive.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'registration',
    component: RegistrationComponent
  },
  {
    path: 'password-recovery',
    component: PasswordRecoveryComponent
  },
  {
    path: 'history',
    component: OutgoingShipmentHistoryComponent
  },
  {
    path: 'profile',
    component: ProfileComponent
  },
  {
    path: 'popular-questions',
    component: PopularQuestionsComponent
  },
  {
    path: 'departure-details/:id',
    component: DepartureDetailsComponent
  },
  {
    path: 'create-departure',
    component: CreateDepartureComponent
  },
  {
    path: 'create-departure/:id',
    component: CreateDepartureComponent
  },
  {
    path: 'archive',
    component: ArchiveComponent
  },
  {
    path: 'personal-data-policy',
    component: PersonalDataPolicyComponent
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  },
  {
    path: 'shipment-archive/:id',
    component: ShipmentArchiveComponent
  },
  {
    path: 'successful-payment',
    component: SuccessfulPaymentComponent
  },
  {
    path: 'error-payment',
    component: ErrorPaymentComponent
  },
  {
    path: '**',
    redirectTo: 'login'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
