import { ToastrService } from 'ngx-toastr';
import { EpochtaApiService } from 'src/app/services/epochta-api.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})
export class PasswordRecoveryComponent implements OnInit {
    phoneForRecovery: string = null;
    codeForRecovery: string = null;
    oldCode: string = null;
    sms: boolean = false;
    isCode: boolean = false;
    token: string = null;
    newPasswordForPersonalArea: string = null;
    trackNumber: any;

    constructor(
        private router: Router,
        private epochtaApi: EpochtaApiService,
        private toastr: ToastrService
    ) {}

    ngOnInit(): void {this.checkAuth();}

    async sendSms($event) {
        if (!this.phoneForRecovery || this.phoneForRecovery.length != 16) {
            this.toastr.error("Введите телефон в формате +7 (555) 555-5555");
            return;
        }
        const number = this.phoneForRecovery.replace(/\D/g, '');

        if (number) {
            const data = await this.epochtaApi.startCustomerLogin(number);
            this.token = data;
            localStorage.setItem("auth", this.token);
            this.sms = true;
            setTimeout(() => document.getElementById("code_for_recovery").focus());
        }
    }

    changeNumberPhone(value: string) {
        const x = value.replace("+7", "").replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
        this.phoneForRecovery = "+7" + (!x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : ''));

        (document.getElementById("phone_for_recovery") as HTMLInputElement).value = this.phoneForRecovery;

        if (this.phoneForRecovery && this.phoneForRecovery.length == 16) {
            (<HTMLInputElement>document.getElementById('send-sms-button')).disabled = false;
        } else {
            (<HTMLInputElement>document.getElementById('send-sms-button')).disabled = true;
        }
    }

    async sendCode($event) {
        if (this.token == null) return;
        const data = await this.epochtaApi.continueCustomerLogin(this.codeForRecovery);

        if (data && data.length > 0) {
            localStorage.setItem("auth", data);
            this.isCode = true;
            setTimeout(() => document.getElementById("new_password_for_recovery").focus());
        } else {
        }

        (<HTMLInputElement>document.getElementById('send-code-button')).disabled = true;
        this.oldCode = this.codeForRecovery;
    }

    changeCode() {
        if (this.codeForRecovery != this.oldCode) {
            (<HTMLInputElement>document.getElementById('send-code-button')).disabled = false;
        }
    }

    sendNewPasswordForPersonalArea(): any {
        if (!this.newPasswordForPersonalArea || this.newPasswordForPersonalArea.length < 6) {
            this.toastr.error('Введите новый пароль (не менее 6-ти символов)');
            return;
        }

        const token = localStorage.getItem('auth');
        this.phoneForRecovery = this.phoneForRecovery.replace(' ', '');
        this.phoneForRecovery = this.phoneForRecovery.replace('(', '');
        this.phoneForRecovery = this.phoneForRecovery.replace(')', '');
        this.phoneForRecovery = this.phoneForRecovery.replace('-', '');
        this.phoneForRecovery = this.phoneForRecovery.replace('+', '');

        this.epochtaApi.passwordRecovery(this.phoneForRecovery, this.newPasswordForPersonalArea)
            .catch((e) => {
                if (e?.error?.message) { this.toastr.error(e?.error?.message); }
                return console.error(e);
            }).then(x => {
            const token = x.id;
            if (typeof token !== 'undefined' && token !== null) {
                localStorage.setItem('auth', token);
                this.router.navigateByUrl('/history');
            } else {
                this.toastr.error('Error');
            }
        });
    }

    async checkAuth() {
        const userInfo = await this.epochtaApi.customers.getCurrentCustomerProfile();

        if (!userInfo) return;

        this.router.navigateByUrl("/history");
    }
}
