import { Component } from '@angular/core';
import { DadataConfig, DadataType } from '@kolkov/ngx-dadata';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor () {}

  title = 'cdek-epochta';

  ngOnInit(){
  }
}
