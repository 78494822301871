<div class="sidebar__menu" [ngClass]="{sidebar__menu__open: isThisSidebarShow}">
    <p class="sidebar__menu__welcome">Добро пожаловать в личный кабинет!</p>

    <button class="sidebar__menu__send_btn btn btn-success" [routerLink]="['/create-departure']">Отправить новое письмо</button>
    <ul class="sidebar__menu__list">
        <li class="sidebar__menu__list__item">
            <a [routerLink]="['/profile']">Профили отправителя/получателя</a>
        </li>
        <li class="sidebar__menu__list__item">
            <a [routerLink]="['/history']">Мои отправления</a>
        </li>
        <li class="sidebar__menu__list__item">
            <a [routerLink]="['/archive']">Доставленные отправления</a>
        </li>
    </ul>

    <div class="sidebar__menu__footer">
        <a class="sidebar__menu__footer_link" [routerLink]="['/popular-questions']"
        [queryParams]="{h: 1}" [target]="'blank'">Как это работает?</a>
        <a [routerLink]="['/personal-data-policy']" [target]="'blank'" class="sidebar__menu__footer_link">Безопасность данных</a>
        <a [routerLink]="['/privacy-policy']" [target]="'blank'" class="sidebar__menu__footer_link">Пользовательское соглашение</a>
        <a [routerLink]="['/**']" class="sidebar__menu__footer__copyright_link">cdekpost.ru</a>
    </div>
</div>
