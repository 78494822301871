<div class="container login__container">
    <header class="header">
        <img src="../../../assets/img/logo.svg" class="header__logo">

        <div class="header__links">
            <a href='/popular-questions' [target]="'blank'">Популярные запросы</a>
            <a href='/privacy-policy' [target]="'blank'">Пользовательское соглашение</a>
            <a href='/personal-data-policy' [target]="'blank'">Политика обработки персональных данных</a>
        </div>
    </header>

    <div class="login">
        <div class="login__text_block">
            <h1 class="login__title h1">
                Электронная почта
            </h1>
            <p class="login__subtitle">Доставка деловой корреспонденции в течении рабочего дня</p>
            <p class="login__create_account_text text-secondary">Если у вас еще нет аккаунта - создайте аккаунт и
                отправляйте письма не выходя из дома</p>
            <button class="btn btn-success" [routerLink]="['/registration']">Создать аккаунт</button>
        </div>


        <div class="login__auth_form">
            <input type="email" class="form-control login__auth_form_input" id="user__email_or_phone"
                name="user__email_or_phone" placeholder="E-mail/номер телефона" [(ngModel)]="emailOrPhone">
            <input type="password" class="form-control login__auth_form_input" id="user__password" name="user__password"
                placeholder="******" [(ngModel)]="password">
            <div class="login__auth_form__btn_group">
                <button class="btn btn-success mr-3" (click)="signInHandler()" [disabled]="!emailOrPhone || !password">Войти</button>
                <button class="btn btn__forget_pass" [routerLink]="['/password-recovery']">Забыли пароль</button>
            </div>
        </div>



        <!-- <div class="login__form" *ngIf="sms">
                <label class="login__form_label" for="phone">
                    Вам должен поступить звонок. <br>Впишите последние 4 цифры номера в поле ниже.<br> Звонок можно
                    сбросить.
                </label>
                <div class="login__form__input_group d-flex">
                    <input class="login-form__input form-control" type="text" id="code" [(ngModel)]="code"
                            (ngModelChange)="changeCode()" (keyup.enter)="send($event)">
                    <button id="send-code-button" class="login-form__button btn btn-success" (click)="send($event)" disabled>
                        <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                                fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                    d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z"/>
                            <path fill-rule="evenodd"
                                    d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z"/>
                        </svg>
                    </button>
                </div>
            </div> -->

    </div>




    <!-- <div class="login">
        <div class="login-block">
            <h1 class="login__title h1">
                Электронная почта
            </h1>
            <div class="login__subtitle mb-5">
                Доставка деловой корреспонденции в течении рабочего дня
            </div>

            <ng-container *ngIf="!sms">
                <div class="login-form w-75">
                    <label class="login-form__label form-label mb-5" for="phone">
                        Чтобы мы не потеряли вашу почту, введите номер телефона. <br/><br/> -->
    <!-- Введите свой номер телефона для входа.<br> -->

    <!-- <span class="login-form__label-text">
                            <div>После этого вам должен поступить звонок.</div>
                            <div>Впишите последние 4 цифры номера в поле.</div>
                            <div>Звонок можно сбросить.</div>
                        </span>
                    </label>
                    <div class="d-flex align-items-center justify-content-center w-100">
                        <input class="login-form__input form-control mr-3" id="phone" [ngModel]="phone" maxlength="16"
                               placeholder="+7 (555) 555-5555" (ngModelChange)="changeNumberPhone($event)"
                               (keyup.enter)="sendSms($event)">
                        <button id="send-sms-button" class="login-form__button btn btn-success" disabled
                                (click)="sendSms($event)">
                            <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z"/>
                                <path fill-rule="evenodd"
                                      d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z"/>
                            </svg>
                        </button>
                    </div>
                    <label class="login-form__label form-label"></label>
                </div>
            </ng-container>


             <ng-container>
                <div class="login-form" *ngIf="sms">
                    <label class="login-form__label form-label" for="phone">
                        Вам должен поступить звонок. <br>Впишите последние 4 цифры номера в поле ниже.<br> Звонок можно
                        сбросить.
                    </label>
                    <div class="d-flex align-items-center justify-content-center">
                        <input class="login-form__input form-control mr-3 w-25" type="text" id="code" [(ngModel)]="code"
                               (ngModelChange)="changeCode()" (keyup.enter)="send($event)">
                        <button id="send-code-button" class="login-form__button btn btn-success" (click)="send($event)">
                            <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                                 fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd"
                                      d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z"/>
                                <path fill-rule="evenodd"
                                      d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </ng-container>
        </div>  -->

    <!-- <div class="footer d-flex justify-content-center align-items-center flex-column mb-5 mb-sm-0">
            <div class="login-form d-flex flex-column justify-content-center align-items-center w-75 mb-5">
                <label class="login-form__label form-label" for="track-number">Отследить отправление о номеру трекера
                    (квитанции,
                    накладной)</label>
                <div class="d-flex align-items-center w-100">
                    <input class="login-form__input form-control mr-3 w-100" id="track-number" [(ngModel)]="trackNumber">
                    <button id="get-track-info-button"
                            [disabled]="!trackNumber || !trackNumber.length || trackNumber.length < 4"
                            class="login-form__button btn btn-success" (click)="goTrackDetails()">


                        <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                             fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd"
                                  d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z"/>
                            <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </div> -->
    <!-- </div> -->
    <footer>cdekpost.ru</footer>
</div>
