<app-header [title]="'Детали отправления'" (toggleSidebar)="sidebarOpen($event)"></app-header>
<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>
    <main class="content">
        <div class="container" *ngIf="post">
            <div class="row mb-5 align-items-center">
                <div class="col-lg-9">
                    <div class="row align-items-start">
                        <div class="col-12 col-sm-6 col-lg-3 mb-3" *ngIf="!post.cdekNumber && !post.spi">
                            <label for="idDeparture" class="form-label">Идентификатор отправления:</label>
                            <input type="text" class="form-control" [(ngModel)]="post.id" id="idDeparture" readonly />
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 mb-3" *ngIf="post.cdekNumber">
                            <label for="number" class="form-label">Номер отслеживания:</label>
                            <a target="_blank"
                                [href]="'https://www.cdek.ru/ru/tracking?order_id=' + post.cdekNumber">{{post.cdekNumber}}</a>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 mb-3" *ngIf="post.spi">
                            <label for="number" class="form-label">Номер отслеживания:</label>
                            <span>{{post.spi}}</span>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 mb-3">
                            <label class="form-label">Статус:</label>
                            <div class="status">{{post.status | statusPost}}</div>
                        </div>
                        <div class="col-12 col-sm-6 col-lg-3 mb-3">
                            <label for="#" class="form-label">Дата отправки:</label>
                            <div class="date">{{post.createdAt | toLocalDate}}</div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 main-block-btn-detalis">
                    <div *ngIf="isReceivePdf(post.status)" (click)="genReceivePdf(post.id)" class="btn btn-link">Квитанция о вручении</div>
                    <div *ngIf="isSendPdf(post.status)" (click)="genSendPdf(post.id)" class="btn btn-link">Квитанция об отправлении</div>
                    <div *ngIf="isIndexPdf(post.status)" (click)="genIndexPdf(post.id)" class="btn btn-link">Опись отправления</div>
                    <div class="mt-2" *ngIf="waybill">
                        <button class="btn btn-link" (click)="downloadAttachment(post.id, waybill.id)">Накладная с подписями</button>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12 mb-5" *ngIf="officeR">
                    <div class="block">
                        <div class="block__label">Подразделения доставки:</div>
                        <div class="block__name">{{officeR}}</div>
                    </div>
                </div>
                <div class="col-12 mb-5">
                    <div class="block">
                        <div class="block__label">Получатель:</div>
                        <div class="block__name">{{post.receiver.name}}</div>
                        <div class="block__address">{{post.destination.address}}</div>
                    </div>
                </div>
                <div class="col-12 mb-5">
                    <div class="block">
                        <div class="block__label">Отправитель:</div>
                        <div class="block__name">
                            <span *ngIf="post.sender.company">{{post.sender.company}} ({{post.sender.name}})</span>
                            <span *ngIf="!post.sender.company">{{post.sender.name}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-5">
                    <div class="block" *ngIf="post && post.attachments">
                        <div class="block__label">Вложения</div>
                        <div class="block__links">
                            <span *ngFor="let att of post.attachments" class="btn btn-link pl-0"
                                (click)="downloadAttachment(post.id, att.id)">{{att?.alternativeName == null || att?.alternativeName == '' ? att.filename : att.alternativeName}}</span>
                        </div>
                    </div>
                </div>
                <div class="col-12 mb-5">
                    <div class="block">
                        <div *ngIf="post.cost">
                            <div class="block__label">Стоимость</div>
                            <div class="block__name">{{(post.cost / 100) | number : "1.0-0"}} р.</div>
                        </div>
                        <div class="block__status">
                            <span *ngIf="!isNew(post.status)">Оплачено</span>
                            <span *ngIf="isNew(post.status)">Не оплачено</span>
                        </div>
                    </div>
                </div>
                <div class="col-12" *ngIf="isNew(post.status)">
                    <button data-toggle="modal" data-target="#exampleModalCenter" class="btn btn-success mr-3">Оплатить</button>
                    <button (click)="removePost(post.id)" class="btn btn-danger">Удалить отправление</button>
                </div>
                <!--(click)="paymentPost(post)"-->
            </div>
        </div>
    </main>
</div>


<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLongTitle">Содержимое отправления</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                В описи отправления будут указаны названия файлов. Продолжить?

                <div class="block__links" *ngIf="post && post.attachments">
                    <span *ngFor="let att of post.attachments" class="btn btn-link pl-0"
                        (click)="downloadAttachment(post.id, att.id)">{{att.filename}}</span>
                </div>
            </div>

            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Отмена</button>
                <button type="button" class="btn btn-primary" (click)="paymentPost(post)">Продолжить</button>
            </div>
        </div>
    </div>
</div>
