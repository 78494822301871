import { Component, OnInit } from '@angular/core';

@Component({
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
    isShowSidebar: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    sidebarOpen(open: boolean) {
        this.isShowSidebar = open;
    }
}
