import {CustomerLocation, ReceiverLocation} from './customer-location.model';
export class Customer {
    id: string;
    friendlyName: string;
    phone: string;
    passwordHash: string;
    email: string;
    locations?: CustomerLocation[];
    receivers?: ReceiverLocation[];
}

export enum TypeRegistration {
    Email = 'email',
    Phone = 'phone'
}
