<app-header [title]="'Политика обработки персональных данных'" (toggleSidebar)="sidebarOpen($event)"></app-header>

<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>

    <main class="content">
        <div class="container">
            <div class="customer-reviews container cdek-container">
                <h1 class="customer-reviews__title">Политика в отношении обработки персональных данных и их защиты</h1>
                <div class="customer-reviews__wrap-content">
                    <p class="customer-reviews__content">Скачать&nbsp;<a
                            href="https://www.cdek.ru/storage/source/document/1/privacy.pdf">документ в PDF</a></p>
                </div>
            </div>
            <div class="container cdek-container text-block" data-v-429b4b6d="">
                <div class="layout" data-v-429b4b6d="">
                    <div class="text-block__box appear-animation" data-v-429b4b6d=""><br />См. также:&nbsp;<a
                            href="https://www.cdek.ru/agreement">Пользовательское соглашение</a><br /><br /><strong>I
                            ОБЩИЕ ПОЛОЖЕНИЯ</strong><br />Настоящая Политика разработана и применяется в ООО
                        &laquo;РЕШЕНИЯ БУДУЩЕГО&raquo; на основании статей 23, 24 Конституции Российской Федерации,
                        Федерального закона от 27.07.2006 №149-ФЗ&nbsp; &laquo;Об информации, информационных технологиях
                        и о защите информации&raquo;, Федерального закона от 27.07.2006 №152-ФЗ &laquo;О персональных
                        данных&raquo;, Федерального закона от 19.12.2005 г. №160-ФЗ &laquo;О ратификации Конвенции
                        Совета Европы о защите физических лиц при автоматизированной обработке персональных
                        данных&raquo;, Постановления&nbsp; Правительства Российской Федерации от 01 ноября 2012г. №1119
                        &laquo;Об утверждении требований к защите персональных данных при их обработке в информационных
                        системах персональных данных&raquo;, Постановления Правительства РФ от 15.09.2008 N 687
                        &laquo;Об утверждении Положения об особенностях обработки персональных данных, осуществляемой
                        без использования средств автоматизации&raquo;, Приказа Федеральной службы по техническому и
                        экспортному контролю от 18 февраля 2013г. №21 &laquo;Об утверждении состава и содержания
                        организационных мер по обеспечению безопасности персональных данных при их обработке в
                        информационных системах персональных данных&raquo; и иными нормативными и ненормативными
                        правовыми актами, регулирующими вопросы обработки персональных данных.<br /><br />Настоящая
                        Политика определяет действия Оператора в отношении обработки персональных данных физических лиц,
                        передавших свои персональные данные для обработки, &nbsp;порядок и условия осуществления
                        обработки персональных данных, обеспечение безопасности персональных данных с использованием и
                        без использования средств автоматизации, устанавливает процедуры, направленные на предотвращение
                        нарушений законодательства Российской Федерации, устранение последствий нарушений, связанных с
                        обработкой персональных данных.<br /><br />Политика разработана с целью обеспечения защиты прав
                        и свобод Субъектов персональных данных при обработке их персональных данных, а также с целью
                        установления ответственности лиц Общества, имеющих доступ к персональным данным Субъектов
                        персональных данных, за невыполнение требований и норм, регулирующих обработку персональных
                        данных.<br />Действие настоящей Политики не распространяется на отношения, возникающие при
                        обработке персональных данных сотрудников Оператора, соискателей вакантных должностей Общества
                        поскольку такие отношения урегулированы отдельным локальным актом; на отношения, на которые
                        действие Федерального закона &laquo;О персональных данных&raquo; не распространяется (п.2 ст.1
                        ФЗ).<br />Настоящая Политика применяется в частности, но не ограничиваясь, при навигации на
                        сайте&nbsp;<a href="http://www.cdek.ru/">www.cdek.ru</a>без совершения заказа на оказание услуг,
                        а также при пользовании сервисами, предложенными на&nbsp;<a
                            href="http://www.cdek.ru/">www.cdek.ru</a>, &nbsp;в том числе без выполнения регистрации
                        на&nbsp;<a href="http://www.cdek.ru/">www.cdek.ru</a>; при выполнении регистрации на&nbsp;<a
                            href="http://www.cdek.ru/">www.cdek.ru</a>.; при оформлении заказа на&nbsp;<a
                            href="http://www.cdek.ru/">www.cdek.ru</a>, при ином использовании Сайта, в соответствии с
                        Пользовательским соглашением.<br />&nbsp;<br /><br /><strong>1.1. Понятия, используемые в
                            настоящей Политике</strong><br /><em>Персональные данные</em>&nbsp;- любая информация,
                        относящаяся к прямо или косвенно определенному или определяемому физическому лицу (Субъекту
                        персональных данных), являющаяся конфиденциальной информацией ограниченного доступа, не
                        составляющей государственную тайну;<br /><br /><em>Субъект персональных данных</em>&nbsp;&mdash;
                        физическое лицо, носитель персональных дынных, чьи персональные данные переданны Обществу для
                        обработки<em>.</em><br /><br /><em>Оператор персональных данных</em>&mdash; ООО &laquo;РЕШЕНИЯ
                        БУДУЩЕГО&raquo; (ИНН 5401401030,
                        630089, РОССИЯ, НОВОСИБИРСКАЯ ОБЛ, Г НОВОСИБИРСК, УЛ АДРИЕНА ЛЕЖЕНА, Д 26, КВ 46),
                        государственный орган, муниципальный орган, юридическое или физическое лицо, самостоятельно или
                        совместно с другими лицами организующие и (или) осуществляющие обработку персональных данных, а
                        также определяющие цели обработки персональных данных, состав персональных данных, подлежащих
                        обработке, действия (операции), совершаемые с персональными данными;<br /><br /><em>Обработка
                            персональных данных</em>&nbsp;- любое действие (операция) или совокупность действий
                        (операций), совершаемых с использованием средств автоматизации или без использования таких
                        средств с персональными данными, включая сбор, запись, систематизацию, накопление, хранение,
                        уточнение (обновление, изменение), извлечение, использование, передачу (распространение,
                        предоставление, доступ), обезличивание, блокирование, удаление, уничтожение персональных
                        данных;<br /><br /><em>Автоматизированная обработка персональных данных</em>&nbsp;- обработка
                        персональных данных с помощью средств вычислительной техники;<br /><br /><em>Распространение
                            персональных данных</em>&nbsp;- действия, направленные на раскрытие персональных данных
                        неопределенному кругу лиц;<br /><br /><em>Предоставление персональных данных</em>&nbsp;-
                        действия, направленные на раскрытие персональных данных определенному лицу или определенному
                        кругу лиц;<br /><br /><em>Блокирование персональных данных</em>&nbsp;- временное прекращение
                        обработки персональных данных (за исключением случаев, если обработка необходима для уточнения
                        персональных данных);<br /><br /><em>Уничтожение персональных данных</em>&nbsp;- действия, в
                        результате которых становится невозможным восстановить содержание персональных данных в
                        информационной системе персональных данных и (или) в результате которых уничтожаются
                        материальные носители персональных данных;<br /><br /><em>Обезличивание персональных
                            данных</em>&nbsp;- действия, в результате которых становится невозможным без использования
                        дополнительной информации определить принадлежность персональных данных конкретному Субъекту
                        персональных данных;<br /><br /><em>Использование&nbsp; персональных данных
                            &mdash;&nbsp;</em>действия с персональными данными, совершаемые Оператором в целях принятия
                        решений или совершения иных действий, порождающих юридические последствия в отношении Субъекта
                        персональных данных или других лиц либо иными образом затрагивающих права и свободы Субъекта
                        персональных данных или других лиц.<br /><br /><em>Информационная система персональных
                            данных</em>&nbsp;- совокупность содержащихся в базах данных персональных данных и
                        обеспечивающих их обработку информационных технологий и технических
                        средств;<br /><br /><em>Трансграничная передача персональных данных</em>&nbsp;- передача
                        персональных данных на территорию иностранного государства органу власти иностранного
                        государства, иностранному физическому лицу или иностранному юридическому
                        лицу.<br /><br /><em>Конфиденциальность персональных данных</em>&nbsp;&mdash; обязательное для
                        соблюдения Оператором или иным получившим доступ к персональным данным лицом требование не
                        допускать их распространение без согласия Субъекта персональных данных или наличия законного
                        основания.<br /><br /><em>Сайт&nbsp;</em>&mdash; совокупность информации, текстов, графических
                        элементов, дизайна, изображений, фото и видеоматериалов, иных результатов интеллектуальной
                        деятельности, а также программных средств для ЭВМ, обеспечивающих публикацию для всеобщего
                        обозрения информации и данных, объединенных общим целевым назначением, посредством технических
                        средств, применяемых для связи между ЭВМ и сети Интернет. Сайт находится в сети Интернет по
                        адресу:&nbsp;<a
                            href="https://www.cdek.ru/">https://www.cdek.ru</a>.<br /><br /><em>Пользователь</em>&mdash;
                        &nbsp;физическое лицо, действующее в своих интересах или в интересах других лиц, акцептовавшее
                        Пользовательское соглашение, размещенное на Сайте, имеющее доступ к Сайту и использующее его,
                        независимо от факта регистрации на Сайте.<br />&nbsp;<br />&nbsp;<br /><br /><strong>1.2.
                            Сокращения</strong><strong>, используемые в настоящей Политике</strong><br />ИС &ndash;
                        информационная система<br />Общество&mdash; ООО &laquo;РЕШЕНИЯ БУДУЩЕГО&raquo;<br />Политика
                        &mdash; настоящая Политика в отношении обработки персональных данных и их защите в ООО
                        &laquo;РЕШЕНИЯ БУДУЩЕГО&raquo;<br />ПДН &mdash; Персональные данные<br />РФ &ndash; Российская
                        Федерация<br />Субъект&mdash; Субъект персональных данных<br />ФЗ &ndash; Федеральный закон от
                        27.07.2006 №153-ФЗ &laquo;О персональных
                        данных&raquo;<br />&nbsp;<br /><br /><strong>1.3.Принципы обработки ПДН</strong><br />1.3.1.
                        Осуществление обработки ПДН на законной и справедливой основе;<br />1.3.2. Ограничения обработки
                        ПДН достижением конкретных, заранее определенных и законных целей. Недопустимости обработки ПДН,
                        несовместимой с целями сбора ПДН.<br />1.3.3. Недопустимости объединения созданных для
                        несовместимых между собой целей баз данных, содержащих ПДН;<br />1.3.4. Соответствия содержания
                        и объема ПДН целям их обработки;<br />1.3.5. Недопустимости обработки ПДН избыточных по
                        отношению к заявленным целям их обработки;<br />1.3.6. Обеспечения точности, достаточности, а в
                        необходимых случаях и актуальности ПДН по отношению к целям обработки;<br />1.3.7. Принятия
                        необходимых мер или обеспечения принятия мер по удалению или уточнению неполных или неточных
                        ПДН;<br />1.3.8. Хранения ПДН в форме, позволяющей определить Субъекта ПДН, не дольше, чем этого
                        требуют цели обработки ПДН;<br />1.3.9. Уничтожения ПДН по достижении целей их обработки или в
                        случае утраты необходимости в достижении этих целей, если иное не предусмотрено федеральным
                        законом.<br />&nbsp;<br /><br /><strong>1.4. Условия обработки ПДН</strong><br />1.4.1.
                        Обработка ПДН осуществляется с согласия Субъекта на обработку его ПДН;<br />1.4.2. Обработка ПДН
                        необходима для достижения целей, предусмотренных международным договором РФ или законом, для
                        осуществления и выполнения возложенных законодательством РФ на Оператора функций, полномочий и
                        обязанностей;<br />1.4.3. Обработка ПДН необходима для исполнения договора, стороной которого
                        либо выгодоприобретателем или поручителем по которому является Субъект ПДН, в том числе в случае
                        реализации Оператором своего права на уступку прав (требований) по такому договору,&nbsp; а
                        также для заключения договора по инициативе Субъекта ПДН или договора, по которому Субъект ПДН
                        будет являться выгодоприобретателем или поручителем;<br />1.4.4. Обработка ПДН необходима для
                        использования Сайта Пользователем;<br />1.4.5.Обработка ПДН необходима для осуществления
                        правосудия, исполнения судебного акта, акта другого органа или должностного лица, подлежащих
                        исполнению в соответствии с законодательством РФ об исполнительном производстве;<br />1.4.6.
                        Обработка ПДН необходима для защиты жизни, здоровья или иных жизненно важных интересов Субъекта
                        ПДН, если получение согласия Субъекта ПДН невозможно;<br />1.4.7. Обработка ПДН необходима для
                        осуществления прав и законных интересов Оператора или третьих лиц либо для достижения
                        общественно значимых целей при условии, что при этом не нарушаются права и свободы Субъекта
                        ПДН.<br />1.4.8. Осуществляется обработка ПДН, доступ неограниченного круга лиц к которым
                        предоставлен Субъектом ПДН либо по его просьбе (далее - ПДН, сделанные общедоступными
                        Субъектом);<br />1.4.9. Осуществляется обработка ПДН, подлежащих опубликованию или обязательному
                        раскрытию в соответствии с федеральным законом.<br />1.4.10. Оператор не осуществляет действий,
                        направленных на раскрытие ПДН неопределенному кругу лиц, т.е не осуществляет распространение
                        ПДН.<br />1.4.11. Обработка ПДН осуществляется в статистических или иных исследовательских
                        целях, за исключением целей, указанных в ст.15 ФЗ, при условии обязательного обезличивания
                        ПДН.<br />1.4.12. Оператор вправе поручить обработку ПДН другому лицу с согласия Субъекта ПДН.
                        Передача необходима в рамках использования Сайта либо для оказания услуг Субъекту ПДН, при этом
                        обеспечивается конфиденциальность ПДН. Лица, осуществляющие обработку ПДН по поручению Общества,
                        обязуются соблюдать принципы и правила обработки ПДН, предусмотренные Федеральным законом
                        №152-ФЗ &laquo;О персональных данных&raquo;. Для каждого лица определены перечень действий
                        (операций) с ПДН, которые будут совершаться организацией, осуществляющей обработку ПДН, цели
                        обработки, установлена обязанность такого лица соблюдать конфиденциальность и обеспечивать
                        безопасность ПДН при их обработке, а также указаны требования к защите обрабатываемых
                        ПДН.<br />&nbsp;<br /><br /><strong>II ЦЕЛИ ОБРАБОТКИ ПДН</strong><br />2.1.Общество
                        руководствуется конкретными, заранее определенными целями обработки ПДН, в соответствии с
                        которыми ПДН были предоставлены Субъектом, в частности:<br />2.1.1.обеспечение защиты прав и
                        свобод Субъекта при обработке его ПДН;<br />2.1.2. ведение бухгалтерского учета и
                        отчетности;<br />2.1.3. обеспечение Пользователю возможности взаимодействовать с Сайтом, в
                        частности направление уведомлений, запросов и информации, связанных с оказанием услуг, а также
                        обработка запросов и заявок от Пользователя,предоставление доступа к персонализированным
                        ресурсам Сайта, предоставления Пользователю специальных предложений, любых информационных
                        сообщений, включая рекламу и иных сведений от имени Сайта или от имени партнеров Сайта,
                        предоставления доступа Пользователю на сайты или сервисы партнеров Сайта, в соответствии с
                        Пользовательским соглашением;<br />2.1.4. оказания услуг курьерской доставки Клиентам в рамках
                        договора возмедного оказания курьерских услуг;<br />2.1.5. ведение уставной деятельности
                        Общества в части заключения, учета и исполнения договоров с контрагентами (Заказчики,
                        Подрядчики, Исполнители и т.п.);<br />2.1.6. проведение опросов и исследований, направленных на
                        выявление удовлетворенности/неудовлетворенности Клиента услугами Общества, улучшение качества
                        услуг;<br />2.1.7. проведение статистических и иных исследований на основе обезличенных
                        данных.<br />&nbsp;<br /><br /><br /><strong>III ПРАВОВОЕ ОСНОВАНИЕ ОБРАБОТКИ
                            ПДН</strong><br />3.1. Гражданский кодекс РФ (гл.39 ГК РФ);<br />3.2. Налоговый кодекс
                        РФ;<br />3.3. Федеральный закон от 15.12.2001 N 167-ФЗ &laquo;Об обязательном пенсионном
                        страховании в Российской Федерации&raquo;;<br />3.4. Устав Общества;<br />3.5. Договор
                        возмездного оказания курьерских услуг, заключенный между Обществом и Субъектом ПДН
                        (Оферта);<br />3.6. Пользовательское соглашение, размещенное на Сайте;<br />3.7. Согласие
                        Субъекта на обработку ПДН.<br />&nbsp;<br /><br /><br /><strong>IVКАТЕГОРИИ СУБЪЕКТОВ ПДН И
                            ПЕРЕЧЕНЬ ПДН</strong><br />4.1. Физическое лицо &mdash; Пользователь
                        сайта.<br />Имя<br />Адрес электронной почты<br />Адрес (город, улица, номер дома, номер
                        квартиры)<br />Номер телефона<br />Сведения об используемом
                        браузере;<br />Местоположение;<br />IP-адрес;<br />Запрашиваемые
                        Интернет-страницы;<br />Источник захода на Сайт&nbsp;<a
                            href="https://www.cdek.ru/">https://www.cdek.ru</a><br />4.2. Физическое лицо, с которым
                        заключен договор гражданско-правового характера;<br />Фамилия, Имя, Отчество<br />Адрес (страна,
                        город, улица, номер дома, номер квартиры)<br />Данные расчетного
                        счета<br />СНИЛС<br />ИНН<br />Данные документа, удостоверяющего личность.<br />4.3. Физическое
                        лицо-Клиент, чьи ПДН стали известныОператору в связи с заключением и исполнением договора&nbsp;
                        оказания услуг:<br />Фамилия, Имя, Отчество<br />данные документа, удостоверяющего
                        личность<br />Адрес (страна, город, улица, номер дома, номер квартиры)<br />адрес электронной
                        почты;<br />номер телефона (домашний, мобильный);<br />4.5.Физическое лицо, чьи ПДН
                        поступилиОператору от Заказчика курьерских услуг в рамках договора возмездного оказания
                        курьерских услуг, при этом указанные ПДН не являются объектом действий Оператора&nbsp; и
                        получение согласия на их обработку является обязанностью соответствующего Клиента, о чем
                        прописано с ним в договоре;<br />Фамилия, Имя, Отчество<br />данные документа, удостоверяющего
                        личность<br />Адрес (страна, город, улица, номер дома, номер квартиры<br />номер телефона
                        (домашний, мобильный)<br />4.6. Физическое лицо, чьи ПДН поступили от контрагента в рамках
                        договора гражданско-правового характера, при этом указанные ПДН не являются объектом действий
                        Оператора и получение согласия на их обработку является обязанностью соответствующего
                        подрядчика/исполнителя;<br />Фамилия, Имя, Отчество<br />данные документа, удостоверяющего
                        личность (при предоставлении доверенности)<br />Адрес электронной
                        почты.<br />&nbsp;<br /><br /><br /><strong>V ОСНОВНЫЕ ПРАВА И ОБЯЗАННОСТИ СУБЪЕКТА ПДН И
                            ОБЩЕСТВА В ОБЛАСТИ ЗАЩИТЫ ПДН</strong><br />5.1. Субъект имеет право:<br />5.1.1. на
                        получение информации, касающейся обработки его ПДН, в частности:<br />- подтверждение факта
                        обработки ПДН Обществом;<br />- правовые основания и цели обработки ПДН;<br />- применяемые
                        Обществом способы обработки ПДН;<br />- наименование и местонахождение Общества, сведения о
                        лицах (за исключением сотрудников Общества), которые имеют доступ к ПДН или которым могут быть
                        раскрыты ПДН на основании договора с Обществом или на основании федерального закона;<br />-
                        обрабатываемые ПДН, относящиеся к соответствующему Субъекту ПДН, источник их получения, если
                        иной порядок предоставления не предусмотрен федеральным законом;<br />- срок обработки ПДН и
                        срок их хранения;<br />- порядок осуществления Субъектом ПДН своих прав;<br />- наименование или
                        фамилию, имя, отчество и адрес лица, осуществляющего обработку ПДН по поручению Общества, если
                        обработка поручена или будет поручена такому лицу;<br />- иные сведения, предусмотренные
                        федеральным законом.<br />5.1.2.требовать от Обществауточнения его ПДН, их блокирования или
                        уничтожения в случае, если ПДНявляются неполными, устаревшими, неточными, незаконно полученными
                        или не являются необходимыми для заявленной цели обработки, а также принимать предусмотренные
                        законом меры по защите своих прав<br />5.1.3. требовать от Общества извещения всех лиц, которым
                        ранее были сообщены неверные или неполные ПДН, обо всех произведенных в них исключениях,
                        исправлениях и дополнениях;<br />5.1.4. отозвать свое согласие на обработку ПДН;<br />5.1.5. на
                        свободный безвозмездный доступ к своим ПДН посредством личного обращения либо направления
                        запроса(Форма запроса приведена в Приложении №1).При этом сведения о ПДН предоставляются в
                        доступной форме, исключая ПДН, относящиеся к другим Субъектам ПДНпо месту расположения Общества
                        в рабочее времяв течение тридцати дней с момента получения запроса;<br />5.1.6. обжаловать
                        действия или бездействие Общества в уполномоченный орган по защите прав Субъектов ПДН или в
                        судебном порядке.<br />5.1.7. иные права, предусмотренные действующим
                        законодательством.<br />5.2.&nbsp; Субъект ПДН обязан:<br />5.2.1. передавать Обществу
                        достоверные ПДН. Общество вправе проверять достоверность предоставленных ПНД в порядке, не
                        противоречащем законодательству РФ, однако Общество исходит из того, что Субъект ПНД
                        предоставляет достоверные и достаточные ПДН для осуществления целей обработки ПДН, и
                        поддерживает эту информацию в актуальном состоянии.<br />5.2.2.&nbsp; своевременно сообщать
                        Обществу об изменении своих ПДН.<br />5.3. Субъект ПДН принимает решение о предоставлении его
                        ПДН и дает согласие на их обработку свободно, своей волей и в своем интересе.<br />5.4.
                        Обязанности Оператора:<br />5.4.1. обеспечивать конфиденциальность ПДН. Оператор и иные лица,
                        получившие доступ к ПДН, обязаны не раскрывать третьим лицам и не распространять ПДН без
                        согласия Субъекта ПДН, если иное не предусмотрено законом;<br />5.4.2. опубликовать или иным
                        образом обеспечить неограниченный доступ к документу, определяющему его политику в отношении
                        обработки ПДН, сведениям о реализуемых требованиях к защите ПД;<br />5.4.3. принимать
                        необходимые правовые, организационные и технические меры или обеспечивать их принятие для защиты
                        ПДН от неправомерного или случайного доступа к ним, уничтожения, изменения, блокирования,
                        копирования, предоставления, распространения ПДН, а также от иных неправомерных действий в
                        отношении ПДН;<br />5.4.4. предоставлять ответы на запросы и обращения Субъектов ПДН, их
                        представителей и уполномоченного органа по защите прав субъектов персональных
                        данных;<br />5.4.5. иные обязанности Опертора, предусмотренные
                        законодательством.<br />&nbsp;<br /><br /><br /><strong>VI ПОРЯДОК ОБРАБОТКИ
                            ПДН.</strong><br />6.1. Оператор осуществляет обработку ПДН Субъектов посредством совершения
                        любого действия (операции) или совокупности действий (операций), совершаемых с использованием
                        средств автоматизации или без использования таких средств, включая следующие:<br />-
                        сбор;<br />- запись;<br />- систематизацию;<br />- накопление;<br />- хранение;<br />- уточнение
                        (обновление, изменение);<br />- извлечение;<br />- использование;<br />- передачу
                        (предоставление);<br />- обезличивание;<br />- блокирование;<br />- удаление;<br />-
                        уничтожение.<br />6.2. Оператор получает ПДН:<br />6.2.1. путем личной передачи Субъектом ПДН
                        при внесении данных на Сайте;<br />6.2.2. путем личной передачи Субъектом в рамках
                        гражданско-правовых отношений;<br />6.2.3. от третьих лиц (Клиентов, контрагентов);<br />6.2.4.
                        из общедоступных источников.<br />6.3. Оператор получает и начинает обработку ПДН Субъекта с
                        момента получения его согласия.<br />Согласие на обработку ПДН может быть дано Субъектом ПДН в
                        любой форме, позволяющей подтвердить факт получения согласия, если иное не установлено
                        федеральным законом: в письменной, устной или иной форме, предусмотренной действующим
                        законодательством, в том числе посредством совершения Субъектом ПДН конклюдентных
                        действий.<br />В частности, согласие на обработку ПДН считается предоставленным Субъектом
                        посредством совершения Субъектом ПДН следующих конклюдентных действий в совокупности:<br />-
                        путем проставления специального знака &mdash; &laquo;галочки&raquo; или &laquo;веб-метки&raquo;
                        в специальном поле на Сайте при Заказе обратного звонка, при обращении в форме Обратной связи,
                        Контроль качества, при регистрации в Личном кабинете, при оформлении Заявки на вызов курьера и
                        нажатия соответствующей кнопки расценивается однозначно, как принятие условий Пользовательского
                        соглашения и предоставление согласия на обработку ПДН в объеме, для целей и в порядке,
                        предусмотренных в предлагаемом перед проставлением специального знака для ознакомления тексте
                        (текст Согласия - Приложение № 2 к настоящей Политике).Согласие считается полученным с момента
                        проставления специального знака и действует до момента направления Субъектом ПДН
                        соответствующего заявления о прекращении обработки ПДН по месту нахождения Оператора или по
                        электронному адресу:&nbsp;<a href="mailto:pretenzia@cdek.ru">pretenzia@cdek.ru</a><br />В случае
                        отсутствия согласия Субъекта на обработку его ПДН, такая обработка не осуществляется.<br />6.4.
                        Получение Оператором ПДН от иных лиц, а равно передача поручения по обработке ПДН осуществляется
                        на основании договора, содержащего условия о порядке обработки и сохранения конфиденциальности
                        полученных ПДН.<br />6.5. При обработке ПДН Исполнительный орган Общества вправе утверждать
                        способы обработки, документирования, хранения и защиты ПДН на базе современных информационных
                        технологий.<br />6.6. Оператор до начала обработки ПДН назначает ответственного за организацию
                        обработки ПДН в должности не ниже начальника структурного подразделения, именуемого далее
                        &laquo;Куратор ОПД&raquo;, который получает указания непосредственно от исполнительного органа
                        Оператора и подотчетен ему.<br />6.7. Перечень лиц, допущенных к обработке ПДН, определяется
                        распоряжением Исполнительного органа и внутренними локальным нормативными актами Общества.
                        Указанные лица должны быть ознакомлены до начала работы:<br />- с положениями законодательства
                        Российской Федерации о ПДН, в том числе с требованиями к порядку защиты ПДН;<br />- с
                        документами, определяющими действия Оператора в отношении обработки ПДН, в том числе с настоящей
                        Политикой, приложениями и изменениями к ней;<br />- с локальными актами по вопросам обработки
                        ПДН.<br />Сотрудники Оператора имеют право получать только те ПДН, которые необходимы им для
                        выполнения конкретных должностных обязанностей. Сотрудники Оператора, осуществляющие обработку
                        ПДН, должны быть проинформированы о факте такой обработки, об особенностях и правилах такой
                        обработки, установленных нормативно-правовыми актами и внутренними документами Оператора.
                        Сотруднику Общества, имеющему право осуществлять обработку ПДН, предоставляются уникальный логин
                        и пароль для доступа к соответствующей ИС в установленном порядке. Каждый сотрудникпри
                        трудоустройстве получает логин и пароль для работы в соответствующей ИС. Сведения о присвоенных
                        Сотруднику идентификаторах (логин и пароль) относятся к конфиденциальным и не подлежат передаче
                        Сотрудником третьим лицам. Сотрудник обеспечивает соблюдение требований о конфиденциальности и
                        несет риск последствий, связанных с нарушением таких требований. Процедура аутентификации
                        осуществляется техническим центром работодателя при осуществлении&nbsp; доступа сотрудника в ИС
                        путем сопоставления введенных логина и пароля соответствующим присвоенным сотруднику логину и
                        паролю, информация о которых содержится в ИС. В случае успешного прохождения процедуры
                        аутентификации Сотрудник получает возможность осуществления операций в ИС.<br />6.8. Оценка
                        вреда, который может быть причинен Субъектам в случае нарушения Оператором требований ФЗ,
                        определяется в соответствии со ст. ст. 15, 151, 152, 1101 Гражданского кодекса РФ.<br />6.9. В
                        случае если Оператор поручает обработку ПДНтретьим лицам, не являющимся его сотрудниками, на
                        основании заключенных договоров (либо иных оснований), в силу которых они должны иметь доступ к
                        ПДНпользователей Сайта, соответствующие данные предоставляются Оператором только после
                        подписания с лицами, осуществляющими обработку ПДНпо поручению Оператора, соответствующего
                        соглашения, в котором должны быть определены перечень действий (операций) с ПДН, которые будут
                        совершаться лицом, осуществляющим их обработку, и цели обработки, должна быть установлена
                        обязанность такого лица соблюдать конфиденциальность ПДНи обеспечивать безопасность ПДНпри их
                        обработке, а также должны быть указаны требования к защите обрабатываемых ПДНв соответствии со
                        ст. 19 ФЗ &laquo;О персональных данных&raquo;.<br />6.10. ПДН не могут быть использованы в целях
                        причинения имущественного и морального вреда Субъектам, затруднения реализации прав и свобод
                        граждан РФ.<br />6.11. В целях собственногоинформационного обеспечения у Обществамогут
                        создаваться общедоступные источники ПДНСубъектов, в том числе справочники и адресные книги. В
                        общедоступные источники ПДНс письменного согласия Субъекта могут включаться его фамилия, имя,
                        отчество, номера контактных телефонов, адрес электронной почты и иные ПДН, сообщаемые Субъектом.
                        Сведения о Субъекте должны быть в любое время исключены из общедоступных источников ПДНпо
                        требованию Субъекта либо по решению суда или иных уполномоченных государственных
                        органов.<br />6.12. Обработка и хранение ПДН осуществляются не дольше, чем этого требуют цели
                        обработки ПДН, если отсутствуют законные основания для дальнейшей обработки, например, если
                        федеральным законом или согласием Субъекта, договором с Субъектом ПДН не установлен
                        соответствующий срок хранения. Обрабатываемые ПДН подлежат уничтожению либо обезличиванию по
                        достижении целей обработки или в случае утраты необходимости в достижении этих целей.<br />6.13.
                        Хранение ПДН, цели обработки которых различны,&nbsp; осуществляется раздельно в рамках
                        информационной системы или, при условии хранения на материальных носителях, в рамках структуры
                        дел соответствующего подразделения Оператора.<br />6.14. Сотрудник Оператора, имеющий доступ к
                        ПДН в связи с исполнением трудовых обязанностей обеспечивает хранение информации, содержащей ПДН
                        Субъектов, исключающее доступ к ним третьих лиц.<br />В отсутствие сотрудника на его рабочем
                        месте не должно находиться документов, содержащих ПДН. При уходе в отпуск, служебную
                        командировку и иных случаях длительного отсутствия сотрудника на рабочем месте, он обязан
                        передать документы и иные носители, содержащие ПДН лицу, на которое локальным актом Оператора
                        будет возложено исполнение его трудовых обязанностей. В случае если такое лицо не назначено, то
                        документы и иные носители, содержащие ПДН Субъектов, передаются другому сотруднику, имеющему
                        доступ к ПДН по указанию руководителя соответствующего структурного подразделения
                        Оператора.<br />При увольнении сотрудника, имеющего доступ к ПДН, документы и иные носители,
                        содержащие ПДН, передаются другому сотруднику, имеющему доступ к ПДН по указанию руководителя
                        структурного подразделения и с уведомлением лица, ответственного за обработку ПДН.<br />6.15.
                        Обработка ПДН прекращается, уничтожение/обезличивание ПДН производится в связи с:<br />6.15.1.
                        достижением цели обработки ПДН, утрата необходимости в достижении целей обработки ПДН - в
                        течение тридцати дней, если иное не предусмотрено договором;<br />6.15.2. истечением срока
                        действия согласия Субъекта ПДН - в течение тридцати дней;<br />6.15.3. выявление неправомерной
                        обработки ПДН &ndash; в течение трех дней с даты выявления;<br />6.15.4. невозможность
                        обеспечения правомерности обработки персональных данных - в течение десяти дней;<br />6.15.5.
                        отзыв&nbsp; согласия Субъекта ПДН если сохранение ПДН<br />более не требуется для целей
                        обработки ПДН - в течение 30 дней.<br />6.16. Субъект ПДН может в любой момент отозвать свое
                        согласие на обработку ПДН при условии, что подобная процедура не нарушает требований
                        законодательства РФ. В случае отзыва Субъектом согласия на обработку ПДН, Оператор вправе
                        продолжить обработку ПДН без согласия Субъекта только при наличии оснований, указанных в
                        ФЗ.<br />Для отзыва согласия на обработку ПДН&nbsp; необходимо подать соответствующее заявление
                        в письменной форме по месту нахождения Операторалибо направить электронное письмо по
                        электронному адресу:&nbsp;<a href="mailto:pretenzia@cdek.ru">pretenzia@cdek.ru</a><br />В случае
                        отзыва Субъектом согласия на обработку его ПДН, Оператор прекращает их обработку или
                        обеспечивает прекращение такой обработки (если обработка осуществляется другим лицом,
                        действующим по поручению Оператора) и в случае, если сохранение ПДН более не требуется для целей
                        их обработки, уничтожает ПДН или обеспечивает их уничтожение (если обработка осуществляется
                        другим лицом, действующим по поручению Оператора) в срок, не превышающий 30 (Тридцати) дней с
                        даты поступления указанного отзыва, если иное не предусмотрено договором, стороной которого,
                        выгодоприобретателем или поручителем по которому является Субъект ПДН, иным соглашением между
                        Оператором и Субъектом, либо если Оператор не вправе осуществлять обработку ПДН без согласия
                        Субъекта на основаниях, предусмотренных ФЗ или другими федеральными
                        законами.<br />&nbsp;<br /><br /><br /><strong>VII ОБЕСПЕЧЕНИЕ БЕЗОПАСНОСТИ
                            ПДН</strong><br />7.1. При обработке ПДНОператор применяет правовые, организационные и
                        технические меры и обеспечивает их принятие для защиты ПДН от неправомерного или случайного
                        доступа к ним, уничтожения, изменения, блокирования, копирования, предоставления,
                        распространения ПДН, а также от иных неправомерных действий в соответствии с требованиями к
                        обеспечению безопасности ПДН при их обработке в информационных системах ПДН, требованиям к
                        материальным носителям ПДН и технологиям хранения таких данных вне информационных систем ПДН,
                        установленными Правительством РФ.<br />7.2. Мероприятия по защите ПДН определяются Положениями,
                        Приказами, Инструкциями и другими локальными актами Общества.<br />7.3. Общество принимает меры,
                        необходимые и достаточные для обеспечения выполнения обязанностей, предусмотренных законами РФ и
                        принятыми в соответствии с ними нормативно-правовыми актами. Общество самостоятельно определяет
                        состав и перечень мер, необходимых и достаточных для обеспечения выполнения обязанностей,
                        предусмотренных Федеральным законом &laquo;О персональных данных&raquo; и принятыми в
                        соответствии с ним нормативно-правовыми актами, если иное не предусмотрено указанным
                        законом&nbsp; иди другими федеральными законами. Обеспечение безопасности ПДН достигается в
                        частности:<br />- назначением ответственного лица за организацию обработки ПДН;<br />-
                        определением угроз безопасности ПДН при их обработке в информационных системах ПДН;<br />-
                        применение организационных и технических мер по обеспечению безопасности ПДН;<br />- оценкой
                        эффективности принимаемых мер по обеспечению безопасности ПДН;<br />- обнаружением фактов
                        несанкционированного доступа к ПДН и принятием необходимых мер;<br />- восстановлением ПДН,
                        модифицированных или уничтоженных вследствие несанкционированного доступа к ним;<br />-
                        установлением правил доступа к ПДН, обрабатываемых в информационных системах ПДН;<br />-
                        контролем над принимаемыми мерами по обеспечению безопасности ПДН и уровня защищенности
                        информационных систем ПДН;<br />- оценкой вреда, который может быть причинен Субъектам ПДН в
                        случае нарушения ФЗ, соотношение указанного вреда и принимаемых Обществом мер, направленных на
                        обеспечение выполнения обязанностей, предусмотренных ФЗ;<br />- издание Обществом локальных
                        актов по вопросам обработки ПДН, а также локальных актов, устанавливающих процедуры,
                        направленные на предотвращение и выявление нарушений законодательства РФ, устранения последствий
                        таких нарушений;<br />- ознакомление сотрудников Общества, непосредственно осуществляющих
                        обработку ПДН, с положениями законодательства РФ, настоящей Политикой и другими локальными
                        актами по вопросам обработки ПДН, и (или) обучением (инструктаж) указанных
                        сотрудников.<br />&nbsp;<br /><br /><br />&nbsp;<br /><br /><br /><br />&nbsp;</div>
                </div>
            </div>
            <div class="container cdek-container text-block" data-v-429b4b6d="">
                <div class="layout" data-v-429b4b6d="">
                    <div class="text-block__box appear-animation" data-v-429b4b6d=""><strong>VIII ЗАПРОСЫ, ОБРАЩЕНИЯ И
                            ПОРЯДОК ИХ ОБРАБОТКИ ОПЕРАТОРОМ</strong><br />8.1. Оператор обязан предоставить безвозмездно
                        Субъекту или его представителю возможность ознакомления с ПДН, относящимися к этому Субъекту. В
                        срок, не превышающий семи рабочих дней со дня предоставления Субъектом или его представителем
                        сведений, подтверждающих, что ПДН являются неполными, неточными или неактуальными, Оператор
                        обязан внести в них необходимые изменения. В срок, не превышающий семи рабочих дней со дня
                        представления Субъектом&nbsp; или его представителем сведений, подтверждающих, что такие ПДН
                        являются незаконно полученными или не являются необходимыми для заявленной цели обработки,
                        Оператор обязан уничтожить такие ПДН. Оператор обязан уведомить Субъекта или его представителя о
                        внесенных изменениях и предпринятых мерах и принять разумные меры для уведомления третьих лиц,
                        которым ПДН этого Субъекта были переданы.<br />8.2. Оператор обязан сообщить в уполномоченный
                        орган по защите прав субъектов персональных данных по запросу этого органа необходимую
                        информацию в течение тридцати дней с даты получения такого запроса.<br />8.3. В случае выявления
                        неправомерной обработки ПДН при обращении Субъекта или его представителя либо по запросу
                        Субъекта или его представителя либо уполномоченного органа по защите прав субъектов персональных
                        данных Оператор обязан осуществить блокирование неправомерно обрабатываемых ПДН, относящихся к
                        этому Субъекту, или обеспечить их блокирование (если обработка ПДН осуществляется другим лицом,
                        действующим по поручению Оператора) с момента такого обращения или получения указанного запроса
                        на период проверки. В случае выявления неточных ПДН при обращении Субъекта или его представителя
                        либо по их запросу или по запросу уполномоченного органа по защите прав субъектов персональных
                        данных Оператор обязан осуществить блокирование ПДН, относящихся к этому Субъекту, или
                        обеспечить их блокирование (если обработка ПДН осуществляется другим лицом, действующим по
                        поручению Оператора) с момента такого обращения или получения указанного запроса на период
                        проверки, если блокирование ПДН не нарушает права и законные интересы Субъекта или третьих
                        лиц.<br />8.4. В случае подтверждения факта неточности ПДН Оператор на основании сведений,
                        представленных Субъектом или его представителем либо уполномоченным органом по защите прав
                        субъектов персональных данных, или иных необходимых документов обязан уточнить ПДН либо
                        обеспечить их уточнение (если обработка ПДН осуществляется другим лицом, действующим по
                        поручению Оператора) в течение семи рабочих дней со дня представления таких сведений и снять
                        блокирование ПДН.<br />8.5. В случае выявления неправомерной обработки ПДН, осуществляемой
                        Оператором или лицом, действующим по поручению Оператора, Оператор в срок, не превышающий трех
                        рабочих дней с даты этого выявления, обязан прекратить неправомерную обработку ПДН или
                        обеспечить прекращение неправомерной обработки ПДН лицом, действующим по поручению Оператора. В
                        случае, если обеспечить правомерность обработки ПДН невозможно, Оператор в срок, не превышающий
                        десяти рабочих дней с даты выявления неправомерной обработки ПДН, обязан уничтожить такие ПДН
                        или обеспечить их уничтожение. Об устранении допущенных нарушений или об уничтожении ПДН
                        Оператор обязан уведомить Субъекта или его представителя, а в случае, если обращение Субъекта
                        или его представителя либо запрос уполномоченного органа по защите прав субъектов персональных
                        данных были направлены уполномоченным органом по защите прав субъектов персональных данных,
                        также указанный орган.<br />&nbsp;<br /><br /><strong>IX КОНТРОЛЬ, ОТВЕТСТВЕННОСТЬ ЗА НАРУШЕНИЕ
                            ИЛИ НЕИСПОЛНЕНИЕ ПОЛИТИКИ</strong><br />9.1. Контроль исполнения настоящей Политики возложен
                        на Куратора ОПД.<br />9.2. Лица, нарушающие или не исполняющие требования Политики, привлекаются
                        к дисциплинарной, административной, гражданско-правовой или уголовной ответственностив
                        соответствии с законодательством РФ.<br />9.3. Руководители структурных подразделений Оператора
                        несут персональную ответственность за исполнение обязанностей их
                        подчиненными.<br />&nbsp;<br /><br /><br /><strong>X ПРОЧИЕ ПОЛОЖЕНИЯ</strong><br />10.1.
                        Настоящая Политика и изменения к ней утверждаются единоличным исполнительным органом Общества,
                        являются обязательными для исполнения всеми сотрудниками, имеющими доступ к ПДН Субъектов, и
                        вступает в силу со дня ее утверждения.<br />10.2. Все сотрудники Общества, допущенные к работе с
                        ПДН, должны быть ознакомлены с настоящей Политикой до начала работы с ПДН.<br />10.3.
                        Опубликование или обеспечение иным образом неограниченного доступа к настоящей Политике, иным
                        документам, определяющим политику Оператора в отношении обработки ПДН, к сведениям о реализуемых
                        требованиях к защите персональных данных Оператор осуществляет, в частности, но не
                        ограничиваясь, посредством размещения на электронном сайте, принадлежащем Оператору.<br />10.4.
                        Все вопросы, связанные с обработкой ПДН, не урегулированные настоящей Политикой, разрешаются в
                        соответствии с действующими законодательством РФ в области персональных данных, а также
                        принятыми Обществом иными локальными актами в области персональных
                        данных.<br />&nbsp;<br /><strong>Приложение №1 к Политике в отношении обработки Персональных
                            данных и их защите</strong>&nbsp;<br /><em>Форма Запроса о доступе Субъекта персональных
                            данных к своим персональным данным</em><br />&nbsp;<br /><br /><br />Руководителю ООО
                        &laquo;РЕШЕНИЯ БУДУЩЕГО&raquo;<br />ИНН 5401401030,
                        630089, РОССИЯ, НОВОСИБИРСКАЯ ОБЛ, Г НОВОСИБИРСК, УЛ АДРИЕНА ЛЕЖЕНА, Д 26, КВ
                        46<br />&nbsp;<br />Запрос<br />о доступе Субъекта персональных данных к своим персональным
                        данным<br />&nbsp;<br />Я,
                        _________________________________________________&nbsp;<em>(ФИО)</em>&nbsp;_______&nbsp;<em>(основной
                            документ, удостоверяющий личность Субъекта персональных данных или его
                            представителя)</em>&nbsp;_____ __________&nbsp;<em>(номер основного документа)</em>&nbsp;
                        выдан &laquo;__&raquo;____________ _____ года&nbsp;<em>(дата
                            выдачи)</em>&nbsp;________________________ ______________________&nbsp;<em>(орган, выдавший
                            основной документ)</em>,<br />__________________________________________
                        ___________________________________________________
                        _______________________________________________&nbsp;&nbsp;<em>(сведения, подтверждающие участие
                            Субъекта персональных данных в отношениях с Оператором (номер договора, дата заключения
                            договора, условное словесное обозначение и\или иные сведения) либо сведения, иным образом
                            подтверждающие факт обработки персональных данных Оператором)</em><br />&nbsp;<br />Прошу
                        предоставить мне для ознакомления следующую информацию (документы), составляющую мои
                        персональные
                        данные:<br />1.__________________________________________;<br />2.__________________________________________;<br />3.
                        __________________________________________.<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;<br />&nbsp;&laquo;__&raquo;
                        ___________ 20__г.<br />&nbsp;<br />_________________________
                        /____________________________________/<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Подпись&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        Расшифровка подписи<br />&nbsp;<br />&nbsp;<br /><br /><br /><br /><br /><strong>Приложение №2 к
                            Политике в отношении обработки Персональных данных и их защите</strong><br /><em>Форма
                            Согласия на обработку персональных данных</em><br /><br />Руководителю ООО &laquo;РЕШЕНИЯ
                        БУДУЩЕГО&raquo;<br />ИНН 5401401030<br />630089, РОССИЯ, НОВОСИБИРСКАЯ ОБЛ, Г НОВОСИБИРСК, УЛ
                        АДРИЕНА ЛЕЖЕНА, Д 26, КВ 46<br /><br />Согласие<br />на обработку персональных
                        данных<br />&nbsp;<br />Настоящим свободно, своей волей и в своем интересе я проинформирован и
                        даю согласие о том, что в соответствии с Федеральным законом от 27.07.2006 N 152-ФЗ "О
                        персональных данных", предоставленная мною информация, включая данные о:<br />Фамилия, Имя,
                        Отчество<br />Адрес электронной почты<br />Адрес (город, улица, номер дома, номер квартиры),
                        необходим для забора\доставки отправления<br />Номер телефона<br />Сведения об используемом
                        браузере;<br />Местоположение;<br />IP-адрес;<br />Данные файлов cookie;<br />Запрашиваемые
                        Интернет-страницы;<br />Источник захода на Сайт&nbsp;<a
                            href="https://www.cdek.ru/">https://www.cdek.ru</a><br />будет внесена в архив Оператора по
                        обработке персональных данных. Я предоставляю право обрабатывать эти данные с целью организации
                        процесса предоставления запрошенной мной информации об услугах Оператора, получения обратной
                        связи от Оператора, заказа услуг, регистрации Личного кабинета, за исключением случаев, когда
                        прямо установлено иное. Мои ПДН будут использованы при запросе мной Обратного звонка,
                        предоставлении обратной связи, моей регистрации и (или) авторизации на сайте&nbsp;<a
                            href="https://www.cdek.ru/">https://www.cdek.ru</a>&nbsp;в целях, установленных
                        Разделом&nbsp; IIПолитики в отношении обработки Персональных данных и их защите, а именно, в
                        целях:<br />&nbsp;- обеспечения Пользователю возможности взаимодействовать с Сайтом, в частности
                        направление уведомлений, запросов и информации, связанных с оказанием услуг, а также обработка
                        запросов и заявок от Пользователя;<br />- оказания услуг курьерской доставки в рамках договора
                        возмедного оказания курьерских услуг (Оферта);<br />-проведение опросов и исследований,
                        направленных на выявление удовлетворенности/неудовлетворенности услугами Общества, улучшение
                        качества услуг;<br />- проведение статистических и иных исследований на основе обезличенных
                        данных.<br />- информирования меня об акциях, скидках, специальных предложениях, о новых&nbsp;
                        услугах;<br />В связи с указанными выше целями я понимаю, что мои персональные данные могут быть
                        сообщены третьим лицам, и даю на это согласие.<br />В случае предоставления мной данных третьих
                        лиц, включая контактных данных, я подтверждаю, что Субъект персональных данных уведомлен об
                        осуществлении обработки его персональных данных Оператором по обработке персональных
                        данных.<br />Настоящим я проинформирован о том, что я вправе требовать уточнения моих
                        персональных данных, их блокирования или уничтожения в случае, если персональные данные являются
                        неполными, устаревшими, неточными или не являются необходимыми для заявленной цели обработки, я
                        также в любой момент могупотребовать прекращения обработки персональных данных, направив
                        соответствующее заявление по месту нахождения Операторалибо направить электронное письмо по
                        электронному адресу:&nbsp;<a href="mailto:pretenzia@cdek.ru">pretenzia@cdek.ru</a>.<br />Я даю
                        свое согласие на использование предоставленных персональных данных для направления коммерческой
                        информации Оператором по обработке персональных данных и третьими лицами по указанному телефону
                        и адресу электронной почты.<br />Я предоставляю право отправлять мне информацию об услугах,
                        предложениях и рекламных акциях Оператора и/или его Партнеров, в том числе с помощью электронных
                        и мобильных средств связи.<br />Я осведомлен и даю согласие на то, что предоставленные
                        персональные данные могут быть&nbsp; включены Оператором в&nbsp; общедоступные источники
                        персональных данных,&nbsp; создаваемые Обществом в целях собственного информационного
                        обеспечения (п.6.11. Политики).<br />Настоящим я проинформирован о том, что я в любой момент
                        могу отказаться от получения коммерческой информации, направив свое заявление по месту
                        нахождения Оператора либо направить электронное письмо по электронному адресу:&nbsp;<a
                            href="mailto:pretenzia@cdek.ru">pretenzia@cdek.ru</a></div>
                </div>
            </div>
        </div>

    </main>
</div>
