<div class="container">
    <app-header [title]="'Восстановление пароля'"></app-header>
    <div class="recovery" *ngIf="!sms && !isCode">
        <span class="h3 mb-4 align-self-start">Шаг 1 из 3</span>
        <label class="recovery__form__label align-self-start mb-2" for="phone_for_recovery">Введите номер телефона, указанный в ЛК <sup class="text-danger">*</sup></label>
        <div class="form-group d-flex w-100">
            <input class="form-control mr-3" id="phone_for_recovery" [ngModel]="phoneForRecovery" maxlength="16" (ngModelChange)="changeNumberPhone($event)"
                (keyup.enter)="sendSms($event)" placeholder="+7 (555) 555-5555">
            <button id="send-sms-button" class="login-form__button btn btn-success" (click)="sendSms($event)"
                disabled>
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                </svg>
            </button>
        </div>
    </div>
    <div class="recovery" *ngIf="sms && !isCode">
        <span class="h3 mb-4 align-self-start">Шаг 2 из 3</span>
        <label class="recovery__form__label align-self-start mb-2" for="code_for_recovery">Введите последние 4 цифры номера или код из смс <sup class="text-danger">*</sup></label>
        <div class="form-group d-flex w-100">
            <input class="form-control mr-3" id="code_for_recovery" [(ngModel)]="codeForRecovery" maxlength="4" (ngModelChange)="changeCode()" (keyup.enter)="sendCode($event)" placeholder="****">
            <button id="send-code-button" class="login-form__button btn btn-success" (click)="sendCode($event)"
                disabled>
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                </svg>
            </button>
        </div>
    </div>
    <div class="recovery" *ngIf="isCode">
        <span class="h3 mb-4 align-self-start">Шаг 3 из 3</span>
        <label class="recovery__form__label align-self-start mb-2" for="password_for_recovery">Создайте новый пароль для личного кабинета <sup class="text-danger">*</sup></label>
        <div class="form-group d-flex w-100">
            <input class="form-control mr-3" id="new_password_for_recovery" [(ngModel)]="newPasswordForPersonalArea" maxlength="256"
                (keyup.enter)="sendNewPasswordForPersonalArea()" placeholder="******">
            <button id="send-new-password-button" class="login-form__button btn btn-success"
                (click)="sendNewPasswordForPersonalArea()"
                [disabled]="!newPasswordForPersonalArea">
                <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd"
                        d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                    <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                </svg>
            </button>
        </div>
    </div>
</div>
