<app-header [title]="'Пользовательское соглашение'" (toggleSidebar)="sidebarOpen($event)"></app-header>

<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>

    <main class="content">
            <div class="container customer-reviews cdek-container">
                    <h1 class="customer-reviews__title">Пользовательское соглашение</h1>
                    <div class="customer-reviews__wrap-content">
                    </div>
                <div class="cdek-container text-block" data-v-429b4b6d="">
                    <div class="layout" data-v-429b4b6d="">
                        <p>Настоящее соглашение, являясь пользовательским соглашением (далее - Соглашение), заключенным
                            между физическим лицом, действующим в своих интересах или в интересах других лиц (далее -
                            Пользователь) и ООО &laquo;РЕШЕНИЯ БУДУЩЕГО&raquo; (далее &mdash; Владелец сайта),
                            являющимся правообладателем исключительного права на Интернет-сайт: www.cdekpost.ru (далее
                            по тексту &ndash; &laquo;Сайт&raquo;), в дальнейшем совместно именуемых
                            &laquo;Стороны&raquo;, определяет права и обязанности Пользователя и Владельца сайта при
                            использовании Сайта.&nbsp;<br /><br />Пользователь выражает согласие с тем, что используя
                            Сайт любым способом и/или регистрируясь на Сайте, он подтверждает, что ознакомлен с
                            условиями настоящего Соглашения, согласен и обязуется их соблюдать. В случае отсутствия у
                            Пользователя безоговорочного согласия со всеми условиями настоящего Соглашения, Пользователь
                            обязуется незамедлительно прекратить использование Сайта и всех его
                            сервисов.<br />&nbsp;<br /><br />1. ТЕРМИНЫ И ОПРЕДЕЛЕНИЯ<br />Владелец сайта - ООО
                            &laquo;РЕШЕНИЯ БУДУЩЕГО&raquo;, (ИНН 5401401030, 630089, РОССИЯ, НОВОСИБИРСКАЯ ОБЛ, Г
                            НОВОСИБИРСК, УЛ АДРИЕНА ЛЕЖЕНА, Д 26, КВ 46).<br /><br />Сайт &mdash; совокупность
                            информации, текстов, графических элементов, дизайна, изображений, фото и видеоматериалов,
                            иных результатов интеллектуальной деятельности, а также программных средств для ЭВМ,
                            обеспечивающих публикацию для всеобщего обозрения информации и данных, объединенных общим
                            целевым назначением, посредством технических средств, применяемых для связи между ЭВМ и сети
                            Интернет.&nbsp; Сайт находится в сети Интернет по адресу: www.cdekpost.ru,&nbsp; на котором
                            представлена информация о курьерских и иных услугах, порядка их заказа, исполнения,&nbsp; а
                            также способах оплаты услуг.<br /><br />Содержание сайта/Контент - охраняемые результаты
                            интеллектуальной деятельности, включая тексты литературных произведений, их названия,
                            предисловия, аннотации, статьи, иллюстрации, обложки, графические, текстовые,
                            фотографические, производные, составные и иные произведения, пользовательские интерфейсы,
                            визуальные интерфейсы, названия товарных знаков, логотипы, программы для ЭВМ, базы данных, а
                            также дизайн, структура, выбор, координация, внешний вид, общий стиль и расположение
                            содержания, входящего в состав Сайта и другие объекты интеллектуальной собственности все
                            вместе и/или по отдельности, содержащиеся на Сайте.<br /><br />Пользователь &mdash;
                            физическое лицо, действующее в своих интересах или в интересах других лиц, акцептовавшее
                            настоящее Соглашение, имеющее доступ к Сайту и использующее его, независимо от факта
                            регистрации на Сайте.<br /><br />Услуги &ndash; курьерские услуги и иные услуги, связанные с
                            оказанием курьерских услуг.<br /><br />Учетная запись &ndash; информация о предоставленных
                            Пользователем данных, фиксирование которых Владелец сайта сочтет необходимым в целях
                            использования Сайта Пользователем.<br /><br />Регистрация &ndash; заполнение необходимых
                            данных, в том числе персональных, запрашиваемых Сайтом в целях идентификации
                            Пользователя.<br /><br />Личный кабинет &ndash; сервис, позволяющий Пользователю
                            осуществлять заказ услуг, отследить заказы, самостоятельно оформить и распечатать
                            заполненные для отправки накладные; произвести расчет стоимости доставки с учетом
                            предоставленных скидок по одному или нескольким направлениям; получить информацию о
                            состоянии счета; получить информацию о контактах закрепленных за Пользователем сотрудников,
                            получить акты оказанных услуг за весь период, сформировать акт сверки, получить информацию
                            по реестрам перечисления наложенных платежей.<br /><br /><br /><br />2. ОБЩИЕ
                            ПОЛОЖЕНИЯ<br /><br />2.1. Пользователь до начала использования Сайта и регистрации на Сайте,
                            а также в последующем обязан знакомиться с текстом:<br /><br />2.1.1. Настоящего
                            Соглашения;&nbsp;<br /><br />2.1.2. Договора возмездного оказания курьерских услуг,
                            Регламента возмездного оказания услуг, являющихся публичной офертой (далее -
                            Оферта);<br /><br />2.1.3. Политики в отношении обработки персональных данных и их защите
                            (далее - Политика);<br /><br />2.1.4. Иными документами, размещенными на
                            Сайте.<br /><br />2.2.&nbsp; Используя Сайт любым способом и/или регистрируясь на Сайте,
                            Пользователь выражает свое полное и безоговорочное согласие со всеми условиями указанных в
                            пп.2.1.1., 2.1.2., 2.1.4. Соглашения документов, и лишается возможности ссылаться на
                            неосведомленность по каким-либо вопросам, урегулированным указанными
                            документами.<br /><br />2.3. Пользователь несет персональную ответственность за ознакомление
                            с указанными в п.2.1. документами и их изменениями.<br /><br />2.4. Настоящее Соглашение
                            распространяет свое действие на все дополнительные положения и условия об оказании услуг,
                            предоставляемых на Сайте.<br /><br /><br /><br />3. ПРЕДМЕТ СОГЛАШЕНИЯ<br /><br />3.1.
                            Предметом настоящего Соглашения является предоставление Пользователю доступа к программному
                            обеспечению Сайта для:<br /><br />3.1.1. Информирования Пользователя о реализуемых
                            посредством Сайта услугах, в том числе путем e-mail- и sms-рассылки;<br /><br />3.1.2.
                            Обеспечения возможности приобретения Пользователем услуг;<br /><br />3.1.3. Использования
                            сервисов Сайта.<br /><br />3.2. Сайт предоставляет Пользователю следующие виды услуг
                            (сервисов):<br /><br />3.2.1. Доступ к электронному контенту на бесплатной основе, с правом
                            просмотра контента;<br /><br />3.2.2. Доступ к средствам поиска и навигации
                            Сайта;<br /><br />3.2.3. Доступ к информации об оказываемых услугах;<br /><br />3.2.4.
                            Доступ к информации о способах и условиях осуществления заказа, оплаты, отслеживания и
                            доставки отправления;<br /><br />3.2.5. Заказ курьерских услуг и иных дополнительных
                            услуг;<br /><br />3.2.6. Получение информации о статусе заказа;<br /><br />3.2.7. Получение
                            новостей об услугах, предложениях, рекламных акциях и скидках;<br /><br />3.2.8. Создание
                            учетной записи в Личном кабинете для осуществления, отслеживания заказов, формирования
                            документов, возникающих при оказании курьерских услуг;<br /><br />3.2.9. Иные виды услуг
                            (сервисов), реализуемые на страницах Сайта.<br /><br />3.3. Под действие настоящего
                            Соглашения подпадают все существующие (реально функционирующие) на данный момент услуги
                            (сервисы) Сайта, а также любые их последующие модификации и появляющиеся в дальнейшем
                            дополнительные услуги (сервисы) Сайта.<br /><br />3.4. Доступ к Сайту предоставляется на
                            бесплатной основе. Отношения по приобретению услуг, носящие возмездный характер,
                            урегулированы Офертой.<br /><br /><br /><br />4. ОБЩИЕ УСЛОВИЯ ИСПОЛЬЗОВАНИЯ
                            САЙТА<br /><br />4.1. Сайт предоставляет Пользователю возможность получать информацию о
                            курьерских и иных услугах, заказа данных услуг, оплаты, отслеживания исполнения данных
                            услуг, получения обратной связи по оказанным и(или) оказываемым услугам.<br /><br />4.2.
                            Заказ услуг возможен посредством заполнения информации на Сайте в разделе &laquo;Новое
                            отправление&raquo; (без регистрации) и при создании учетной записи (регистрации) в Личном
                            кабинете и иными способами, имеющимися на Сайте.&nbsp;<br /><br />4.3.&nbsp; Порядок
                            создания учетной записи в Личном кабинете и процедура осуществления заказа услуг через
                            использование Личного кабинета отражены в Инструкции по использованию личного кабинета,
                            размещенной на Сайте в разделе &laquo;Онлайн сервисы&raquo;.<br /><br />4.4. Информацию о
                            статусе отправления можно посмотреть на Сайте в разделах &laquo;Исходящие&raquo; ,
                            &laquo;Доставленные&raquo;, а также на главной странице путем заполнения окна
                            &laquo;Отследить отправление о номеру трекера (квитанции, накладной)&raquo;.<br />4.5.
                            Пользователь приобретает права и становится обязанным по Оферте при оформлении заказа
                            (акцепта).<br /><br />4.6. Владелец сайта использует автоматическую систему сбора данных
                            &laquo;cookie&raquo;. Cookie представляют собой набор сведений, передаваемых сервером на
                            браузер и на устройство Пользователя, таких как IP-адреса пользователя и другие сведения,
                            относящиеся к трафику данных пользователя или к предпочтениям пользователя при навигации на
                            Сайте. Разрешение на использование cookie необходимо для использования Сайта и его сервисов,
                            включая заказ услуг. Данные обрабатываются в совокупном и обезличенном виде и не содержат
                            информации о персональных данных, но при этом позволяют связать пользователя с его
                            персональной информацией, сообщенной Пользователем на Сайте.<br /><br />Указанные данные
                            собираются Сайтом в процессе навигации посредством Cookie напрямую и автоматически в рамках
                            осуществления операционных функций и не используются для идентификации субъекта персональных
                            данных.<br />В большинстве браузеров есть возможность удаления cookie после каждого сеанса.
                            Инструкции по выполнению такой операции удаления содержатся в разделе
                            &laquo;Настройки&raquo;&nbsp; браузера Пользователя или в справочной информации, и
                            Пользователь может к ней обратиться при желании удалить Cookie.<br /><br />Если процедура по
                            удалению&nbsp; Cookie была запущена полностью или частично, Владелец сайта не может
                            гарантировать, что веб-страницы Сайта и/или оказание определенных услуг на сайте будут
                            функционировать должным образом.<br /><br />4.7. На Сайте могут быть представлены ссылки на
                            сайты третьих лиц. Они предоставляются исключительно для удобства Пользователя. Если
                            Пользователь переходит по этим ссылкам, то покидает Сайт. Владелец сайта не просматривает
                            сайты третьих лиц, не контролирует и не несет ответственности за любой из этих сайтов или их
                            содержание. Владелец Сайта не дает никаких рекомендаций и не делает каких-либо заявлений в
                            отношении подобных сайтов, любой информации или материалов, которые там представлены, или
                            результатов, которые могут быть получены при их использовании. Если Пользователь переходит
                            на сторонние сайты, ссылки на которые размещены на Сайте, Пользователь делает это осознавая
                            свою ответственность.<br /><br /><br />5. СРОКИ И СТОИМОСТЬ ОКАЗАНИЯ УСЛУГ</p>
                        <p>5.1. Услуги по пользованию сервисом (Сайтом) оказываются Владельцем Сайта без ограничения
                            срока их оказания.</p>
                        <p>5.2. Услуги по пользованию сервисом (Сайтом) оказываются Владельцем без взимания платы.</p>
                        <p>5.3. Сроки оказания курьерских услуг размещаются на сайте курьерской организации (cdek.ru).
                        </p>
                        <p>5.4. Стоимость оказания курьерских услуг зависит от количества и размера почтовых вложений, и
                            формируется в момент заполнения данных об отправлении и загрузки документов. Минимальная
                            стоимость курьерских услуг&nbsp; за 1 отправление составляет 100 рублей, максимальная
                            стоимость одного отправления не может превышать 5&nbsp;000 рублей.</p>
                        <p>5.5. В случае неоказания/некачественного оказания услуг, возврат денежных средств
                            осуществляется в соответствии нормами ГК РФ, ФЗ "О защите прав потребителей"</p>

                        <p>&nbsp;</p>
                        <ol start="6">
                            <li><br />ПРАВА И ОБЯЗАННОСТИ СТОРОН<br /><br />6.1. Владелец сайта
                                вправе:<br /><br />6.1.1. Предоставить Пользователю доступ к Сайту и поддерживать Сайт и
                                сервисы в рабочем состоянии, изменять правила пользования Сайтом, а также изменять
                                содержание данного Сайта.&nbsp;<br /><br />6.1.2. Принимать меры по привлечению
                                Пользователя к ответственности, предусмотренной настоящим Соглашением и находящейся в
                                компетенции Владельца сайта.<br /><br />6.1.3. В случае размещения Пользователем на
                                Сайте информации или совершения иных действий, несоответствующих условиям Соглашения без
                                уведомления, по собственному усмотрению удалить полностью или частично размещенную
                                Пользователем информацию, включая ту информацию, в отношении которой затруднительно
                                определить ее соответствие Соглашению и/или применимому законодательству
                                РФ;<br /><br />6.1.4. За нарушение условий настоящего Соглашения Владелец сайта имеет
                                право без предварительного уведомления блокировать доступ Пользователя к Сайту и/или
                                удалить учетную запись Пользователя.<br /><br />6.1.5. Использовать (обрабатывать и
                                т.п.) персональные данные, указанные Пользователем при использовании Сайта и при
                                регистрации, а также направлять на предоставленный Пользователем номер телефона,
                                электронный адрес и размещать в пространстве, ограниченном доступом Пользователя,
                                рекламные и информационные сообщения по своему усмотрению, рекламные материалы партнеров
                                Владельца сайта.<br /><br />6.1.6. Переуступать либо каким-либо иным способом передавать
                                свои права и обязанности, вытекающие из его отношений с Пользователем, третьим лицам без
                                согласия Пользователя.<br /><br />6.1.7. В любое время без уведомления Пользователя
                                вносить изменения в перечень услуг, реализуемых посредством Сайта, тарифы, документы
                                Сайта и иное содержание Сайта. Изменения вступают в силу с момента их опубликования на
                                Сайте.<br /><br />6.1.8. Без предварительного уведомления Пользователя проводить
                                профилактические или иные работы, обеспечивающие работоспособность
                                Сайта.<br /><br />6.2. Пользователь вправе:<br /><br />6.2.1. Получить доступ к
                                использованию Сайта после соблюдения требований предусмотренных настоящим Соглашением и
                                документами, указанными в п.2.1.2, 2.1.4 настоящего Соглашения.<br /><br />6.2.2.
                                Пользоваться всеми имеющимися на Сайте сервисами, а также заказывать любую услугу,
                                предлагаемую на Сайте на основании Оферты.<br /><br />6.2.3. Задавать вопросы,
                                относящиеся к курьерским и иным услугам, предлагаемым Сайтом.<br /><br />6.2.4.
                                Пользоваться Сайтом исключительно в целях и порядке, предусмотренных Соглашением и
                                незапрещенных законодательством Российской Федерации.<br /><br />6.3. Пользователь Сайта
                                обязуется:<br /><br />6.3.1. Предоставлять по запросу Владельца сайта точную,
                                достоверную информацию, которая имеет непосредственное отношение к предоставляемым
                                услугам данного Сайта.<br /><br />6.3.2. Обеспечивать сохранность логина и пароля от
                                доступа третьих лиц и своевременную их замену в случае утери или иных
                                несанкционированных действий третьих лиц, а также несет ответственность за любые
                                действия третьих лиц, воспользовавшихся указанными логином и паролем Пользователя. Если
                                Пользователем не доказано обратно, все действия, совершенные на Сайте с использованием
                                принадлежащих Пользователю логина и пароля, считаются совершенными Пользователем. Без
                                ущерба для остальных положений настоящего Соглашения Владелец сайта не несет прямой или
                                косвенной ответственности любого рода за любые убытки, понесенные в результате или в
                                связи с несоблюдением Пользователем настоящей обязанности.<br /><br />6.3.3. Соблюдать
                                имущественные и неимущественные права авторов и иных правообладателей при использовании
                                Сайта.<br /><br />6.3.4. Не предпринимать действий, которые могут рассматриваться как
                                нарушающие нормальную работу Сайта.<br /><br />6.3.5. Не распространять с использованием
                                Сайта любую конфиденциальную и охраняемую законодательством Российской Федерации
                                информацию о физических либо юридических лицах.<br /><br />6.3.6. Избегать любых
                                действий, в результате которых может быть нарушена конфиденциальность охраняемой
                                законодательством Российской Федерации информации.<br /><br />6.3.7. Не использовать
                                Сайт для распространения информации рекламного характера, иначе как с согласия Владельца
                                сайта.<br /><br />6.3.8. Не использовать сервисы Сайта с целью:<br /><br />6.3.8.1.
                                Загрузки контента, который является незаконным, нарушает любые права третьих лиц;
                                пропагандирует насилие, жестокость, ненависть и (или) дискриминацию по расовому,
                                национальному, половому, религиозному, социальному признакам; содержит недостоверные
                                сведения и (или) оскорбления в адрес конкретных лиц, организаций, органов власти;
                                побуждает к совершению противоправных действий.<br /><br />6.3.8.2. Представления себя
                                за другое лицо или представителя организации и (или) сообщества без достаточных на то
                                прав, в том числе за сотрудников данного Сайта.<br /><br />6.3.8.3. Введения в
                                заблуждение относительно предоставляемых Сайтом услуг и порядка работы Сайта и его
                                сервисов;<br /><br />6.3.8.4. Некорректного сравнения услуг, предоставляемых Сервисом, а
                                также формирования негативного отношения к лицам, (не) пользующимся услугами Сайта,
                                осуждения таких лиц.<br /><br />6.3.9. Незамедлительно уведомить Владельца сайта о
                                несанкционированном использовании его учётной записи или пароля или любом другом
                                нарушении системы безопасности.&nbsp;<br /><br />6.3.10. По требованию Владельца сайта
                                изменять свои пароли, в противном случае Владелец сайта имеет право изменять пароли
                                Пользователя в принудительном порядке.<br /><br />6.3.11. Не пытаться получить доступ к
                                учетной записи и/или логину и паролю другого Пользователя любым способом, включая, но не
                                ограничиваясь, путем обмана, злоупотребления доверием, подбора логина и
                                пароля.<br /><br /><br /><br />7. ДОПУСТИМОЕ ИСПОЛЬЗОВАНИЕ САЙТА И ГАРАНТИИ
                                ПОЛЬЗОВАТЕЛЯ<br /><br />7.1. Для использования Сайта необходимо подключение к Интернету.
                                Владелец сайта не несет ответственность за возможные неблагоприятные последствия для
                                Пользователя вызванные, невозможностью подключения к Интернету.<br /><br />7.2.
                                Содержание Сайта не может быть скопировано, опубликовано, воспроизведено, передано или
                                распространено любым способом, а также размещено в глобальной сети Интернет без
                                предварительного письменного согласия Владельца сайта.<br /><br />7.3. Содержание Сайта
                                защищено авторским правом, законодательством о товарных знаках, а также другими нормами
                                законодательства Российской Федерации, относящимся к защите интеллектуальной
                                собственности, и защите от недобросовестной конкуренции<br /><br />7.4. Настоящее
                                Соглашение не предоставляет Пользователю никаких прав на любые результаты
                                интеллектуальной деятельности Владельца сайта или третьих лиц, если передача
                                исключительных прав прямо не указана, и все исключительные права и прибыль от
                                использования остаются у Владельца сайта и/или соответствующего третьего
                                лица.<br /><br />7.5. Информация, размещаемая на Сайте, не должна истолковываться как
                                изменение настоящего Соглашения.<br /><br />7.6. Пользователь
                                гарантирует:<br /><br />7.6.1. Не размещать и не передавать посредством Сайта любую
                                информацию ограниченного доступа (конфиденциальную информацию), если Пользователь не
                                уполномочен на совершение данных действий;<br /><br />7.6.2. Не размещать посредством
                                Сайта персональные данные, в том числе домашние адреса, номера телефонов, адреса
                                электронной почты, паспортные данные и прочую личную информацию (персональные данные)
                                других Пользователей или иных лиц без их предварительного согласия;<br /><br />7.6.3. Не
                                использовать любые устройства, программы, процедуры, алгоритмы и методы, автоматические
                                устройства или эквивалентные ручные процессы для доступа, приобретения, копирования или
                                отслеживания содержания Сайта;<br /><br />7.6.4. Не нарушать надлежащее функционирование
                                Сайта;<br /><br />7.6.5. Не обходить любым способом навигационную структуру Сайта для
                                получения или попытки получения любой информации, документов или материалов любыми
                                средствами, которые специально не представлены сервисами данного
                                Сайта;<br /><br />7.6.6. Не осуществлять несанкционированный доступ к функциям Сайта,
                                любым другим системам или сетям, относящимся к данному Сайту, а также к любым услугам,
                                предлагаемым на Сайте;<br /><br />7.6.7. Не нарушать систему безопасности или
                                аутентификации на Сайте или в любой сети, относящейся к Сайту.<br /><br />7.6.8. Не
                                выполнять обратный поиск, не отслеживать или не пытаться отслеживать любую информацию о
                                любом другом Пользователе Сайта.<br /><br />7.6.9. Не создавать несколько учётных
                                записей на Сайте, если фактически они принадлежат одному и тому же
                                лицу.<br /><br />7.6.10. Не передавать в пользование свою учетную запись и/или логин и
                                пароль своей учетной записи третьим лицам;<br /><br />7.6.11. Не использовать программы
                                для автоматизированного сбора информации и/или взаимодействия с Сайтом и его
                                сервисами;<br /><br />7.6.12. Не размещать любые файлы, которые содержат или могут
                                содержать вирусы и другие вредоносные программы;<br /><br />7.6.13. Не накапливать и не
                                собирать адреса электронной почты или другую контактную информацию и личные данные
                                Пользователей Сайта автоматизированными или иными способами для любых целей, в том числе
                                целей рассылки незапрошенной почты (спама) или другой нежелательной
                                информации;<br /><br />7.6.14. Не использовать Сайт и его Содержание в любых целях,
                                запрещенных законодательством Российской Федерации, а также подстрекать к любой
                                незаконной деятельности или другой деятельности, нарушающей права Владельца Сайта или
                                других лиц.<br /><br />7.6.15. Не предпринимать каких-либо действий, направленных на
                                причинение ущерба обладателю прав на Сайт, правообладателям и иным
                                лицам.<br /><br />7.6.16. Не регистрироваться, используя данные, на которые у
                                Пользователя нет права использования.<br /><br />7.6.17. Не использовать Сайт в целях
                                извлечения прибыли, для размещения рекламы, реализации мошеннических схем и других
                                противоправных действий.<br /><br />7.6.18. Что все данные, в том числе и персональные
                                данные, предоставляемые им в адрес Владельца Сайта, а также размещаемые им на Сайте,
                                получены им на законных основаниях и с соблюдением требований законодательства
                                Российской Федерации о персональных данных;<br /><br />7.7. Владелец сайта полагается на
                                добросовестность Пользователя и данных им гарантий. В случае нарушения Пользователем
                                предоставленных в п. 6.6. Соглашения гарантий, Пользователь обязуется возместить
                                Владельцу сайта любые понесенные им убытки.<br /><br /><br /><br />8.
                                ОТВЕТСТВЕННОСТЬ<br /><br />8.1. Присоединяясь к настоящему Соглашению, Пользователь
                                понимает, принимает и соглашается с тем, что он:<br /><br />8.1.1. Несет полную
                                ответственность за сохранность своей учетной записи (логина и пароля), а также за все
                                действия, совершенные под его учетной записью;<br /><br />8.1.2. Использует Сайт на свой
                                собственный риск;<br /><br />8.1.3. Обязуется самостоятельно и за свой счет
                                урегулировать все претензии третьих лиц, связанные с действием или бездействием
                                Пользователя при использовании Сайта.<br /><br />8.2. Любые убытки, которые Пользователь
                                может понести в случае умышленного или неосторожного нарушения любого положения
                                настоящего Соглашения, а также вследствие несанкционированного доступа к коммуникациям
                                другого Пользователя, либо вследствие действий других Пользователей или третьих лиц,
                                Владельцем сайта не возмещаются.<br /><br />8.3. Владелец сайта не несет ответственности
                                за:<br /><br />8.3.1. Любые технические сбои или иные проблемы любых телефонных сетей
                                или служб, компьютерных систем, серверов или провайдеров, компьютерного или телефонного
                                оборудования, программного обеспечения, сбои сервисов электронной почты или скриптов по
                                техническим причинам.<br /><br />8.3.2. Действия платежных систем и за задержки,
                                связанные с их работой,<br /><br />8.3.3. Содержание не принадлежащих Владельцу сайта
                                сайтов, ссылки на которые могут присутствовать на Сайте, и не гарантирует их
                                доступность, корректность работы и соответствия заявленной тематике.<br /><br />8.3.4.
                                Ненадлежащее функционирование Сайта, в случае, если Пользователь не имеет необходимых
                                технических средств для его использования, а также не несет никаких обязательств по
                                обеспечению пользователей такими средствами.<br /><br />8.3.5. Невозможности оформления
                                Заказа Пользователем на Сайте по любым причинам.<br /><br />8.3.6.&nbsp; Любой ущерб
                                компьютеру пользователя или иного лица, мобильным устройствам, любому другому
                                оборудованию или программному обеспечению, вызванный или связанный со скачиванием
                                материалов с Сайта или по ссылкам, размещенным на Сайте.<br /><br />8.3.7. Наличие на
                                Сайте вирусов, а также за возможные последствия заражения мобильного устройства,
                                компьютера Пользователя вирусами или попадание на мобильное устройство, компьютер
                                Пользователя иных вредоносных программ. Владелец сайта предоставляет Сайт и программное
                                обеспечение Сайта Пользователю &laquo;как есть&raquo; без каких-либо дополнительных
                                гарантий.<br /><br />8.3.8. Любые убытки, включая упущенную выгоду, моральный и иной
                                вред, причиненные Пользователю или третьим лицам в результате использования ими Сайта,
                                содержимого Сайта или иных материалов, к которым был получен доступ с помощью Сайта или
                                в связи с функционированием Сайта, а также во всех иных случаях, предусмотренных
                                настоящим Соглашением.<br /><br />8.3.9. Убытки любого рода, понесенные Пользователем в
                                результате или в связи несоблюдением Пользователем требований Соглашения к сохранности
                                реквизитов доступа &mdash; логина и пароля, а также за соответствие персональных данных
                                действительности и законность их передачи от Пользователя Владельцу сайта.</li>
                        </ol>
                        <p><br />8.4. В случае нарушения правил использования Сайта, Пользователь обязуется возместить
                            Владельцу Сайта вред, причиненный такими действиями.<br /><br />8.5. Признавая международный
                            характер сети Интернет, Пользователь принимает на себя ответственность за соблюдение всех
                            соответствующих правил и законов, касающихся действий Пользователя в сети
                            Интернет.<br /><br />8.6. При любых обстоятельствах ответственность Владельца Сайта в
                            соответствии со ст. 15 Гражданского кодекса России ограничена 1 000 (одной тысячей) рублей
                            РФ и возлагается на него при наличии в его действиях вины.<br /><br /><br /><br />9.
                            НАРУШЕНИЕ УСЛОВИЙ ПОЛЬЗОВАТЕЛЬСКОГО СОГЛАШЕНИЯ<br /><br />9.1. Владелец сайта имеет право
                            раскрыть информацию о Пользователе, если действующее законодательство Российской Федерации
                            требует или разрешает такое раскрытие.<br /><br />9.2. Владелец сайта вправе без
                            предварительного уведомления Пользователя прекратить и (или) заблокировать доступ к Сайту,
                            если Пользователь нарушил настоящее Соглашение или содержащиеся в иных документах условия
                            пользования Сайтом, при наличии обстоятельств, свидетельствующих о том, что она используется
                            в мошеннических, преступных, террористических целях, нарушает права и законные интересы
                            третьих лиц, любым иным способом способствует или может способствовать нарушению
                            законодательства Российской Федерации, а также в случае прекращения действия Сайта либо по
                            причине технической неполадки или проблемы.<br /><br />9.3. Владелец сайта не несет
                            ответственности перед Пользователем или третьими лицами за прекращение доступа к Сайту в
                            случае нарушения Пользователем любого положения настоящего Соглашения или иного документа,
                            содержащего условия пользования Сайтом.<br /><br /><br /><br /><br />10. СОГЛАСИЕ
                            ПОЛЬЗОВАТЕЛЯ НА ПОЛУЧЕНИЕ РЕКЛАМНЫХ СООБЩЕНИЙ И ОБРАБОТКУ ПЕРСОНАЛЬНЫХ
                            ДАННЫХ<br /><br />10.1. Принимая настоящее Соглашение, Пользователь&nbsp; предоставляет свое
                            согласие на использование его персональных данных (Имя, Адрес электронной почты, Адрес
                            (город, улица, номер дома, номер квартиры), Номер телефона, Сведения об используемом
                            браузере; Местоположение;IP-адрес; Запрашиваемые Интернет-страницы; Источник захода на Сайт
                            https://www.cdek.ru) для целей исполнения настоящего Соглашения, а также для целей получения
                            рекламных, маркетинговых материалов путем e-mail-, sms-рассылки, с правом передачи данных
                            Пользователя третьим лицам.<br /><br />10.2. Предоставление Пользователем своих персональных
                            данных Владельцу сайта рассматривается им как данное субъектом персональных данных
                            конкретное, информированное и сознательное согласие на обработку персональных данных
                            Пользователя Владельцем сайта, его подрядчиками и и иными третьими лицам, осуществляющими
                            обработку персональных данных по поручению Владельца сайта. Указанное согласие дается
                            Пользователем свободно, своей волей и в своем интересе.<br /><br />10.3. В случае, когда
                            информацию о Пользователе на Сайте указывает не сам Пользователь, а иное лицо,
                            соответствующее лицо обязано иметь достаточные законные основания и/или поручение
                            Пользователя для передачи такой информации Владельцу Сайта, а также согласие Пользователя на
                            обработку его персональных данных. Указанное лицо самостоятельно несет всю полноту
                            ответственности перед Пользователем за незаконное использование информации о
                            Пользователя.<br /><br />10.4. Владелец сайта при хранении и обработке персональных данных
                            руководствуется требованиями российского законодательства, Политикой и гарантирует
                            необходимые меры защиты персональных данных от несанкционированного
                            доступа.<br /><br />10.5. Персональные данные могут предоставляться Пользователем путем
                            заполнения соответствующих данных в размещаемых на Сайте формах.<br /><br />10.6.
                            Ответственность за правомерность и достоверность персональных данных Пользователя несет
                            исключительно предоставившее их лицо. Владелец сайта не принимает на себя никаких
                            обязательств по проверке персональных данных, указанных Пользователем.<br /><br />10.7.
                            Пользователь в любой момент вправе расторгнуть настоящее Соглашение в одностороннем порядке
                            и отозвать свое согласие на обработку персональных данных, подав заявление в порядке,
                            предусмотренном Политикой.<br /><br /><br /><br />11. РАЗРЕШЕНИЕ СПОРОВ<br /><br />11.1. В
                            случае возникновения любых разногласий или споров между Сторонами настоящего Соглашения
                            обязательным условием до обращения в суд является предъявление претензии.&nbsp; По спорам,
                            связанным с настоящим Соглашением претензии направляются по адресу Владельца
                            сайта.<br /><br />11.2. При невозможности разрешить спор в добровольном порядке любая из
                            Сторон вправе обратиться в суд за защитой своих прав, которые предоставлены им действующим
                            законодательством Российской Федерации.&nbsp;<br /><br /><br /><br />12. ДОПОЛНИТЕЛЬНЫЕ
                            УСЛОВИЯ<br /><br />12.1. Владелец сайта не принимает встречные предложения от Пользователя
                            относительно изменений настоящего Пользовательского соглашения.<br /><br />12.2.
                            Пользователь добровольно предоставляет информацию, которая автоматически передается
                            Владельцу Сайта в процессе использования Сайта с помощью установленного на устройстве
                            Пользователя программного обеспечения, в том числе IP-адрес, информация cookie, информация о
                            браузере Пользователя, геолокационные данные устройств Пользователя, данные о действиях
                            Пользователя на Сайте, а также иные данные о Пользователе.<br /><br />12.3. По вопросам,
                            связанным с работой Сайта, использованием сервисов Сайта, отслеживанием Заказов и т.д.
                            Пользователь может получить всю необходимую информацию на страницах Сайта в соответствующих
                            разделах, а также путем использования сервиса &laquo;Обратной связь&raquo;, &laquo;Заказать
                            звонок&raquo;, &laquo;Звонок Online&raquo;.&nbsp;<br /><br />12.4. Информация считается
                            направленной от имени Владельца сайта Пользователю, если она направлена на электронный
                            адрес, указанный им при предоставлении своих персональных данных.<br /><br />12.5. Владелец
                            сайта оставляет за собой право вносить изменения в действующее Соглашение, в связи с чем
                            Пользователь обязуется регулярно отслеживать любые изменения в Соглашении, размещенном на
                            Сайте. Новая редакция Соглашения вступает в силу с момента ее размещения на данной странице,
                            если иное не предусмотрено новой редакцией Соглашения.<br /><br />12.6. В случае признания
                            недействительными части положений настоящего Соглашения, остальные части Соглашения будут
                            оставаться в силе. Отказ любой из сторон от какого-либо положения или условия настоящего
                            Соглашения, или любое его нарушение не отменяет действие положений Соглашения.</p>
                    </div>
                </div>
            </div>

    </main>
</div>
