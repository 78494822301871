<nav class="navbar__header">
    <a (click)="showSidebar()" class="sidebar__menu__burger" *ngIf="isBurger">
        <div class="sidebar__menu__burger__toggle" [ngClass]="{close__icon: isShowSidebar}"></div>
    </a>
    <img src="../../../assets/img/logo.svg" class="navbar__header__logo" routerLink="/**">
    <span class="navbar__header__title ml-2">{{ title }}</span>
</nav>

<!-- <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
        <div class="navbar-brand d-flex align-items-center" routerLink="/history">
            <img src="../../../assets/img/logo.svg" style="width: 110px; height: 50px;">
            <span class="nav__logo ml-2">{{ title }}</span>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto mb-2 mb-lg-0">
                <li class="nav-item">
                    <a class="btn btn-link" routerLink="/create-departure">Новое отправление</a>
                </li>
                <li class="nav-item btn-link">
                    <a class="btn btn-link" routerLink="/history">Исходящие</a>
                </li>
                <li class="nav-item btn-link">
                    <a class="btn btn-link" routerLink="/archive">Доставленные</a>
                </li>
                <li class="nav-item btn-link">
                    <a class="btn btn-link" routerLink="/profile">Личный кабинет</a>
                </li>
            </ul>
        </div>
    </div>
</nav> -->
