<div class="container">
    <app-header [title]="'Регистрация'"></app-header>
    <div class="login">
        <div class="login__form w-100" *ngIf="!sms && !isCode">
            <span class="h3 mb-4 d-flex align-self-start">Шаг 1 из 3</span>
            <label class="login__form_label" for="phone">
                <b>Чтобы мы не потеряли вашу почту, введите номер телефона или email.</b>
                <br>
                <!-- Введите свой номер телефона для входа.<br> -->

<!--                <span class="login__form_label_text">-->
<!--                    <div>После этого вам должен поступить звонок.</div>-->
<!--                    <div>Впишите последние 4 цифры номера в поле.</div>-->
<!--                    <div>Звонок можно сбросить.</div>-->
<!--                </span>-->
            </label>

            <div class="login__form__input_group d-flex" style="padding: 20px 0 20px 0;">
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [(ngModel)]="typeRegistration" (ngModelChange)="_typeRegistration()" id="phoneRadioBtn" value="phone">
                    <label class="form-check-label" for="phoneRadioBtn">Номер телефона</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" [(ngModel)]="typeRegistration" (ngModelChange)="_typeRegistration()" id="emailRadioBtn" value="email">
                    <label class="form-check-label" for="emailRadioBtn">Email</label>
                </div>
            </div>

            <div class="login__form__input_group d-flex" *ngIf="typeRegistration">
                <input *ngIf="typeRegistration == 'phone'"
                       class="login-form__input form-control mr-3"
                       id="phone"
                       [ngModel]="phone"
                       maxlength="16"
                       placeholder="+7 (555) 555-5555"
                       (ngModelChange)="changeNumberPhone($event)"
                    (keyup.enter)="sendSmsOrEmail($event)">
                <input *ngIf="typeRegistration == 'email'"
                       class="login-form__input form-control mr-3"
                       [ngModel]="email"
                       placeholder="email@mail.ru"
                       type="email"
                       (ngModelChange)="changeNumberEmail($event)"
                       (keyup.enter)="sendSmsOrEmail($event)">
                <button id="send-sms-button" class="login-form__button btn btn-success" (click)="sendSmsOrEmail($event)"
                    disabled>
                    <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                    </svg>
                </button>
            </div>
            <label class="login-form__label form-label"></label>
        </div>

        <div class="login__form w-100" *ngIf="sms && !isCode">
            <span class="h3 mb-4 d-flex align-self-start">Шаг 2 из 3</span>
            <label class="login__form_label" for="code">
                <span class="login__form_label_text" *ngIf="typeRegistration == 'phone'">
                    Вам должен поступить звонок. <br>Впишите последние 4 цифры номера в поле ниже.<br> Звонок можно
                    сбросить.
                </span>
                <span class="login__form_label_text" *ngIf="typeRegistration == 'email'">
                    Мы отправили вам письмо с кодом подтверждения на <b>{{email}}</b>
                </span>
            </label>
            <div class="login__form__input_group d-flex">
                <input class="login-form__input form-control mr-3" type="text" id="code" [(ngModel)]="code"
                    (ngModelChange)="changeCode()" (keyup.enter)="sendCode($event)">
                <button id="send-code-button" class="login-form__button btn btn-success" (click)="sendCode($event)"
                    disabled>
                    <svg width="20px" height="20px" viewBox="0 0 16 16" class="bi bi-arrow-right-short"
                        fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd"
                            d="M8.146 4.646a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.793 8 8.146 5.354a.5.5 0 0 1 0-.708z" />
                        <path fill-rule="evenodd" d="M4 8a.5.5 0 0 1 .5-.5H11a.5.5 0 0 1 0 1H4.5A.5.5 0 0 1 4 8z" />
                    </svg>
                </button>
            </div>
        </div>

        <div class="login__form w-100 d-flex flex-column" *ngIf="isCode">
            <span class="h3 mb-4 d-flex align-self-start">Шаг 3 из 3</span>
            <div class="form-group mb-3">
                <label class="registration__form__label" for="full-name">Ф.И.О. <sup class="text-danger">*</sup></label>
                <input type="text" class="form-control" id="full-name" [(ngModel)]="fullName" required >
            </div>
            <div class="form-group mb-3" *ngIf="typeRegistration !== 'email'">
                <label class="registration__form__label" for="email">E-mail (адрес электронной почты) <sup class="text-danger">*</sup></label>
                <input type="email" class="form-control" id="email" [(ngModel)]="email" required >
            </div>
            <div class="form-group mb-4">
                <label class="registration__form__label" for="user-password">Создать пароль для ЛК (личного кабинета) <sup class="text-danger">*</sup></label>
                <input type="password" class="form-control" id="user-password" [(ngModel)]="password" required >
            </div>
            <button class="btn btn-success" [disabled]="!fullName || !email || !password" (click)="addUserData($event)">Завершить регистрацию</button>
            <small class="align-self-start mt-4"><sup class="text-danger">* </sup>поля, обязательные для заполнения</small>
        </div>


        <!-- <div class="footer-links d-flex flex-column flex-lg-row align-items-center mt-auto pt-3 pb-3">
            <a href="/popular-questions" target="blank">Популярные запросы</a>
            <a href="/privacy-policy" target="blank">Пользовательское соглашение</a>
            <a href="/personal-data-policy" target="blank">Политика обработки персональных данных</a>
        </div> -->
    </div>
    <div class="container-fluid text-center "></div>
</div>
