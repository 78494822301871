import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'toLocalDate'
})
export class ToLocalDatePipe implements PipeTransform {

  transform(value: Date) {
    return new Date(value).toLocaleString();
  }

}
