<app-header [title]="'Мои отправления'" (toggleSidebar)="sidebarOpen($event)"></app-header>

<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>

    <main class="content">
        <div class="container table__messages" *ngIf="posts && posts.length; else tableMessages">
            <div class="table__messages__head__row table__row">
                <div class="table__messages__head__col table__col">Номер отслеживания</div>
                <div class="table__messages__head__col table__col">Дата отправки</div>
                <div class="table__messages__head__col table__col">Получатель</div>
                <div class="table__messages__head__col table__col">Адрес получателя</div>
                <div class="table__messages__head__col table__col">Подразделения доставки</div>
                <div class="table__messages__head__col table__col">Почтовая служба</div>
                <!-- <div class="table__messages__head__col table__col">Прикрепленный файл</div> -->
                <div class="table__messages__head__col table__col">Статус</div>
                <!-- <div class="table__messages__head__col table__col">Отчеты</div> -->
            </div>
            <div class="table__messages__body__row table__row" *ngFor="let post of posts">
                <div class="table__messages__body__col table__col" data-caption="Номер отслеживания">
                    <a *ngIf="post.status !== 'new'" [routerLink]="'/shipment-archive/' + post.id">{{post.cdekNumber || post.spi || 'Отслеживание доставки'}}</a>
                    <a *ngIf="post.status === 'new'" href="javascript:void(0)" (click)="goDetails(post)">Редактирование черновика</a>
                </div>
                <div class="table__messages__body__col table__col">{{post.createdAt | toLocalDate}}</div>
                <div class="table__messages__body__col table__col" data-caption="Получатель"><a href="javascript:void(0)" (click)="goDetails(post)">{{post.receiver.name}}</a></div>
                <div class="table__messages__body__col table__col" data-caption="Адрес получателя">{{post.destination.address}}</div>
                <div class="table__messages__body__col table__col" data-caption="Подразделения доставки">{{getOfficeName(post)}}</div>
                <div class="table__messages__body__col table__col" data-caption="Почтовая служба">{{post.postalServiceAlias == 0 ? 'CDEK' : post.postalServiceAlias == 1 ? 'Национальная почтовая служба' : null}}</div>
                <!-- <div class="table__messages__body__col table__col">
                    <a href="javascript:void(0)" style="border: 1px solid red" download>Договор купли-продажи.pdf</a>
                </div> -->
                <div class="table__messages__body__col table__col" data-caption="Статус письма">
                    <span class="text-success status-{{post.status}}">{{post.status | statusPost}}</span><br>
                </div>
                <!-- <div class="table__messages__body__col table__col">
                    <a href="javascript:void(0)" style="border: 1px solid red" class="text-warning">Квитанция о доставке</a>
                    <a href="javascript:void(0)" style="border: 1px solid red" class="text-warning">Квитанция об оплате</a>
                    <a href="javascript:void(0)" style="border: 1px solid red" class="text-warning">Опись</a>
                </div> -->
            </div>
        </div>
        <ng-template #tableMessages>
            <div class="container text-center mt-2">
                Создайте новое отправление, чтобы оно появилось на этом экране.
            </div>
        </ng-template>
    </main>
</div>
 <!-- #{{post.cdekNumber || post.id}} -->


