import { ToastrService } from 'ngx-toastr';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EpochtaApiService } from '../../services/epochta-api.service';
import {Customer} from '../../models/customer.model';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    emailOrPhone: string = null;
    password: string = null;
    token: string = null;
    trackNumber;

    constructor(
        private router: Router,
        private epochtaApi: EpochtaApiService,
        private toastr: ToastrService
    ) { }

    ngOnInit() {
        this.checkAuth();
    }

    signInHandler() {
        if (!this.password || this.password.length < 6) {
            this.toastr.error("Введите пароль (не менее 6-ти символов)");
            return;
        }

        if (!this.emailOrPhone) {
            this.toastr.error("Введите email или телефон");
            return;
        }

        console.log(this.emailOrPhone);
        this.epochtaApi.сustomerLoginEmailAndPassword(this.emailOrPhone, this.password)
            .catch((e) => {
                if (e?.error?.message) { this.toastr.error(e?.error?.message); }
                return console.error(e);
            }).then(x => {
                if (x) {
                    const token = x.id;
                    console.log(token);
                    if (typeof token !== 'undefined' && token !== null) {
                        localStorage.setItem('auth', token);
                        this.router.navigateByUrl('/history');
                    }
                }
            });
    }

    goTrackDetails() {
        this.router.navigateByUrl("/shipment-archive/" + this.trackNumber);
    }

    async checkAuth() {
        const userInfo = await this.epochtaApi.customers.getCurrentCustomerProfile();

        if (!userInfo) return;

        this.router.navigateByUrl("/history");
    }
}





