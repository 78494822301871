<app-header [title]="'Доставленные отправлений'" (toggleSidebar)="sidebarOpen($event)"></app-header>

<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>

    <main class="content">
        <div class="container" *ngIf="post">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card__title">
                            <div class="card__city">{{post.receiver.name}}</div>
                            <div class="card__date">Дата: {{post.createdAt | toLocalDate}}</div>
                        </div>
                        <div class="card__row">
                            <div class="card__row-right">Отправитель: {{post.sender.name}}</div>
                        </div>
                        <div class="card__row">
                            <div class="card__row-left">Куда: {{post.destination.address}}</div>
                            <div class="card__row-right">Получатель: {{post.receiver.name}}</div>
                        </div>
                    </div>
                </div>
                <div *ngIf="post.changeStatus && post.changeStatus.length">
                    <div class="col-12" *ngFor="let change of post.changeStatus">
                        <div class="card">
                            <div class="card__row">
                                <div class="card__city">Смена статуса на "{{change.status | statusPost}}"</div>
                                <div class="card__date">Дата: {{change.date | date : "dd:MM:yyyy hh:ss"}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>
