import { Office } from './../../models/office.model';
import { EpochtaApiService } from './../../services/epochta-api.service';
import { PostInfo } from 'src/app/models/post.model';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {CodeAndName} from '../../models/code-and-name.model';

@Component({
    selector: 'app-departure-details',
    templateUrl: './departure-details.component.html',
    styleUrls: ['./departure-details.component.scss']
})
export class DepartureDetailsComponent implements OnInit {
    isShowSidebar: boolean = false;
    codes: CodeAndName[] = [];
    officeR: string;

    constructor(
        private route: ActivatedRoute,
        private apiService: EpochtaApiService,
        private router: Router,
        private toastr: ToastrService
    ) { }

    post: PostInfo;
    office: Office;
    waybill;

    async ngOnInit(): Promise<any> {
        this.route.params.subscribe(async (params) => {
            if (!params['id']) { this.router.navigateByUrl('/history'); }
            await this.getPost(params['id']);
            await this.apiService.office.getPostCodes().then(async e => {
                this.codes = e;
                if (this.post?.destination?.postalCode != null) {
                    this.officeR = this.codes.filter(x => x.code === this.post.destination.postalCode)[0]?.name;
                }
            });
        });
    }

    sidebarOpen(open: boolean) {
        this.isShowSidebar = open;
    }

    async getPost(id: string) {
        this.post = await this.apiService.posts.getPostDetails(id);

        this.waybill = this.post.attachments.find(a => a.isWaybill);
        this.post.attachments = this.post.attachments.filter(a => !a.isWaybill);

        this.getOffice(this.post.officeId);
    }

    removePost(id: string) {
        this.apiService.posts.removePost(id).then(res => {
            this.toastr.success("Успешно удалено");
            setTimeout(() => this.router.navigateByUrl("/history"), 1000);
        })
    }

    paymentPost(post: PostInfo) {
        this.apiService.posts.payForOrder(this.post)
            .then(res => location.href = (res));
    }

    downloadAttachment(postId: string, id: string) {
        this.apiService.posts.downloadAttachment(postId, id)
    }

    async getOffice(id: string) {
        if (!id)
            return;

        this.office = await this.apiService.office.getOffice(id);
    }

    isReceivePdf(status: string) {
        return status == "completed" ? true : false;
    }

    isSendPdf(status: string) {
        return status == "waitingOfficeActions" || status == "waitingDelivery" || status == "completed" ? true : false;
    }

    isIndexPdf(status: string) {
        return status == "waitingOfficeActions" || status == "waitingDelivery" || status == "completed" ? true : false;
    }

    isNew(status: string) {
        return status == "new" || status == "waitingPayment" ? true : false;
    }

    genReceivePdf(postId) {
        window.open(this.apiService.pdf.postReceiveFormPdf(postId));
    }

    genSendPdf(postId) {
        window.open(this.apiService.pdf.postSendFormPdf(postId));
    }

    genIndexPdf(postId) {
        window.open(this.apiService.pdf.postIndexFormPdf(postId));
    }

}
