import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import {CustomerLocation, ReceiverLocation} from './../../models/customer-location.model';
import { Component, HostListener, OnInit } from '@angular/core';
import { Customer } from 'src/app/models/customer.model';
import { EpochtaApiService } from 'src/app/services/epochta-api.service';
import {DadataAddress, DadataConfig, DadataSuggestion, DadataType} from '@kolkov/ngx-dadata';
import {environment} from '../../../environments/environment';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
    isEdit: boolean = false;
    customer = new Customer;
    locationDetails = new CustomerLocation;
    receiverDetails = new ReceiverLocation;
    startPhone;
    startEmail;
    delayCode: number;
    delayCodeEmail: number;
    delayInterval;
    isShowSidebar: boolean = false;

    formCustomer: FormGroup;
    formReceiver: FormGroup;

    getConfigCity: DadataConfig;
    getSenderConfigStreet: DadataConfig;
    getSenderConfigHouse: DadataConfig;

    configCompany: DadataConfig = {
        apiKey: environment.apiKeyDaData,
        type: DadataType.party,
        limit: 5
    };

    configAddressReceiver: DadataConfig = {
        apiKey: environment.apiKeyDaData,
        type: DadataType.address
    };

    configAddressCustomer: DadataConfig = {
        apiKey: environment.apiKeyDaData,
        type: DadataType.address
    };

    configName: DadataConfig = {
        apiKey: environment.apiKeyDaData,
        type: DadataType.fio
    };

    constructor(
        private apiService: EpochtaApiService,
        private toastr: ToastrService,
        private router: Router
    ) { }

    // Необходимо закрывать modal bootstrap, после нажатия кнопки "назад" в браузере.
    @HostListener('window:popstate', ['$event'])
    onPopState(event) {
        document.getElementById('close-button')?.click();
    }

    ngOnInit(): void {
        window.alert = () => {};
        this.formCustomer = new FormGroup({
            fullName: new FormControl(null, [Validators.required]),
            locationCity: new FormControl(null, [Validators.required]),
            locationStreet: new FormControl({value: '', disabled: true}, [Validators.required]),
            locationHouse: new FormControl({value: '', disabled: true}, [Validators.required]),
            postalCode: new FormControl({value: '', disabled: true}, [Validators.required]),
            companyName: new FormControl(null),
            comment: new FormControl(null),
            hiddenLocationRegion: new FormControl(null),
            hiddenLocationCity: new FormControl(null),
            hiddenLocationStreet: new FormControl(null),
        });

        this.formReceiver = new FormGroup({
            companyName: new FormControl(null, [Validators.required]),
            locationCity: new FormControl(null, [Validators.required]),
            locationStreet: new FormControl({value: '', disabled: true}, [Validators.required]),
            locationHouse: new FormControl({value: '', disabled: true}, [Validators.required]),
            postalCode: new FormControl({value: '', disabled: true}, [Validators.required]),
            phone: new FormControl(null, [Validators.required]),
            comment: new FormControl(null),
            hiddenLocationRegion: new FormControl(null),
            hiddenLocationCity: new FormControl(null),
            hiddenLocationStreet: new FormControl(null),
        });

        this.getConfigCity = {
            apiKey: environment.apiKeyDaData,
            type: DadataType.address,
            partyAddress: 'city',
            locations: [{
                country: 'Россия',
            }],
            bounds: {
                fromBound: {
                    value: 'city'
                },
                toBound: {
                    value: 'city'
                }
            }
        };

        this.getSenderConfigStreet = { apiKey: environment.apiKeyDaData, type: DadataType.address };
        this.getSenderConfigHouse = { apiKey: environment.apiKeyDaData, type: DadataType.address };

        this.getCustomer();
        this.setStyleAutoText();
    }

    sidebarOpen(open: boolean) {
        this.isShowSidebar = open;
    }

    // Отправить код для подтверждения телефона
    async sendCode() {
        await this.apiService.customers.sendCode(this.customer.phone);
        this.delayCode = 30;
        this.delayInterval = setInterval(() => {
            this.delayCode--;
            if (this.delayCode == 0) {
                clearInterval(this.delayInterval);
                this.delayCode = null;
            }
        }, 1000)
    }

    // Отправить код для подтверждения email
    async sendCodeEmail(): Promise<void> {
        if (!this.validateEmail(this.customer.email)) {
            this.toastr.error('Необходимо указать корректный email.', 'Ошибка');
            return;
        }
        await this.apiService.customers.sendCodeEmail(this.customer.email);
        this.delayCodeEmail = 30;
        this.delayInterval = setInterval(() => {
            this.delayCodeEmail--;
            if (this.delayCodeEmail === 0) {
                clearInterval(this.delayInterval);
                this.delayCodeEmail = null;
            }
        }, 1000);
    }

    // Подтвердить телефон
    async continueCode(code: string) {
        const res = await this.apiService.customers.continueCode(code);
        if (!res)
            return;

        this.customer = res;
        this.startPhone = this.customer.phone;
        this.toastr.success("Номер телефона успешно изменен.");
        // (<HTMLInputElement>document.getElementById("code")).value = ""
        this.closeModal();
    }

    // Подтвердить email
    async continueCodeEmail(code: string) {
        const res = await this.apiService.customers.continueCodeEmail(code);
        if (!res)
            return;

        this.toastr.success("Email успешно изменен.");
        // (<HTMLInputElement>document.getElementById("email")).value = "";
        this.customer = res;
        this.startEmail = this.customer.email;
        console.log(this.customer);
        this.closeModal();
    }

    async getCustomer(): Promise<void> {
        this.customer = await this.apiService.customers.getCurrentCustomerProfile();
        this.startPhone = this.customer.phone;
        this.startEmail = this.customer.email;
    }

    validateEmail(email): any {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    async saveCustomer(): Promise<void> {
        if (this.startPhone !== this.customer.phone) {
            this.toastr.error('Необходимо подтвердить номер телефона.', 'Ошибка');
            return;
        }

        if (this.startEmail !== this.customer.email) {
            this.toastr.error('Необходимо подтвердить email.', 'Ошибка');
            return;
        }

        if (this.customer.friendlyName == null || this.customer.friendlyName === '') {
            this.toastr.error('Необходимо указать ФИО.', 'Ошибка');
            return;
        }

        await this.apiService.customers.saveCustomer(this.customer).then(customer => {
            this.customer = customer;
            this.toastr.success('Данные успешно изменены.');
        });
    }

    editLocation(id: number): void {
        this.isEdit = true;
        this.locationDetails = JSON.parse(JSON.stringify(this.customer.locations.find(m => m.id === id)));

        if (this.locationDetails.name)
            this.formCustomer.get('fullName').setValue(this.locationDetails.name);
        if (this.locationDetails.company)
            this.formCustomer.get('companyName').setValue(this.locationDetails.company);
        if (this.locationDetails.comment)
            this.formCustomer.get('comment').setValue(this.locationDetails.comment);
        if (this.locationDetails.locationCity) {
            this.formCustomer.get('locationCity').setValue(this.locationDetails.locationCity);
        }
        if (this.locationDetails.locationStreet) {
            this.formCustomer.get('locationStreet').setValue(this.locationDetails.locationStreet);
            this.formCustomer.get('locationStreet').enable();
        }
        if (this.locationDetails.locationHouse) {
            this.formCustomer.get('locationHouse').setValue(this.locationDetails.locationHouse);
            this.formCustomer.get('locationHouse').enable();
        }
        if (this.locationDetails.postcode) {
            this.formCustomer.get('postalCode').setValue(this.locationDetails.postcode);
            this.formCustomer.get('postalCode').enable();
        }
    }

    async saveLocation(): Promise<void> {
        if (this.formCustomer.invalid) {
            this.formCustomer.markAllAsTouched();
            return;
        }
        this.locationDetails.name = this.formCustomer.get('fullName').value;
        this.locationDetails.address = `${this.formCustomer.get('locationCity').value}, ${this.formCustomer.get('locationStreet').value}, ${this.formCustomer.get('locationHouse').value}`;
        this.locationDetails.postcode = this.formCustomer.get('postalCode').value;
        this.locationDetails.company = this.formCustomer.get('companyName').value;
        this.locationDetails.comment = this.formCustomer.get('comment').value;
        this.locationDetails.locationRegion = this.formCustomer.get('hiddenLocationRegion').value;
        this.locationDetails.locationCity = this.formCustomer.get('locationCity').value;
        this.locationDetails.locationStreet = this.formCustomer.get('locationStreet').value;
        this.locationDetails.locationHouse = this.formCustomer.get('locationHouse').value;
        this.customer = await this.apiService.customers.saveCustomerLocation(this.customer.id, this.locationDetails);
        this.locationDetails = new CustomerLocation();
        this.closeModal();
    }

    async removeLocation(id: number): Promise<void> {
        this.customer = await this.apiService.customers.removeCustomerLocation(this.customer.id, id);
        this.closeModal();
    }

    clearLocationDetails(): void {
        this.locationDetails = new CustomerLocation();
    }

    private closeModal(): void {
        document.getElementById('close-modal').click();
        document.getElementById('close-code-modal').click();
        document.getElementById('close-button-receivers').click();
        document.getElementById('close-email-modal').click();
    }

    logOut(): void {
        localStorage.clear();
        this.router.navigateByUrl('/login');
    }

    editReceiver(id: number): void {
        this.isEdit = true;
        this.receiverDetails = JSON.parse(JSON.stringify(this.customer.receivers.find(m => m.id === id)));

        if (this.receiverDetails.phone)
            this.formReceiver.get('phone').setValue(this.receiverDetails.phone);
        if (this.receiverDetails.company)
            this.formReceiver.get('companyName').setValue(this.receiverDetails.company);
        if (this.receiverDetails.comment)
            this.formReceiver.get('comment').setValue(this.receiverDetails.comment);
        if (this.receiverDetails.locationCity) {
            this.formReceiver.get('locationCity').setValue(this.receiverDetails.locationCity);
        }
        if (this.receiverDetails.locationStreet) {
            this.formReceiver.get('locationStreet').setValue(this.receiverDetails.locationStreet);
            // this.formReceiver.get('locationStreet').enable();
        }
        if (this.receiverDetails.locationHouse) {
            this.formReceiver.get('locationHouse').setValue(this.receiverDetails.locationHouse);
            // this.formReceiver.get('locationHouse').enable();
        }
        if (this.receiverDetails.postcode) {
            this.formReceiver.get('postalCode').setValue(this.receiverDetails.postcode);
            this.formReceiver.get('postalCode').enable();
        }
    }

    async removeReceiver(id: number): Promise<void> {
        this.customer = await this.apiService.customers.removeReceiverLocation(this.customer.id, id);
        this.closeModal();
    }

    async saveReceiver(): Promise<void> {
        if (this.formReceiver.invalid) {
            this.formReceiver.markAllAsTouched();
            return;
        }
        this.receiverDetails.company = this.formReceiver.get('companyName').value;
        this.receiverDetails.address = `${this.formReceiver.get('locationCity').value}, ${this.formReceiver.get('locationStreet').value}, ${this.formReceiver.get('locationHouse').value}`;
        this.receiverDetails.phone = this.formReceiver.get('phone').value;
        this.receiverDetails.postcode = this.formReceiver.get('postalCode').value;
        this.receiverDetails.officeId = null;
        this.receiverDetails.comment = this.formReceiver.get('comment').value;
        this.receiverDetails.locationRegion = this.formReceiver.get('hiddenLocationRegion').value;
        this.receiverDetails.locationCity = this.formReceiver.get('locationCity').value;
        this.receiverDetails.locationStreet = this.formReceiver.get('locationStreet').value;
        this.receiverDetails.locationHouse = this.formReceiver.get('locationHouse').value;
        this.customer = await this.apiService.customers.saveReceiverLocation(this.customer.id, this.receiverDetails);
        this.receiverDetails = new ReceiverLocation();
        this.closeModal();
    }

    addEdit(): void {
        this.isEdit = false;
        this.receiverDetails = new ReceiverLocation();
        this.locationDetails = new CustomerLocation();
        this.formCustomer.reset();
        this.formReceiver.reset();
    }

    private setStyleAutoText(): any {
        const els = document.querySelectorAll('.dadata');
        els.forEach(el => {
            const inputEl = <HTMLElement>el.firstChild.firstChild;
            const elem = <HTMLElement>el;
            // elem.style.padding = "0";
            inputEl.style.backgroundColor = '#fff';
            inputEl.setAttribute('class', 'form-control');
            inputEl.style.paddingLeft = '12px';
        });
    }

    onReceiverLocationSelected(event: DadataSuggestion, bound: string): any {
        const addressData = event.data as DadataAddress;
        if (bound === 'city') {
            this.formReceiver.get('hiddenLocationRegion').setValue(addressData.region);
            this.formReceiver.get('hiddenLocationCity').setValue(addressData.city);
            this.configAddressReceiver = {
                apiKey: environment.apiKeyDaData,
                type: DadataType.address,
                locations: [{
                    region: addressData.region,
                    city: addressData.city,
                }],
                bounds: {
                    fromBound: {
                        value: 'street'
                    },
                    toBound: {
                        value: 'street'
                    }
                }
            };
            this.formReceiver.get('locationStreet').enable();
        } else if (bound === 'street') {
            this.formReceiver.get('hiddenLocationStreet').setValue(addressData.street);
            this.configAddressReceiver = {
                apiKey: environment.apiKeyDaData,
                type: DadataType.address,
                locations: [{
                    region: addressData.region,
                    city: addressData.city,
                    street: addressData.street,
                }],
                bounds: {
                    fromBound: {
                        value: 'house'
                    },
                    toBound: {
                        value: 'house'
                    }
                }
            };
            this.formReceiver.get('locationHouse').enable();
        } else if (bound === 'house') {
            this.formReceiver.get('postalCode').setValue(addressData.postal_code);
            this.formReceiver.get('postalCode').enable();
        }
    }
    onCustomerLocationSelected(event: DadataSuggestion, bound: string): any {
        const addressData = event.data as DadataAddress;
        if (bound === 'city') {
            this.formCustomer.get('hiddenLocationRegion').setValue(addressData.region);
            this.formCustomer.get('hiddenLocationCity').setValue(addressData.city);
            this.configAddressCustomer = {
                apiKey: environment.apiKeyDaData,
                type: DadataType.address,
                locations: [{
                    region: addressData.region,
                    city: addressData.city,
                }],
                bounds: {
                    fromBound: {
                        value: 'street'
                    },
                    toBound: {
                        value: 'street'
                    }
                }
            };
            this.formCustomer.get('locationStreet').enable();
        } else if (bound === 'street') {
            this.formCustomer.get('hiddenLocationStreet').setValue(addressData.street);
            this.configAddressCustomer = {
                apiKey: environment.apiKeyDaData,
                type: DadataType.address,
                locations: [{
                    region: addressData.region,
                    city: addressData.city,
                    street: addressData.street,
                }],
                bounds: {
                    fromBound: {
                        value: 'house'
                    },
                    toBound: {
                        value: 'house'
                    }
                }
            };
            this.formCustomer.get('locationHouse').enable();
        } else if (bound === 'house') {
            this.formCustomer.get('postalCode').setValue(addressData.postal_code);
            this.formCustomer.get('postalCode').enable();
        }
    }

    resetAddressReceiver(bound: string): void {
        if (bound === 'city') {
            if (this.formReceiver.get('locationCity').value === '') {
                this.formReceiver.get('locationStreet').setValue('');
                this.formReceiver.get('locationStreet').disable();
                this.formReceiver.get('locationHouse').setValue('');
                this.formReceiver.get('locationHouse').disable();
            }
        } else if (bound === 'street') {
            if (this.formReceiver.get('locationStreet').value === '') {
                this.formReceiver.get('locationHouse').setValue('');
                this.formReceiver.get('locationHouse').disable();
                this.formReceiver.get('postalCode').setValue('');
                this.formReceiver.get('postalCode').disable();
                this.configAddressReceiver.locations = [{
                    region: this.formReceiver.get('hiddenLocationRegion').value,
                    city: this.formReceiver.get('hiddenLocationCity').value,
                }];
                this.configAddressReceiver.bounds = { fromBound: { value: 'street' }, toBound: { value: 'street' } };
            }
        } else if (bound === 'house') {
            if (this.formReceiver.get('locationHouse').value === '') {
                this.formReceiver.get('postalCode').setValue('');
                this.formReceiver.get('postalCode').disable();
            }
        }
    }
    resetAddressCustomer(bound: string): void {
        if (bound === 'city') {
            if (this.formCustomer.get('locationCity').value === '') {
                this.formCustomer.get('locationStreet').setValue('');
                this.formCustomer.get('locationStreet').disable();
                this.formCustomer.get('locationHouse').setValue('');
                this.formCustomer.get('locationHouse').disable();
                this.formCustomer.get('postalCode').setValue('');
                this.formCustomer.get('postalCode').disable();
            }
        } else if (bound === 'street') {
            if (this.formCustomer.get('locationStreet').value === '') {
                this.formCustomer.get('locationHouse').setValue('');
                this.formCustomer.get('locationHouse').disable();
                this.formCustomer.get('postalCode').setValue('');
                this.formCustomer.get('postalCode').disable();
                this.configAddressCustomer.locations = [{
                    region: this.formCustomer.get('hiddenLocationRegion').value,
                    city: this.formCustomer.get('hiddenLocationCity').value,
                }];
                this.configAddressCustomer.bounds = { fromBound: { value: 'street' }, toBound: { value: 'street' } };
            }
        } else if (bound === 'house') {
            if (this.formCustomer.get('locationHouse').value === '') {
                this.formCustomer.get('postalCode').setValue('');
                this.formCustomer.get('postalCode').disable();
            }
        }
    }

}
