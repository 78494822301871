<app-header [title]="'Личный кабинет'" (toggleSidebar)="sidebarOpen($event)"></app-header>

<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>

    <main class="content">
        <div class="container">
            <div class="row" *ngIf="customer">
                <div class="col-6 col-lg-3 mb-3">
                    <label for="phone" class="form-label">Мой телефон</label>
                    <input appPhoneMask type="text" id="phone" class="form-control" [(ngModel)]="customer.phone">
                    <input data-toggle="modal" data-target="#codeModal"
                           *ngIf="startPhone != customer.phone" type="button" class="btn btn-success mt-2 mr-2"
                           value="Подтвердить">
                    <input *ngIf="startPhone != customer.phone" type="button" class="btn btn-light mt-2" value="Отмена"
                           (click)="customer.phone = startPhone">
                </div>
                <div class="col-6 col-lg-3 mb-3">
                    <label for="email" class="form-label">Email</label>
                    <input type="text" id="email" class="form-control" [(ngModel)]="customer.email">
                    <input data-toggle="modal" data-target="#emailModal"
                           *ngIf="startEmail != customer.email" type="button" class="btn btn-success mt-2 mr-2"
                           value="Подтвердить">
                    <input *ngIf="startEmail != customer.email" type="button" class="btn btn-light mt-2" value="Отмена"
                           (click)="customer.email = startEmail">
                </div>
                <div class="col-6 col-lg-3 mb-3">
                    <label for="name" class="form-label">ФИО</label>
                    <input type="text" id="name" class="form-control" [(ngModel)]="customer.friendlyName">
                </div>
                <div class="col-6 col-lg-3 col-lg-3 mb-3">
                    <label for="save" class="form-label" style="visibility: collapse;">Save</label>
                    <input type="button" class="form-control btn btn-success" value="Сохранить" (click)="saveCustomer()">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <h4>Отправители</h4>
                    <table class="table table-striped">
                        <thead>
                            <tr class="table-secondary">
                                <th>ФИО</th>
                                <th>Адрес</th>
                                <th>Почтовый индекс</th>
                                <th>Компания</th>
                                <th>Комментарий</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let location of customer.locations">
                                <td data-label="ФИО">{{location.name}}</td>
                                <td data-label="Адрес">{{location.address}}</td>
                                <td data-label="Почтовый индекс">{{location.postcode}}</td>
                                <td data-label="Компания">{{location.company}}</td>
                                <td data-label="Комментарий">{{location.comment}}</td>
                                <td data-label="">
                                    <div class="d-flex btn-wrap">
                                        <span class="btn btn-link table__button" data-toggle="modal"
                                            data-target="#locationsModal"
                                            (click)="editLocation(location.id)">Редактировать</span>
                                        <span class="btn btn-link" (click)="removeLocation(location.id)">Удалить</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-link mt-1" (click)="addEdit()" data-toggle="modal"
                        data-target="#locationsModal">Добавить</button>
                </div>
            </div>
            <div class="row" style="margin-top: 30px;">
                <div class="col-12">
                    <h4>Получатели</h4>
                    <table class="table table-striped">
                        <thead>
                        <tr class="table-secondary">
                            <th>Название организации, ИНН</th>
                            <th>Адрес</th>
                            <th>Контактный телефон</th>
                            <th>Комментарий</th>
                            <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let receiver of customer.receivers">
                            <td data-label="Название организации, ИНН">{{receiver.company}}</td>
                            <td data-label="Адрес">{{receiver.address}}</td>
                            <td data-label="Контактный телефон">{{receiver.phone}}</td>
                            <td data-label="Комментарий">{{receiver.comment}}</td>
                            <td data-label="">
                                <div class="d-flex btn-wrap">
                                        <span class="btn btn-link table__button" data-toggle="modal"
                                              data-target="#receiversModal"
                                              (click)="editReceiver(receiver.id)">Редактировать</span>
                                    <span class="btn btn-link" (click)="removeReceiver(receiver.id)">Удалить</span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <button class="btn btn-link mt-1" (click)="addEdit()" data-toggle="modal"
                            data-target="#receiversModal">Добавить</button>
                    <br>
                    <button class="btn btn-success btn__loguot mt-4" (click)="logOut()">Выйти из аккаунта</button>
                    <br>
                    <br>
                </div>
            </div>
            <div class="modal fade" id="locationsModal" tabindex="-1" aria-labelledby="locationsModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="locationsModalLabel">
                                {{isEdit ? "Редактирование" : "Добавление"}}
                            </h5>
                            <button type="button" id="close-button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="formCustomer">

                                <label class="form-label">ФИО*</label>
                                <ngx-dadata [config]="configName"
                                            type="text"
                                            formControlName="fullName"
                                            name="fullName"
                                            [ngClass]="formCustomer.get('fullName').invalid ? 'is-invalid' : null"
                                            class="form-control mb-3 dadata"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Город*</label>
                                <ngx-dadata [config]="getConfigCity"
                                            type="text"
                                            formControlName="locationCity"
                                            name="locationCity"
                                            class="form-control mb-3 dadata"
                                            [ngClass]="formCustomer.get('locationCity').invalid ? 'is-invalid' : null"
                                            (selected)="onCustomerLocationSelected($event, 'city')"
                                            (ngModelChange)="resetAddressCustomer('city');"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Улица*</label>
                                <ngx-dadata [config]="configAddressCustomer"
                                            type="text"
                                            formControlName="locationStreet"
                                            name="locationStreet"
                                            class="form-control mb-3 dadata"
                                            [ngClass]="formCustomer.get('locationStreet').invalid ? 'is-invalid' : null"
                                            (selected)="onCustomerLocationSelected($event, 'street')"
                                            (ngModelChange)="resetAddressCustomer('street');"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Дом*</label>
                                <ngx-dadata [config]="configAddressCustomer"
                                            type="text"
                                            formControlName="locationHouse"
                                            name="locationHouse"
                                            class="form-control mb-3 dadata"
                                            [ngClass]="formCustomer.get('locationHouse').invalid ? 'is-invalid' : null"
                                            (selected)="onCustomerLocationSelected($event, 'house')"
                                            (ngModelChange)="resetAddressCustomer('house');"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Почтовый индекс*</label>
                                <input type="text"
                                       formControlName="postalCode"
                                       [ngClass]="formCustomer.get('postalCode').invalid ? 'is-invalid' : null"
                                       name="postalCode" class="form-control mb-2">

                                <label class="form-label">Название организации, ИНН</label>
                                <ngx-dadata [config]="configCompany"
                                            formControlName="companyName"
                                            name="companyName"
                                            type="text"
                                            class="form-control mb-3 dadata"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Комментарий</label>
                                <input type="text"
                                       formControlName="comment"
                                       name="comment" class="form-control mb-2">
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" id="close-modal"
                                data-dismiss="modal">Закрыть</button>
                            <button type="button" class="btn btn-success" (click)="saveLocation()">
                                {{isEdit ? "Сохранить" : "Добавить" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal fade" id="receiversModal" tabindex="-1" aria-labelledby="receiversModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="receiversModalLabel">
                                {{isEdit ? "Редактирование" : "Добавление"}}
                            </h5>
                            <button type="button" id="close-button-receivers" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="modal-body">
                            <form [formGroup]="formReceiver">
                                <label class="form-label">Название организации, ИНН*</label>
                                <ngx-dadata [config]="configCompany"
                                            formControlName="companyName"
                                            name="companyName"
                                            type="text"
                                            [ngClass]="formReceiver.get('companyName').invalid ? 'is-invalid' : null"
                                            class="form-control mb-3 dadata"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Город*</label>
                                <ngx-dadata [config]="getConfigCity"
                                            type="text"
                                            formControlName="locationCity"
                                            name="locationCity"
                                            class="form-control mb-3 dadata"
                                            [ngClass]="formReceiver.get('locationCity').invalid ? 'is-invalid' : null"
                                            (selected)="onReceiverLocationSelected($event, 'city')"
                                            (ngModelChange)="resetAddressReceiver('city');"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Улица*</label>
                                <ngx-dadata [config]="configAddressReceiver"
                                            type="text"
                                            formControlName="locationStreet"
                                            name="locationStreet"
                                            class="form-control mb-3 dadata"
                                            [ngClass]="formReceiver.get('locationStreet').invalid ? 'is-invalid' : null"
                                            (selected)="onReceiverLocationSelected($event, 'street')"
                                            (ngModelChange)="resetAddressReceiver('street');"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Дом*</label>
                                <ngx-dadata [config]="configAddressReceiver"
                                            type="text"
                                            formControlName="locationHouse"
                                            name="locationHouse"
                                            class="form-control mb-3 dadata"
                                            [ngClass]="formReceiver.get('locationHouse').invalid ? 'is-invalid' : null"
                                            (selected)="onReceiverLocationSelected($event, 'house')"
                                            (ngModelChange)="resetAddressReceiver('house');"
                                            style="padding: 0;">
                                </ngx-dadata>

                                <label class="form-label">Почтовый индекс*</label>
                                <input type="text"
                                       formControlName="postalCode"
                                       [ngClass]="formReceiver.get('postalCode').invalid ? 'is-invalid' : null"
                                       name="postalCode" class="form-control mb-2">

                                <label class="form-label">Контактный телефон*</label>
                                <input type="text"
                                       appPhoneMask
                                       formControlName="phone"
                                       [ngClass]="formReceiver.get('phone').invalid ? 'is-invalid' : null"
                                       name="phone" class="form-control mb-2">

                                <label class="form-label">Комментарий</label>
                                <input type="text"
                                       formControlName="comment"
                                       name="comment" class="form-control mb-2">
                            </form>
                        </div>
                        <div class="modal-footer">
                            <button type="button" class="btn btn-secondary" id="close-modal-receivers"
                                    data-dismiss="modal">Закрыть</button>
                            <button type="button" class="btn btn-success" (click)="saveReceiver()">
                                {{isEdit ? "Сохранить" : "Добавить" }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>

<div class="modal fade" id="codeModal" tabindex="-1" aria-labelledby="codeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="codeModalLabel">
                    Смена номера телефона
                </h5>
                <button type="button" id="close-button-code" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="mb-2">Мой телефон</div>
                <input appPhoneMask [(ngModel)]="customer.phone" class="form-control mb-3">
                <div class="mb-2">Код подтверждения</div>
                <input #code id="code" placeholder="1234" type="number" class="form-control mb-3">
                <label class="form-label">
                    Вам должен поступить звонок. <br>Впишите последние 4 цифры номера в поле кода подтверждения.<br>
                    Звонок можно
                    сбросить.
                </label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-link" id="close-code-modal" data-dismiss="modal">Закрыть</button>
                <button (click)="sendCode()" type="button" class="btn btn-outline-success" [disabled]="delayCode">
                    Отправить код
                    <span *ngIf="delayCode">{{delayCode}}</span>
                </button>
                <button type="button" class="btn btn-success" (click)="continueCode(code.value)">
                    Подтвердить
                </button>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="emailModal" tabindex="-1" aria-labelledby="emailModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="emailModalLabel">
                    Смена email
                </h5>
                <button type="button" id="close-button-email" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="mb-2">Email</div>
                <input [(ngModel)]="customer.email" class="form-control mb-3">
                <div class="mb-2">Код подтверждения</div>
                <input #codeEmail id="codeEmail" placeholder="1234" type="number" class="form-control mb-3">
                <label class="form-label">
                    Вам должно придти письмо на email с кодом подтверждения.
                </label>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-link" id="close-email-modal" data-dismiss="modal">Закрыть</button>
                <button (click)="sendCodeEmail()" type="button" class="btn btn-outline-success" [disabled]="delayCodeEmail">
                    Отправить код
                    <span *ngIf="delayCodeEmail">{{delayCodeEmail}}</span>
                </button>
                <button type="button" class="btn btn-success" (click)="continueCodeEmail(codeEmail.value)">
                    Подтвердить
                </button>
            </div>
        </div>
    </div>
</div>
