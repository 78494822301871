<app-header [title]="'Доставленные отправления'"></app-header>

<div class="content__wrapper">
    <app-sidebar-menu [isThisSidebarShow]="isShowSidebar"></app-sidebar-menu>

    <main class="content">

        <div class="text-center mt-2" *ngIf="!posts || !posts.length">
            Доставленных отправлений нет. Отправления появляются здесь после успешной доставки.
        </div>

        <div class="container table__messages" *ngIf="posts && posts.length">
            <div class="table__messages__head__row table__row">
                <div class="table__messages__head__col table__col">Номер отслеживания</div>
                <div class="table__messages__head__col table__col">Дата отправки</div>
                <div class="table__messages__head__col table__col">Получатель</div>
                <div class="table__messages__head__col table__col">Адрес получателя</div>
                <!-- <div class="table__messages__head__col table__col">Прикрепленный файл</div> -->
                <div class="table__messages__head__col table__col">Статус</div>
                <!-- <div class="table__messages__head__col table__col">Отчеты</div> -->
            </div>
            <div class="table__messages__body__row table__row" *ngFor="let post of posts">
                <div class="table__messages__body__col table__col" data-caption="Номер отслеживания">
                    <a [routerLink]="'/shipment-archive/' + post.id">{{post.cdekNumber || 'Отслеживание доставки'}}</a>
                </div>
                <div class="table__messages__body__col table__col">{{post.createdAt | toLocalDate}}</div>
                <div class="table__messages__body__col table__col" data-caption="Получатель"><a href="javascript:void(0)" (click)="goDetails(post)">{{post.receiver.name}}</a></div>
                <div class="table__messages__body__col table__col" data-caption="Адрес получателя">{{post.destination.address}}</div>
                <div class="table__messages__body__col table__col" data-caption="Статус письма">
                    <span class="text-success">{{post.status | statusPost}}</span><br>
                </div>
            </div>
        </div>
    </main>
</div>
