import {environment} from './../../../environments/environment';
import {ToastrService} from 'ngx-toastr';
import {Attachment, AttachmentReq} from './../../models/attachment.model';
import {ActivatedRoute, Router} from '@angular/router';
import {Customer} from 'src/app/models/customer.model';
import {EpochtaApiService} from 'src/app/services/epochta-api.service';
import {Post} from './../../models/post.model';
import {AfterContentChecked, Component, Inject, OnInit} from '@angular/core';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {FileSystemFileEntry, NgxFileDropEntry} from 'ngx-file-drop';
import {DadataAddress, DadataConfig, DadataSuggestion, DadataType} from '@kolkov/ngx-dadata';
import {CodeAndName} from '../../models/code-and-name.model';
import {CustomerLocation, ReceiverLocation} from '../../models/customer-location.model';
import {DOCUMENT} from '@angular/common';
import {PostalServiceAlias, PostalService} from "../../models/postal-service.model";
import * as events from "events";

@Component({
    selector: 'app-create-departure',
    templateUrl: './create-departure.component.html',
    styleUrls: ['./create-departure.component.scss']
})
export class CreateDepartureComponent implements OnInit, AfterContentChecked  {
    public form: FormGroup;
    public receiversFormArray: FormArray;
    public get receivers(): FormArray {
        return this.form.get('receivers') as FormArray;
    }

    submitted = false;
    post: Post;
    customer = new Customer;
    selectedLocation: number = null;
    timeoutChange; // Для выполнения ClearTimeout(timeoutChange)

    getConfigCity: DadataConfig;
    getSenderConfigStreet: DadataConfig;
    getSenderConfigHouse: DadataConfig;

    configAddressReceiver: DadataConfig = {
        apiKey: environment.apiKeyDaData,
        type: DadataType.address
    };
    configCompany: DadataConfig = {
        apiKey: environment.apiKeyDaData,
        type: DadataType.party
    };
    configName: DadataConfig = {
        apiKey: environment.apiKeyDaData,
        type: DadataType.fio
    };

    currentAddress;
    codes: CodeAndName[] = [];
    selectedOffice: string;
    attachments: Attachment[];
    isLoading: boolean;
    startCustomerEmail: string;
    isShowSidebar: boolean;
    disabledSenderStreet: boolean;
    disabledSenderHouse: boolean;

    postalServices: PostalService[] = [];

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private apiService: EpochtaApiService,
        private router: Router,
        private toastr: ToastrService,
        private route: ActivatedRoute
    ) { }

    sidebarOpen(open: boolean): any {
        this.isShowSidebar = open;
    }

    async removeAttachment(i: string): Promise<any> {
        this.post.attachments = this.post.attachments.filter(m => m !== this.post.attachments[i]);

        let totalPage = 0;
        for (let i = 0; i < this.post.attachments.length; i++) {
            totalPage = totalPage + +this.post.attachments[i].pageCount;
        }
        this.post.cost = (+environment.costO + (+environment.pricePerPage * +totalPage));

        if (!this.post.receiverLocation
            || !this.post.receiverName
            || !this.post.receiverPhone) { return; }
        await this.apiService.posts.saveAttachments(this.post);
    }

    public editFileName(attr: any, i: string): any {
        if (attr.alternativeName === null || attr.alternativeName === '') {
            attr.alternativeName = attr.filename;
        }
        attr.isModeEdit = !attr.isModeEdit;
        setTimeout(() => {
            this.document.getElementById('rename-file-input-' + i).focus();
            this.document.getElementById('rename-file-input-' + i).onblur = () => {
                this.editFileName(attr, i);
            };
        }, 100);
    }

    onAddressSelected(event: DadataSuggestion): any {
        const addressData = event.data as DadataAddress;
        console.log(addressData);
    }

    async focusOut(): Promise<any> {
        this.refreshCost();
        // this.post.postalCode = (<HTMLInputElement>document.getElementById('codes-select'))?.value;
        this.post.senderName = this.form.get('senderName').value;
        this.post.senderCompany = this.form.get('senderCompany').value;
        this.post.senderLocation = this.form.get('senderLocationCity').value + ', ' + this.form.get('senderLocationStreet').value + ', ' + this.form.get('senderLocationHouse').value;

        for (const item of this.receiversFormArray.controls) {
            this.post.receiverName = item.get('receiverName').value;
            this.post.receiverPhone = item.get('receiverPhone').value;
            this.post.postalServiceAlias = item.get('postalService').value;
            this.post.city = item.get('receiverLocationCity').value;
            this.post.street = item.get('receiverLocationStreet').value;
            this.post.house = item.get('receiverLocationHouse').value;
            this.post.postalCodeIndex = item.get('receiverPostalCodeIndex').value;
            break;
        }

        if (!this.post.receiverName
            || !this.post.receiverPhone
            || !this.post.senderName) { return; }
        const res = await this.apiService.posts.updatePost(this.post);
        if (!res || !res.id) { return; }
        this.post.id = res.id;
        if (this.post.attachments?.length) { await this.apiService.posts.saveAttachments(this.post); }
        if (this.customer.email !== this.startCustomerEmail) { await this.apiService.customers.saveCustomer(this.customer); }
    }

    async ngOnInit(): Promise<any> {
        window.alert = () => {};
        this.post = new Post();
        this.disabledSenderStreet = true;
        this.disabledSenderHouse = true;
        this.postalServices.push(
            { name: 'CDEK', postalServiceAlias: PostalServiceAlias.Cdek },
            { name: 'Национальная почтовая служба', postalServiceAlias: PostalServiceAlias.Nationalpost });
        this.receiversFormArray = new FormArray([
            new FormGroup({
                receiverName: new FormControl(null,  Validators.required),
                receiverLocationCity: new FormControl(null, Validators.required),
                receiverLocationStreet: new FormControl({value: '', disabled: true}, Validators.required),
                receiverLocationHouse: new FormControl({value: '', disabled: true}, Validators.required),
                receiverPostalCodeIndex: new FormControl({value: '', disabled: true}, Validators.required),
                receiverPhone: new FormControl(null, [Validators.required, Validators.minLength(12)]),
                postalService: new FormControl(PostalServiceAlias.Nationalpost, Validators.required),
                hiddenReceiverLocationRegion: new FormControl(null),
                hiddenReceiverLocationCity: new FormControl(null),
                hiddenReceiverLocationStreet: new FormControl(null),
            })
        ]);

        this.form = new FormGroup({
            senderName: new FormControl(null, [Validators.required]),
            senderLocationCity: new FormControl(null),
            senderLocationStreet: new FormControl(null),
            senderLocationHouse: new FormControl(null),
            senderCompany: new FormControl(null),
            email: new FormControl(null, [Validators.required, Validators.email]),
            receiverCheckPolicy: new FormControl(false, [Validators.requiredTrue]),
            receivers: this.receiversFormArray
        });

        this.getConfigCity = {
            apiKey: environment.apiKeyDaData,
            type: DadataType.address,
            partyAddress: 'city',
            locations: [{
                    country: 'Россия',
                }],
            bounds: {
                fromBound: {
                    value: 'city'
                },
                toBound: {
                    value: 'city'
                }
            }
        };

        this.getSenderConfigStreet = { apiKey: environment.apiKeyDaData, type: DadataType.address };
        this.getSenderConfigHouse = { apiKey: environment.apiKeyDaData, type: DadataType.address };

        this.customer = await this.apiService.customers.getCurrentCustomerProfile();
        this.startCustomerEmail = this.customer.email;
        console.log(this.customer)
        this.form.get('email').setValue(this.startCustomerEmail);

        if (this.customer?.locations) {
            const emptyLocation: CustomerLocation = { id: 0, name: 'Новый отправитель', address: null, company: null, postcode: null, comment: null, locationRegion: null, locationCity: null, locationHouse: null, locationStreet: null };
            this.customer.locations.unshift(emptyLocation);
            this.selectedLocation = emptyLocation.id;
        }
        if (this.customer?.receivers) {
            const emptyLocationR: ReceiverLocation = { id: 0, company: 'Новый получатель', address: null, phone: null, officeId: null, comment: null, locationRegion: null, locationCity: null, locationHouse: null, locationStreet: null, postcode: null };
            this.customer.receivers.unshift(emptyLocationR);
            this.selectedLocation = emptyLocationR.id;
        }
        this.setStyleAutoText();
        this.getPostCodes();
        const postId = this.route.snapshot.paramMap.get('id');
        if (!postId) { return; }
        const res = await this.apiService.posts.getPostDetails(postId);
        if (!res) { return; }
        this.post.id = res.id;
        for (const item of this.receiversFormArray.controls) {
            item.get('receiverName').setValue(res.receiver.name);
            item.get('receiverPhone').setValue(res.receiver.phone);
            item.get('postalService').setValue(res.postalServiceAlias);

            if (res.receiver.city && res.receiver.street && res.receiver.house && res.receiver.postalCodeIndex) {
                item.get('receiverLocationCity').setValue(res.receiver.city);
                item.get('receiverLocationCity').enable();
                item.get('receiverLocationStreet').setValue(res.receiver.street);
                item.get('receiverLocationStreet').enable();
                item.get('receiverLocationHouse').setValue(res.receiver.house);
                item.get('receiverLocationHouse').enable();
                item.get('receiverPostalCodeIndex').setValue(res.receiver.postalCodeIndex);
                item.get('receiverPostalCodeIndex').enable();
            }

            break;
        }

        if (res.sender.address != null) {
            const arrayOfStrings = res.sender.address.split(',');
            if (arrayOfStrings.length === 3) {
                this.form.get('senderLocationCity').setValue(arrayOfStrings[0].trim() !== 'null' ? arrayOfStrings[0].trim() : null);
                this.form.get('senderLocationCity').enable();
                this.form.get('senderLocationStreet').setValue(arrayOfStrings[1].trim() !== 'null' ? arrayOfStrings[1].trim() : null);
                this.form.get('senderLocationStreet').enable();
                this.form.get('senderLocationHouse').setValue(arrayOfStrings[2].trim() !== 'null' ? arrayOfStrings[2].trim() : null);
                this.form.get('senderLocationHouse').enable();
            }
        }

        this.form.get('senderName').setValue(res.sender.name);
        // this.form.get('senderLocation').setValue(res.sender.address);
        this.form.get('senderCompany').setValue(res.sender.company);
        this.form.get('senderCompany').setValue(res.sender.company);
        this.form.get('email').setValue(this.startCustomerEmail);
        this.post.cost = res.cost > 0 ? res.cost : 35000;
        this.post.attachments = res.attachments;
        this.post.status = res.status;
        setTimeout(() => {
            // (<HTMLInputElement>document.getElementById("codes-select")).value = res.destination.postalCode;
            this.selectedOffice = this.codes.find(m => m.code === res.destination.postalCode)?.name;
        });
    }

    onSenderLocationCitySelected(event: DadataSuggestion): any {
        const addressData = event.data as DadataAddress;
        this.disabledSenderStreet = false;
        this.getSenderConfigStreet = {
            apiKey: environment.apiKeyDaData,
            type: DadataType.address,
            locations: [{
                region: addressData.region,
                city: addressData.city,
            }],
            bounds: {
                fromBound: {
                    value: 'street'
                },
                toBound: {
                    value: 'street'
                }
            }
        };
    }

    onSenderLocationStreetSelected(event: DadataSuggestion): any {
        const addressData = event.data as DadataAddress;
        this.disabledSenderHouse = false;
        this.getSenderConfigHouse = {
            apiKey: environment.apiKeyDaData,
            type: DadataType.address,
            locations: [{
                region: addressData.region,
                city: addressData.city,
                street: addressData.street,
            }],
            bounds: {
                fromBound: {
                    value: 'house'
                },
                toBound: {
                    value: 'house'
                }
            }
        };
    }

    onReceiverLocationSelected(event: DadataSuggestion, bound: string, control: any): any {
        const addressData = event.data as DadataAddress;
        const controlField = control as FormGroup;
        if (bound === 'city') {
            controlField.get('hiddenReceiverLocationRegion').setValue(addressData.region);
            controlField.get('hiddenReceiverLocationCity').setValue(addressData.city);
            this.configAddressReceiver = {
                apiKey: environment.apiKeyDaData,
                type: DadataType.address,
                locations: [{
                    region: addressData.region,
                    city: addressData.city,
                }],
                bounds: {
                    fromBound: {
                        value: 'street'
                    },
                    toBound: {
                        value: 'street'
                    }
                }
            };
            controlField.get('receiverLocationStreet').enable();
        } else if (bound === 'street') {
            controlField.get('hiddenReceiverLocationStreet').setValue(addressData.street);
            this.configAddressReceiver = {
                apiKey: environment.apiKeyDaData,
                type: DadataType.address,
                locations: [{
                    region: addressData.region,
                    city: addressData.city,
                    street: addressData.street,
                }],
                bounds: {
                    fromBound: {
                        value: 'house'
                    },
                    toBound: {
                        value: 'house'
                    }
                }
            };
            controlField.get('receiverLocationHouse').enable();
        } else if (bound === 'house') {
            controlField.get('receiverPostalCodeIndex').setValue(addressData.postal_code);
            controlField.get('receiverPostalCodeIndex').enable();
        }
    }

    resetAddressReceiver(bound: string, control: any): void {
        const controlField = control as FormGroup;
        if (bound === 'city') {
            controlField.get('receiverLocationStreet').setValue('');
            controlField.get('receiverLocationStreet').disable();
            controlField.get('receiverLocationHouse').setValue('');
            controlField.get('receiverLocationHouse').disable();
            controlField.get('receiverPostalCodeIndex').setValue('');
            controlField.get('receiverPostalCodeIndex').disable();
        } else if (bound === 'street') {
            controlField.get('receiverPostalCodeIndex').setValue('');
            controlField.get('receiverPostalCodeIndex').disable();
            controlField.get('receiverLocationHouse').setValue('');
            controlField.get('receiverLocationHouse').disable();
            this.configAddressReceiver.locations = [{
                region: controlField.get('hiddenReceiverLocationRegion').value,
                city: controlField.get('hiddenReceiverLocationCity').value,
            }];
            this.configAddressReceiver.bounds = { fromBound: { value: 'street' }, toBound: { value: 'street' } };
        }
    }

    resetAddressSender(bound: string): void {
        if (bound === 'city') {
            this.form.get('senderLocationStreet').setValue('');
            if (this.form.get('senderLocationStreet').value === '') {
                this.form.get('senderLocationStreet').disable();
            }
            this.form.get('senderLocationHouse').setValue('');
            this.form.get('senderLocationHouse').disable();
        } else if (bound === 'street') {
            this.form.get('senderLocationStreet').setValue('');
            if (this.form.get('senderLocationStreet').value === '') {
                this.form.get('senderLocationHouse').disable();
            }
        }
    }

    async changePostalService(control: FormGroup): Promise<any> {
        if (control.get('postalService').value.toString() === PostalServiceAlias.Cdek.toString()) {
            control.setControl('receiverCode', new FormControl(null, Validators.required));
        } else if (control.get('postalService').value.toString() === PostalServiceAlias.Nationalpost.toString()) {
            control.removeControl('receiverCode');
        }
        this.changeAddress();
        this.focusOut();
    }

    ngAfterContentChecked(): any {
        this.setStyleAutoText();
        if (this.form.get('senderLocationCity').value === '') {
            this.form.get('senderLocationStreet').setValue(''); this.disabledSenderStreet = true;
            this.form.get('senderLocationHouse').setValue(''); this.disabledSenderHouse = true;
        }
        if (this.form.get('senderLocationStreet').value === '') {
            this.form.get('senderLocationHouse').setValue(''); this.disabledSenderHouse = true;
        }
    }

    addReceiver(event: events): void {
        this.receiversFormArray.push(
            new FormGroup({
                receiverName: new FormControl(null, Validators.required),
                receiverLocationCity: new FormControl(null, Validators.required),
                receiverLocationStreet: new FormControl(null, Validators.required),
                receiverLocationHouse: new FormControl(null, Validators.required),
                receiverPostalCodeIndex: new FormControl(null, Validators.required),
                receiverPhone: new FormControl(null, [Validators.required, Validators.minLength(12)]),
                postalService: new FormControl(PostalServiceAlias.Nationalpost, Validators.required),
                hiddenReceiverLocationRegion: new FormControl(null),
                hiddenReceiverLocationCity: new FormControl(null),
                hiddenReceiverLocationStreet: new FormControl(null),
            })
        );
        this.form.setControl('receivers', this.receiversFormArray);
        this.refreshCost();
        document.getElementById('btn-addReceiver').blur();
    }

    removeReceiver(i): void {
        this.receiversFormArray.removeAt(i);
    }

    showOnSubmitModal() {
        if (!this.post.attachments) {
            this.post.attachments = [];
        }
        if (this.form.invalid || this.post.attachments.length === 0) {
            this.form.markAllAsTouched();
            const elDadata = document.querySelectorAll('form .is-invalid input');
            if (elDadata[0]) {
                // @ts-ignore
                elDadata[0].focus();
                return;
            }
            const elDef = document.querySelectorAll('form input.is-invalid');
            if (elDef[0]) {
                // @ts-ignore
                elDef[0].focus();
                return;
            }
            return;
        }

        document.getElementById('show-onSubmit-modal').click();
    }

    async onSubmit(): Promise<void> {
        if (!this.post.attachments) {
            this.post.attachments = [];
        }
        if (this.form.invalid || this.post.attachments.length === 0) {
            this.form.markAllAsTouched();
            const elDadata = document.querySelectorAll('form .is-invalid input');
            if (elDadata[0]) {
                // @ts-ignore
                elDadata[0].focus();
                return;
            }
            const elDef = document.querySelectorAll('form input.is-invalid');
            if (elDef[0]) {
                // @ts-ignore
                elDef[0].focus();
                return;
            }
            return;
        }

        this.isLoading = true;
        this.post.senderName = this.form.get('senderName').value;
        this.post.senderLocation = this.form.get('senderLocationCity').value + ', ' + this.form.get('senderLocationStreet').value + ', ' + this.form.get('senderLocationHouse').value;
        this.post.senderCompany = this.form.get('senderCompany').value;

        if (this.customer.email === null || this.customer.email === '') {
            this.customer.email = this.form.get('email').value;
            await this.apiService.customers.saveCustomer(this.customer);
        }

        for (const item of this.receiversFormArray.controls) {
            const index = this.receiversFormArray.controls.indexOf(item);
            if (index === 0) {
                this.post.receiverName = item.get('receiverName').value;
                this.post.receiverLocation = item.get('receiverLocationCity').value + ', ' + item.get('receiverLocationStreet').value + ', ' + item.get('receiverLocationHouse').value;
                this.post.city = item.get('receiverLocationCity').value;
                this.post.street = item.get('receiverLocationStreet').value;
                this.post.house = item.get('receiverLocationHouse').value;
                this.post.receiverPhone = item.get('receiverPhone').value;
                if (item.get('postalService').value === '0') {
                    this.post.postalCode = item.get('receiverCode').value;
                }
                this.post.postalCodeIndex = item.get('receiverPostalCodeIndex').value;
                this.post.postalServiceAlias = item.get('postalService').value;
            }
        }

        const res = await this.apiService.posts.updatePost(this.post);

        if (typeof res === 'undefined')
        {
            this.isLoading = false;
            return;
        }

        if (this.receiversFormArray.controls.length > 1) {
            for (const item of this.receiversFormArray.controls) {
                const index = this.receiversFormArray.controls.indexOf(item);
                if (index !== 0) {
                    this.post.receiverName = item.get('receiverName').value;
                    this.post.receiverLocation = item.get('receiverLocationCity').value + ', ' + item.get('receiverLocationStreet').value + ', ' + item.get('receiverLocationHouse').value;
                    this.post.city = item.get('receiverLocationCity').value;
                    this.post.street = item.get('receiverLocationStreet').value;
                    this.post.house = item.get('receiverLocationHouse').value;
                    this.post.receiverPhone = item.get('receiverPhone').value;
                    if (item.get('postalService').value === '0') {
                        this.post.postalCode = item.get('receiverCode').value;
                    }
                    this.post.postalCodeIndex = item.get('receiverPostalCodeIndex').value;
                    this.post.postalServiceAlias = item.get('postalService').value;
                    this.post.ParentId = this.post.id;
                    const _tempChild = await this.apiService.posts.updatePost(this.post);
                    if (_tempChild && _tempChild.id) {
                        await this.apiService.posts.changePostStatus(_tempChild.id, 'waitingPayment');
                    }
                }
            }
        }

        if (res && res.id) { await this.apiService.posts.changePostStatus(res.id, 'waitingPayment'); }
        this.customer.email = this.form.get('email').value;
        await this.apiService.customers.saveCustomer(this.customer);
        const post = await this.apiService.posts.getPostDetails(res.id);
        if (post) {
            const payRes = await this.apiService.posts.payForOrder(post);
            document.getElementById('closeModalOnSubmit').click();
            if (payRes) {
                location.href = payRes;
                this.router.navigateByUrl('/history');
            } else {
                this.router.navigateByUrl('/history');
            }
        }
    }

    // К отправителю
    onOptionsSelected(v): void {
        if (v === '0') {
            this.form.get('senderName').setValue(null);
            this.form.get('senderCompany').setValue(null);
            this.form.get('senderLocationCity').setValue(null);
            this.form.get('senderLocationStreet').setValue(null);
            this.form.get('senderLocationStreet').disable();
            this.form.get('senderLocationHouse').setValue(null);
            this.form.get('senderLocationHouse').disable();
        } else {
            const targetLocation = this.customer.locations.find(m => m.id === +v);
            this.form.get('senderName').setValue(targetLocation.name);
            this.form.get('senderCompany').setValue(targetLocation.company);
            if (targetLocation.locationCity != null) {
                this.form.get('senderLocationCity').setValue(targetLocation.locationCity);
                this.form.get('senderLocationStreet').setValue(targetLocation.locationStreet);
                this.form.get('senderLocationStreet').enable();
                this.form.get('senderLocationHouse').setValue(targetLocation.locationHouse);
                this.form.get('senderLocationHouse').enable();
            } else {
                this.form.get('senderLocationCity').setValue(null);
                this.form.get('senderLocationStreet').setValue(null);
                this.form.get('senderLocationStreet').disable();
                this.form.get('senderLocationHouse').setValue(null);
                this.form.get('senderLocationHouse').disable();
            }
        }
        this.focusOut();
    }

    changeAddress(): void {
        // Проверка на совпадение с почтовыми кодами.
        for (const item of this.receiversFormArray.controls) {
            if (item.get('receiverLocationCity') && item.get('receiverLocationCity').value?.length && item.get('receiverLocationCity').value.length > 3) {
                let city = item.get('receiverLocationCity').value.toLowerCase();
                city = city.replace('г ', '');
                if (city.indexOf(',') > 0) { city = city.substring(0, city.indexOf(',')); }
                if (city) {
                    const tryCode = this.codes.find(m => m.code.toLowerCase().indexOf(city) >= 0);
                    if (tryCode) {
                        console.log(tryCode.code);
                        if (item.get('receiverCode'))
                            item.get('receiverCode').setValue(tryCode.code);
                    }
                }
            }
        }
        clearTimeout(this.timeoutChange);
        this.timeoutChange = setTimeout(() => this.refreshCost(), 2000);
    }

    async refreshCost(): Promise<any> {
        const cost = await this.apiService.posts.getCostPost(this.post); // 72.43
        this.post.cost = (cost * this.receiversFormArray.controls.length);
    }

    droppedImages(files: NgxFileDropEntry[]): any {
        if (this.post.attachments == null) {
            this.post.attachments = [];
        }
        for (const droppedFile of files) {
            if (droppedFile.fileEntry.isFile && this.isFileAllowed(droppedFile.fileEntry.name)) {
                const reader = new FileReader();
                const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
                fileEntry.file((file: File) => {
                    const rCount = new FileReader();
                    rCount.readAsBinaryString(file);
                    rCount.onload = () => {
                        const count = rCount.result.toString().match(/\/Type[\s]*\/Page[^s]/g).length;
                        reader.readAsDataURL(file);
                        reader.onload = () => {
                            const base64 = reader.result.toString();
                            const attachment: AttachmentReq = {
                                fileBase64: base64.substring(base64.indexOf(',') + 1, base64.length),
                                filename: file.name,
                                alternativeName: null,
                                extension: file.type.substring(file.type.indexOf('/') + 1, file.type.length),
                                size: file.size,
                                pageCount: count
                            };
                            this.post.attachments.push(attachment);

                            let totalPage = 0;
                            for (let i = 0; i < this.post.attachments.length; i++) {
                                totalPage = totalPage + +this.post.attachments[i].pageCount;
                            }
                            this.post.cost = (+environment.costO + (+environment.pricePerPage * +totalPage));
                        };
                    };
                });
            }
        }
        setTimeout(async () => {
            if (!this.post.receiverLocation
                || !this.post.receiverName
                || !this.post.receiverPhone) {
                return;
            }
            await this.apiService.posts.saveAttachments(this.post);
        }, 1000);
    }

    isFileAllowed(fileName: string): any {
        let isFileAllowed = false;
        const allowedFiles = ['.pdf']; // , '.jpg', '.jpeg'
        const regex = /(?:\.([^.]+))?$/;
        const extension = regex.exec(fileName);
        if (undefined !== extension && null !== extension) {
            for (const ext of allowedFiles) {
                if (ext === extension[0]) {
                    isFileAllowed = true;
                }
            }
        }
        if (!isFileAllowed) {
            this.toastr.warning(`Файл ${fileName} имеет недопустимое расширение`, ``);
        }
        return isFileAllowed;
    }

    onCodeSelected(value: string): any {
        this.refreshCost();
        this.selectedOffice = this.codes.find(m => m.code == value)?.name;
        this.focusOut();
    }

    getValueSelectedOffice(value: string): any {
        if (value)
            return this.codes.find(m => m.code === value)?.name;
    }

    private setStyleAutoText(): any {
        const els = document.querySelectorAll('.dadata');
        els.forEach(el => {
            const inputEl = <HTMLElement>el.firstChild.firstChild;
            const elem = <HTMLElement>el;
            // elem.style.padding = "0";
            inputEl.style.backgroundColor = '#fff';
            inputEl.setAttribute('class', 'form-control');
            inputEl.style.paddingLeft = '12px';
        });
    }

    private async getPostCodes(): Promise<any> {
        this.codes = await this.apiService.office.getPostCodes();
        this.codes = this.codes.filter(x => x.postalService === 'cdek');
        if (this.codes.length) {
            this.selectedOffice = this.codes[0].name;
        }
    }

    isNew(status: string): any {
        return status === 'new' || status === 'waitingPayment' ? true : false;
    }

    removePost(id: string): any {
        this.apiService.posts.removePost(id).then(res => {
            this.toastr.success('Успешно удалено');
            setTimeout(() => this.router.navigateByUrl('/history'), 1000);
        });
    }

    console(object): void {
        console.log(object);
    }

    onOptionsSelectedReceiver(value: string, receiver: AbstractControl): void {
        if (value === '0') {
            receiver.get('receiverName').setValue(null);
            receiver.get('receiverPhone').setValue(null);
            receiver.get('receiverLocationCity').setValue(null);
            receiver.get('receiverLocationStreet').setValue(null);
            receiver.get('receiverLocationStreet').disable();
            receiver.get('receiverLocationHouse').setValue(null);
            receiver.get('receiverLocationHouse').disable();
            receiver.get('receiverPostalCodeIndex').setValue(null);
            receiver.get('receiverPostalCodeIndex').disable();
        } else {
            const targetLocation = this.customer.receivers.find(m => m.id === +value);
            receiver.get('receiverName').setValue(targetLocation.company);
            receiver.get('receiverPhone').setValue(targetLocation.phone);
            if (targetLocation.locationCity != null) {
                receiver.get('receiverLocationCity').setValue(targetLocation.locationCity);
                receiver.get('receiverLocationStreet').setValue(targetLocation.locationStreet);
                receiver.get('receiverLocationStreet').enable();
                receiver.get('receiverLocationHouse').setValue(targetLocation.locationHouse);
                receiver.get('receiverLocationHouse').enable();
                receiver.get('receiverPostalCodeIndex').setValue(targetLocation.postcode);
                receiver.get('receiverPostalCodeIndex').enable();
            } else {
                receiver.get('receiverLocationCity').setValue(null);
                receiver.get('receiverLocationStreet').setValue(null);
                receiver.get('receiverLocationStreet').disable();
                receiver.get('receiverLocationHouse').setValue(null);
                receiver.get('receiverLocationHouse').disable();
                receiver.get('receiverPostalCodeIndex').setValue(null);
                receiver.get('receiverPostalCodeIndex').disable();
            }
        }
        this.focusOut();
    }
}
