export class CustomerLocation {
    id: number;
    name: string;
    address: string;
    postcode: string;
    company: string;
    comment: string;
    locationRegion: string;
    locationCity: string;
    locationStreet: string;
    locationHouse: string;
}

export class ReceiverLocation {
    id: number;
    company: string;
    address: string;
    phone: string;
    officeId: string;
    comment: string;
    locationRegion: string;
    locationCity: string;
    locationStreet: string;
    locationHouse: string;
    postcode: string;
}
