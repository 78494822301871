import { Router } from '@angular/router';
import {Post, PostInfo} from './../../models/post.model';
import { EpochtaApiService } from 'src/app/services/epochta-api.service';
import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-outgoing-shipment-history',
    templateUrl: './outgoing-shipment-history.component.html',
    styleUrls: ['./outgoing-shipment-history.component.scss']
})
export class OutgoingShipmentHistoryComponent implements OnInit {
    isShowSidebar: boolean = false;

    constructor(private apiService: EpochtaApiService, private router: Router) { }

    posts: PostInfo[];
    offices: any[];

    async ngOnInit(): Promise<any> {
        this.offices = await this.apiService.office.getPostCodes();
        this.posts = await this.apiService.posts.getCurrentUserOutbox();
    }

    public getOfficeName(post: PostInfo): string {
        if (post?.destination?.postalCode) {
            return this.offices.filter(x => x.code === post?.destination?.postalCode)[0]?.name;
        } else if (post?.receiver?.postalCodeIndex) {
            return this.offices.filter(x => x.code === post?.receiver?.postalCodeIndex)[0]?.name;
        }
    }

    goDetails(post: PostInfo) {
        if (post.status == 'new') {
            this.router.navigateByUrl(`create-departure/${post.id}`);
            return;
        }
        this.router.navigateByUrl(`departure-details/${post.id}`);
    }

    sidebarOpen(open: boolean) {
        this.isShowSidebar = open;
    }
}
