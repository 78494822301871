import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { RegistrationComponent } from './pages/registration/registration.component';
import { OutgoingShipmentHistoryComponent } from './pages/outgoing-shipment-history/outgoing-shipment-history.component';
import { HeaderComponent } from './components/header/header.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { PopularQuestionsComponent } from './pages/popular-questions/popular-questions.component';
import { DepartureDetailsComponent } from './pages/departure-details/departure-details.component';
import { CreateDepartureComponent } from './pages/create-departure/create-departure.component';
import { ArchiveComponent } from './pages/archive/archive.component';
import { ShipmentArchiveComponent } from './pages/shipment-archive/shipment-archive.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { EpochtaApiService } from './services/epochta-api.service';
import { NgxFileDropModule } from 'ngx-file-drop';
import { StatusPostPipe } from './pipes/status-post.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { CommonModule } from '@angular/common';
import { ToLocalDatePipe } from './pipes/to-local-date.pipe';
import { NgxDadataModule } from '@kolkov/ngx-dadata';
import { SuccessfulPaymentComponent } from './pages/result-pages/successful-payment/successful-payment.component';
import { ErrorPaymentComponent } from './pages/result-pages/error-payment/error-payment.component';
import { PrivacyPolicyComponent } from './pages/policy/privacy-policy/privacy-policy.component';
import { PersonalDataPolicyComponent } from './pages/policy/personal-data-policy/personal-data-policy.component';
import { SidebarMenuComponent } from './components/sidebar-menu/sidebar-menu.component';
import { PasswordRecoveryComponent } from './pages/password-recovery/password-recovery.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegistrationComponent,
    OutgoingShipmentHistoryComponent,
    HeaderComponent,
    ProfileComponent,
    PopularQuestionsComponent,
    DepartureDetailsComponent,
    CreateDepartureComponent,
    ArchiveComponent,
    ShipmentArchiveComponent,
    StatusPostPipe,
    ToLocalDatePipe,
    SuccessfulPaymentComponent,
    ErrorPaymentComponent,
    PrivacyPolicyComponent,
    PersonalDataPolicyComponent,
    SidebarMenuComponent,
    PasswordRecoveryComponent,
    PhoneMaskDirective
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxDadataModule,
        FormsModule,
        ReactiveFormsModule,
        NgxFileDropModule,

        CommonModule,
        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(),
        // ToastrModule added
    ],
    exports: [
        PhoneMaskDirective
    ],
  providers: [EpochtaApiService],
  bootstrap: [AppComponent]
})
export class AppModule { }
