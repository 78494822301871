// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    // apiUrl: "https://localhost:44333/api/Epochta/",
    apiUrl: "https://pochta-api.ud4.ru/api/Epochta/",
    // apiUrl: "https://api.cdekpost.ru/api/Epochta/",
    costO: 20100,
    pricePerPage: 500,
    apiKeyDaData: "ec615adb239ddce4afadf6479ef0198e75b6bec2"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
