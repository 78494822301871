import { Post, PostInfo } from './../models/post.model';
import {CustomerLocation, ReceiverLocation} from './../models/customer-location.model';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Customer } from '../models/customer.model'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class EpochtaApiService {
    apiUrl: string;

    constructor(
        private http: HttpClient,
        private toastr: ToastrService,
        private router: Router,

    ) { this.apiUrl = environment.apiUrl; }

    public readonly pdf = ((api: this) => ({
        /* Квитанция о вручении (Статус: completed) */
        postReceiveFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostReceiveFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Квитанция об отправлении (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postSendFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostSendFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
        /* Опись отправления (Статус: waitingOfficeActions, waitingDelivery, completed) */
        postIndexFormPdf(postId: string, openInBrowser: boolean = true) {
            return api.apiUrl + `PostIndexFormPdf?postId=${postId}&openInBrowser=${openInBrowser}`;
        },
    }))(this);

    public readonly customers = ((api: this) => ({
        sendCode(phone: string) {
            return api.apiCallGet("SendCustomerCode", { phone });
        },
        sendCodeEmail(email: string) {
            return api.apiCallGet("SendCustomerCodeEmail", { email });
        },
        continueCode(code: string) {
            return api.apiCallGet("ContinueCustomerCode", { code });
        },
        continueCodeEmail(code: string) {
            return api.apiCallGet("ContinueCustomerCodeEmail", { code });
        },
        getCurrentCustomerProfile() {
            return api.apiCallGet("GetCurrentCustomerProfile");
        },
        getCustomers() {
            return api.apiCallGet("customers");
        },
        getCustomerById(id: string) {
            return api.apiCallGet(`customer/id/${id}`);
        },
        getCustomerByPhone(id: string) {
            return api.apiCallGet(`customer/phone/${id}`);
        },
        getCustomerByEmail(id: string) {
            return api.apiCallGet(`customer/email/${id}`);
        },
        saveCustomer(customer: Customer) {
            return api.apiCall(`customer`, customer);
        },
        saveCustomerLocation(customerId: string, customerLocation: CustomerLocation) {
            return api.apiCall(`customer/id/${customerId}/location/update`, customerLocation);
        },
        saveReceiverLocation(customerId: string, receiverLocation: ReceiverLocation) {
            return api.apiCall(`customer/id/${customerId}/locationReceiver/update`, receiverLocation);
        },
        removeCustomerLocation(customerId: string, locationIndex: number) {
            return api.apiCallGet(`customer/id/${customerId}/location/remove/id/${locationIndex}`);
        },
        removeReceiverLocation(customerId: string, locationIndex: number) {
            return api.apiCallGet(`customer/id/${customerId}/locationReceiver/remove/id/${locationIndex}`);
        },
    }))(this);

    public readonly posts = ((api: this) => ({
        createPost(post: Post) {
            return api.apiCall("CreatePost", post);
        },
        updatePost(post: Post) {
            return api.apiCall("UpdateOrCreatePost", post);
        },
        getCurrentUserOutbox() {
            return api.apiCallGet(`GetCurrentUserOutbox`)
        },
        getUserArchivedPosts() {
            return api.apiCallGet(`GetUserArchivedPosts`)
        },
        getPostDetails(id: string): Promise<PostInfo> {
            return api.apiCallGet(`GetPostDetails`, { id: id })
        },
        getPostDetailsByCdekNumber(cdekNumber: string): Promise<PostInfo> {
            return api.apiCallGet(`GetPostDetailsByCdekNumber`, { cdekNumber })
        },
        downloadAttachment(postId: string, attachmentId: string) {
            alert(api.apiUrl + `DownloadAttachmentFile?postId=${postId}&attachmentId=${attachmentId}`)
            window.open(api.apiUrl + `DownloadAttachmentFile?postId=${postId}&attachmentId=${attachmentId}`);
        },
        saveAttachments(post: Post) {
            return api.apiCall(`SavePostAttachments`, post)
        },
        removePost(id: string) {
            return api.apiCallGet(`RemovePost`, { postId: id })
        },
        getCostPost(post: Post) {
            return api.apiCall(`GetCostPost`, post)
        },
        payForOrder(post: PostInfo) {
            return api.apiCall(`PayForOrder`, { postId: post.id, post: post })
        },
        changePostStatus(postId: string, status: string) {
            return api.apiCall(`ChangePostStatus?postId=${postId}&status=${status}&isOffice=false`, { postId: postId, status: status })
        },
    }))(this);

    public readonly office = ((api: this) => ({
        getPostCodes() {
            return api.apiCallGet("GetPostCodes");
        },
        getOffice(id: string) {
            return api.apiCallGet(`GetOfficeById?id=${id}`);
        }
    }))(this);


    public startCustomerLogin(phone: string) {
        return this.apiCallGet("StartCustomerLogin", { 'phone': phone });
    }

    public startCustomerLoginEmail(email: string) {
        return this.apiCallGet("StartCustomerLoginEmail", { 'email': email });
    }

    public continueCustomerLogin(code: string) {
        return this.apiCallGet("ContinueCustomerLogin", { 'code': code });
    }

    public сustomerLoginEmailAndPassword(userLogin: string, userPassword: string) {
        return this.apiCallGet("CustomerLoginEmailAndPassword", { 'login': userLogin, 'password': userPassword});
    }

    public passwordRecovery(phone: string, newPassword: string) {
        return this.apiCallGet("PasswordRecovery", { 'phone': phone, 'newPassword': newPassword});
    }

    private apiCall(url: string, params: any): Promise<any> {
        return this.http
            .post(this.apiUrl + url, params, this.makeOptions())
            .toPromise()
            .catch((e) => {
                if (e?.error?.message)
                    this.toastr.error(e?.error?.message)
                console.error(e);
                if (e.status == '401') this.router.navigateByUrl('/login');
            });
    }

    private apiCallGet(url: string, params?: any): Promise<any> {
        let options = this.makeOptions() as any;

        if (params) {
            options.params = params;
        }

        return this.http
            .get(this.apiUrl + url, options)
            .toPromise()
            .catch((e) => {
                if (e?.error?.message)
                    this.toastr.error(e?.error?.message)
                console.warn(e);
                if (e.status == '401' || e.status == '0')
                {
                    if (this.router.url !== '/registration' && this.router.url !== '/registration#step-1' && this.router.url !== '/registration#step-2'&& this.router.url !== '/registration#step-3' && this.router.url !== '/password-recovery') {
                        this.router.navigateByUrl('/login');
                    }
                }
            });
    }

    makeOptions() {
        let headers: HttpHeaders = new HttpHeaders().set(
            'Content-Type',
            'application/json'
        );
        const token = localStorage.getItem('auth');

        if (token)
            headers = headers.set('auth', token);
        return { headers: headers };
    }
}
